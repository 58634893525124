import { getPopupHistory } from "@/services";
import { useQuery } from "react-query";

export const useGetPopupHistoryData = (page: number | null, rowPerPage: number | null) => {
    const {
        data: popupHistory,
        isLoading: getPopupHistoryIsLoading,
    } = useQuery(["popupHistory", page, rowPerPage], async () => {
        const popupHistoryData = await getPopupHistory(page, rowPerPage);
        console.log("popupHistoryData", popupHistoryData);
        return popupHistoryData || [];
    });
    return { popupHistory, getPopupHistoryIsLoading };
};
