import React from 'react';
import '../styles/styles.css';
import { IProduct } from '@/models';
import IMAGES from '../assets/images/images';

interface ProductCardProps {
    product: IProduct;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {

    const isSingleRow = product.imageSizeType === 1 || product.imageSizeType === 2;
    const isDoubleHeight = product.imageSizeType === 3;
    const flexItemClass = isSingleRow ? "flexItem fullWidth" : isDoubleHeight ? "flexItem doubleHeight" : "flexItem";
    const cardImageClass = isSingleRow ? "cardImage fullWidth" : "cardImage";

    const cardStyle = isDoubleHeight ? {} : { height: '50%', width: '100%' };

    const showOutOfStockImage = product.stockQuantity === 0;

    return (
        <div className={flexItemClass} style={cardStyle}>
            <div className="imageContainer">
                <img src={product.imageUrl} alt={product.name} className={cardImageClass} />
                {showOutOfStockImage && (<img src={IMAGES.outOfStock} style={{ position: 'absolute', top: isDoubleHeight ? '50%' : '25%', left: 0, width: '100%' }} />)}
                <div className="circleBadge">{product.sequence}</div>
            </div>

            <div className="textContainer">
                <div className="name">{product.name}</div>
                <div className="price" style={{ visibility: showOutOfStockImage ? 'hidden' : 'visible' }}>CKP {product.sellingPoint}</div>
            </div>
        </div>
    );
};

export default ProductCard;
