import { axiosInstance } from "@/api/axios.instance";
import { IOrder } from "@/models";

export const getOrders = async (): Promise<IOrder[]> =>
    await axiosInstance.get(`/orders/activeOrderList`).then((res) => res.data);

export const getOrderDetails = async (
    orderId: bigint | null,
): Promise<any> =>
    await axiosInstance.get(`/orders/trackingInfo`, {
        params: { orderId: orderId }
    }).then((res) => res.data);

export const getInActiveOrders = async (): Promise<IOrder[]> =>
    await axiosInstance.get(`/orders/inactiveOrderList`).then((res) => res.data);

export const setOrderPreparing = async (orderId: bigint) =>
    await axiosInstance.post(`/orders/setpreparing?orderId=${orderId}`);

export const setOrderSenting = async ({
    orderId,
    trackingNumber,
    carrierCompany = "CarComp.",
}: {
    orderId: bigint;
    trackingNumber: string;
    carrierCompany: string;
}) =>
    await axiosInstance.post(
        `/orders/setsending`,
        {},
        {
            params: {
                orderId,
                trackingNumber,
                carrierCompany,
            },
        },
    );

export const setDigitalOrderSending = async ({
    orderId,
    expiryDate,
    digitalCode,
}: {
    orderId: bigint;
    expiryDate: string;
    digitalCode: string;
}) =>
    await axiosInstance.post(
        `/orders/setDigitalProductSending`,
        {},
        {
            params: {
                orderId,
                expiryDate,
                digitalCode,
            },
        },
    );

export const setOrderFinish = async (orderId: bigint) =>
    await axiosInstance.post(`/orders/setfinish?orderId=${orderId}`);

export const setOrderCancel = async (orderId: bigint) =>
    await axiosInstance.post(`/orders/setcanceled?orderId=${orderId}`);

export const setOrderReturn = async (orderId: bigint) =>
    await axiosInstance.post(`/orders/setreturn?orderId=${orderId}`);
