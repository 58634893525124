import moment from "moment";
import { Column, DataTable } from "primereact";
import { useGetLastWeekGameInfoData } from "@/hooks";

const BoxesOpenedByUniqueUsersAndAdPerCkpDataTable = () => {
    const { lastWeekGameInfo, getLastWeekGameInfoIsLoading } =
        useGetLastWeekGameInfoData();

    return (
        <div className="col-12 m-0">
            <div className="card mb-0">
                <h5>Opened Boxes and Ads per CKP</h5>
                <DataTable
                    value={lastWeekGameInfo}
                    loading={getLastWeekGameInfoIsLoading}
                    responsiveLayout="scroll"
                    paginator
                    rows={25}
                    rowsPerPageOptions={[25, 50, 100]}
                >
                    <Column
                        field="date"
                        header="Date"
                        sortable
                        body={(rowData) => <>{moment(rowData.date).format("DD-MM-YYYY")}</>}
                    />
                    <Column
                        field="uniqueBoxOpenUserCount"
                        header="Box Opened Users"
                        sortable
                        style={{ width: "35%" }}
                    />
                    <Column
                        field="totalBoxOpenCount"
                        header="Total Opened Boxes"
                        sortable
                        style={{ width: "35%" }}
                    />
                    <Column
                        field="adPerCkp"
                        header="Ads per CKP"
                        sortable
                        style={{ width: "35%" }}
                    />
                </DataTable>
            </div>
        </div>
    );
};

export default BoxesOpenedByUniqueUsersAndAdPerCkpDataTable;
