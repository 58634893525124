import { Toast } from "primereact";
import { updateMbiStatus } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateMbiStatusData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: updateMbiStatusIsLoading } = useMutation(
        async ({ mbiId, status }: {
            mbiId: bigint,
            status: string,
        }) => await updateMbiStatus(mbiId, status),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("mbis");
                toastSuccess(toast, "Mbi status updated");
            },
            onError: (error: any) => {
                let errorMessage = "Mbi status not updated";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        updateMbiStatusIsLoading,
    };
};
