import moment from "moment";
import { Chart } from "primereact";
import { useEffect, useState } from "react";
import { useGetRegisteredCustomerCountWeekly } from "@/hooks";

const WeeklyRegisteredCustomerCount = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { customerCount, getCustomerCountIsLoading } =
        useGetRegisteredCustomerCountWeekly();

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--text-color");
        const textColorSecondary = documentStyle.getPropertyValue(
            "--text-color-secondary",
        );
        const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
        const data = customerCount?.map((item: any) => item[1]);
        const labels = customerCount?.map((item: any) => moment(item[0]).format("DD-MM-YYYY"));
        const chartData = {
            labels: labels ?? [],
            datasets: [
                {
                    label: "Daily New User Count",
                    data: data ?? [],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue("--green-500"),
                    tension: 0.4,
                    fontSize: 20,
                },
            ],
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.9,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getCustomerCountIsLoading]);

    return (
        <div className="card mb-0">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
};

export default WeeklyRegisteredCustomerCount;
