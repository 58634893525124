import moment from "moment";
import { Chart } from "primereact";
import { useEffect, useState } from "react";
import { useGetLastWeekInviteInfoData } from "@/hooks";

const InviterAndInvitedUsersChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { lastWeekInviteInfo, getlastWeekInviteInfoIsLoading } =
        useGetLastWeekInviteInfoData();

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const labels = lastWeekInviteInfo?.map((item: any) => moment(item.date).format("DD-MM-YYYY"));
        const invitedUsers = lastWeekInviteInfo?.map((item: any) => item.invitedPeopleCount);
        const inviterUsers = lastWeekInviteInfo?.map((item: any) => item.peopleWhoInviteCount);

        const chartData = {
            labels: labels ?? [],
            datasets:
                [
                    {
                        borderWidth: 1,
                        data: invitedUsers ?? [],
                        label: "Invited Users",
                        backgroundColor: documentStyle.getPropertyValue("--cyan-500"),
                    },
                    {
                        borderWidth: 1,
                        data: inviterUsers ?? [],
                        label: "Inviter Users",
                        backgroundColor: documentStyle.getPropertyValue("--purple-500"),
                    },
                ],
        };
        const options = {
            aspectRatio: 0.83,
            maintainAspectRatio: false,
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getlastWeekInviteInfoIsLoading]);

    return (
        <div className="card mb-0">
            <h4 style={{ textAlign: "center" }}>
                Inviter And Invited Users
            </h4>
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    );
};

export default InviterAndInvitedUsersChart;
