import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { sendNotificationToUser } from "@/services";
import { useMutation, useQueryClient } from "react-query";

export const useSendNotificationData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: sendNotificationIsLoading,
        isSuccess: sendNotificationIsSuccess,
    } = useMutation(
        async ({
            customerId,
            notificationMessageId
        }: {
            customerId: number | null,
            notificationMessageId: number | null,
        }) => await sendNotificationToUser(customerId, notificationMessageId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("customerNotifications");
                toastSuccess(toast, "Notification sent");
            },
            onError: (error: any) => {
                let errorMessage = "Notification not sent";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        sendNotificationIsLoading,
        sendNotificationIsSuccess,
    };
};
