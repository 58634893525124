import React from 'react';

interface ProductSplitProps {
    leftContent: React.ReactNode;
    rightContent: React.ReactNode;
}

const ProductSplit: React.FC<ProductSplitProps> = ({ leftContent, rightContent }) => {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>{leftContent}</div>
            <div style={{ flex: 1 }}>{rightContent}</div>
        </div>
    );
};

export default ProductSplit;
