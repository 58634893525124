import { Badge, Button, Column, Toast } from "primereact";
import React, { useEffect, useRef, useState } from "react";
import { DataTableCrud, DataTableToolbar, SendNotificationDialog } from "@/components";
import { useGetCustomerNotificationsData, useSendNotificationData } from "@/hooks";
import { INotification, INotificationMessage } from "@/models";
import moment from "moment";

interface Props {
    customerId: number | null;
}

const CustomerNotificationLogDataTable: React.FC<Props> = ({ customerId }) => {

    const [sendNotificationDialog, setSendNotificationDialog] = useState(false);

    const toast = useRef<Toast>(null);

    const { customerNotifications, getCustomerNotificationsIsLoading } =
        useGetCustomerNotificationsData(customerId);

    const {
        mutate: sendNotificationMutate,
        sendNotificationIsLoading,
        sendNotificationIsSuccess,
    } = useSendNotificationData(toast);

    useEffect(() => {
        if (sendNotificationIsSuccess) {
            hideSendNotificationDialog();
        }
    }, [sendNotificationIsSuccess]);

    const openSendNotificationDialog = async () => {
        setSendNotificationDialog(true);
    };

    const hideSendNotificationDialog = () => {
        setSendNotificationDialog(false);
    };

    const sendNotification = (notificationMessage: INotificationMessage) => {
        sendNotificationMutate({ customerId: customerId, notificationMessageId: notificationMessage?.id });
    };

    return (
        <>
            <Toast ref={toast} />

            <DataTableToolbar>
                <Button
                    label="Send Notification"
                    id="p-send-notification-button"
                    icon="pi pi-send"
                    className="p-button-success mr-2"
                    onClick={openSendNotificationDialog}
                /></DataTableToolbar>

            <DataTableCrud
                value={customerNotifications}
                loading={getCustomerNotificationsIsLoading}
                title="customer notification records"
            >
                <Column
                    field="id"
                    header="Id"
                    sortable
                    style={{ minWidth: "6rem" }}
                ></Column>
                <Column
                    field="notificationTitle"
                    header="Title"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="notificationMessage"
                    header="Message"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="date"
                    header="Date"
                    sortable
                    style={{ minWidth: "12rem" }}
                    body={(rowData: INotification) => (
                        <>{moment.utc(rowData.date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                ></Column>
                <Column
                    field="isSuccess"
                    header="Status"
                    sortable
                    style={{ minWidth: "6rem" }}
                    body={(rowData: INotification) => (
                        <>{rowData?.isSuccess ?
                            <Badge value='Success' className="p-badge-success" /> :
                            <Badge value='Fail' className="p-badge-danger" />}</>
                    )}
                ></Column>
                <Column
                    field="isClicked"
                    header="Clicked"
                    sortable
                    style={{ minWidth: "6rem" }}
                    body={(rowData: INotification) => (
                        <>{rowData?.isClicked ?
                            <Badge value='Yes' className="p-badge-info" /> :
                            <Badge value='No' className="p-badge-secondary" />}</>
                    )}
                ></Column>
            </DataTableCrud>

            <SendNotificationDialog
                visible={sendNotificationDialog}
                isLoading={sendNotificationIsLoading}
                onHide={hideSendNotificationDialog}
                sendNotification={sendNotification}
            />
        </>
    );
};

export default CustomerNotificationLogDataTable;
