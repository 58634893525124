import { EcpmDataTable } from "@/components";

const Ecpms = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <EcpmDataTable />
            </div>
        </div>
    );
};

export default Ecpms;
