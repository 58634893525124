import { getProduct } from "@/services";
import { useQuery } from "react-query";

export const useGetProductData = (productId: number | null) => {
 const {
  data: product,
  isLoading: getProductIsLoading,
  refetch: refetchProduct,
 } = useQuery(["product", productId], getProduct, {
  enabled: false,
 });
 return { product, getProductIsLoading, refetchProduct };
};
