import { Toast } from "primereact";
import { deleteSupplier } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteSupplierData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: deleteSupplierIsLoading } = useMutation(
        async (supplierId: bigint) => await deleteSupplier(supplierId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("suppliers");
                toastSuccess(toast, "Supplier deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Supplier not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteSupplierIsLoading,
    };
};
