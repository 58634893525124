import { getRetention } from "@/services";
import { useQuery } from "react-query";

export const useGetRetentionData = () => {
 const { data: retention, isLoading: getRetentionIsLoading } = useQuery(
  "retention",
  getRetention,
 );
 return { retention, getRetentionIsLoading };
};
