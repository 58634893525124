import * as Yup from "yup";

export const productSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Name is required"),
  type: Yup.string().nullable()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Type is required"),
  sellingPoint: Yup.number().nullable()
    .min(1, "Please enter at least 1 character!")
    .max(100000, "You can enter up to 100000 characters!")
    .required("Price is required"),
});
