import moment from "moment";
import { Chart } from "primereact";
import { useEffect, useState } from "react";
import { useGetRetentionInLast7DaysData } from "@/hooks";

const RetentionInLast7DaysChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { retentionInLast7Days, getRetentionInLast7DaysIsLoading } = useGetRetentionInLast7DaysData();

    useEffect(() => {
        const labels = retentionInLast7Days?.map((item: any) => moment(item.date).format("DD-MM-YYYY"));
        const ret1 = retentionInLast7Days?.map((item: any) => item.retention1);
        const ret3 = retentionInLast7Days?.map((item: any) => item.retention3);
        const ret7 = retentionInLast7Days?.map((item: any) => item.retention7);
        const ret30 = retentionInLast7Days?.map((item: any) => item.retention30);
        const chartData = {
            labels: labels ?? [],
            datasets: [
                {
                    data: ret1 ?? [],
                    label: "Retention 1",
                    borderColor: "#FF5733",
                    tension: 0.4,
                },
                {
                    data: ret3 ?? [],
                    label: "Retention 3",
                    borderColor: "#00A656",
                    tension: 0.4,
                },
                {
                    data: ret7 ?? [],
                    label: "Retention 7",
                    borderColor: "#0088E0",
                    tension: 0.4,
                },
                {
                    data: ret30 ?? [],
                    label: "Retention 30",
                    borderColor: "#B9006E",
                    tension: 0.4,
                },
            ],
        };
        const options = {
            aspectRatio: 0.747,
            maintainAspectRatio: false,
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getRetentionInLast7DaysIsLoading]);

    return (
        <div className="card mb-0">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
};

export default RetentionInLast7DaysChart;
