import { Toast } from "primereact";
import { addCkpToCustomer } from "@/services";
import { INotificationMessage } from "@/models";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddCkpToCustomer = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCkpIsLoading,
        isSuccess: addCkpIsSuccess,
    } = useMutation(
        async ({
            customerId,
            notificationMessage
        }: {
            customerId: number | null,
            notificationMessage: INotificationMessage | null,
        }) => await addCkpToCustomer(customerId, notificationMessage),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("addCkp");
                toastSuccess(toast, "CKP Added");
            },
            onError: (error: any) => {
                let errorMessage = "CKP not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        addCkpIsLoading,
        addCkpIsSuccess,
    };
};
