import { getRegisteredCustomerCountWeekly } from "@/services";
import { useQuery } from "react-query";

export const useGetRegisteredCustomerCountWeekly = () => {
 const { data: customerCount, isLoading: getCustomerCountIsLoading } = useQuery(
  "customerCountRegisteredWeekly",
  getRegisteredCustomerCountWeekly,
 );
 return { customerCount, getCustomerCountIsLoading };
};
