import { POPUP_TYPES } from "@/constants/enums";
import * as Yup from "yup";

export const popupSchema = Yup.object().shape({
    popupType: Yup.string()
        .nullable()
        .min(2, "Please select popup type!")
        .required("Popup Type is required"),

    title: Yup.string()
        .nullable()
        .when('popupType', {
            is: (popupType: string) => popupType === POPUP_TYPES.TXT,
            then: Yup.string()
                .nullable()
                .min(2, "Please enter at least 2 characters!")
                .required("Title is required"),
            otherwise: Yup.string().nullable(),
        }),

    text: Yup.string()
        .nullable()
        .when('popupType', {
            is: (popupType: string) => popupType === POPUP_TYPES.TXT,
            then: Yup.string()
                .nullable()
                .min(2, "Please enter at least 2 characters!")
                .required("Text is required"),
            otherwise: Yup.string().nullable(),
        }),

    routeTargetId: Yup.string()
        .nullable()
        .when(['popupType', 'link'], {
            is: (popupType: string, link: string) => (popupType === POPUP_TYPES.PIR || popupType === POPUP_TYPES.FSIR || popupType === POPUP_TYPES.TXT) && link === '/product',
            then: Yup.string()
                .nullable()
                .min(2, "Please select product!")
                .required("Product is required"),
            otherwise: Yup.string().nullable(),
        }),

    videoUrl: Yup.string()
        .nullable()
        .when('popupType', {
            is: (popupType: string) => popupType === POPUP_TYPES.VID,
            then: Yup.string()
                .nullable()
                .min(2, "Please enter at least 2 characters!")
                .required("Video URL is required"),
            otherwise: Yup.string().nullable(),
        }),

    imageUrl: Yup.string()
        .nullable()
        .when('popupType', {
            is: (popupType: string) => popupType === POPUP_TYPES.PIL || popupType === POPUP_TYPES.FSIL || popupType === POPUP_TYPES.PIR || popupType === POPUP_TYPES.FSIR,
            then: Yup.string()
                .nullable()
                .min(2, "Please enter at least 2 characters!")
                .required("Image URL is required"),
            otherwise: Yup.string().nullable(),
        }),

    processDateStart: Yup.date()
        .nullable(),

    processDateEnd: Yup.date()
        .nullable()
        .min(
            Yup.ref('processDateStart'),
            'Process End Date should be after Process Start Date'
        ),

    moneyAmountMin: Yup.number()
        .nullable()
        .min(0, 'Min Money Amount cannot be less than 0'),

    moneyAmountMax: Yup.number()
        .nullable()
        .min(
            Yup.ref('moneyAmountMin'),
            'Max Money Amount should be greater than or equal to Min Money Amount'
        ),
});
