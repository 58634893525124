import { useQuery } from "react-query";
import { getCustomerCampaignLogs } from "@/services";

export const useGetCampaignLogData = (userUId: number | bigint | null) => {
 const { data: campaignLogs, isLoading: getCampaignLogDataLoading } = useQuery(
  ["campaignLogs", userUId],
  async () => {
   const campaignLogsData = await getCustomerCampaignLogs(userUId);
   console.log("campaignLogsData", campaignLogsData);
   return campaignLogsData || []; // Make sure to handle potential null or undefined cases
  },
 );

 return { campaignLogs, getCampaignLogDataLoading };
};

export default useGetCampaignLogData;
