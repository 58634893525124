import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { setDigitalOrderSending } from "@/services";
import { useMutation, useQueryClient } from "react-query";

export const useSetDigitalOrderSendingData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate: sendingMutate } = useMutation(
        async ({
            orderId,
            expiryDate,
            digitalCode = "useSetOrderSendingData",
        }: {
            orderId: bigint;
            expiryDate: string;
            digitalCode: string;
        }) => {
            console.log("code:", digitalCode);
            await setDigitalOrderSending({ orderId, expiryDate, digitalCode });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("orders");
                toastSuccess(toast, "Order set sending");
            },
            onError: (error: any) => {
                let errorMessage = "Order not set sending";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        sendingMutate,
    };
};
