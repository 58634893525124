import { Column } from "primereact";
import React, { useState } from "react";
import { DataTableSearchHeader, DataTableCrud } from "@/components";
import { customerFilters } from "@/constants";
import { useGetAddressesData } from "@/hooks";
import { useTranslation } from "react-i18next";

interface Props {
 customerId: number | null;
}

const AddressDataTable: React.FC<Props> = ({ customerId }) => {
 const [filters, setFilters] = useState(customerFilters);

 const { addresses, getAddressesIsLoading } = useGetAddressesData(customerId);
 const { t } = useTranslation();

 return (
  <>
   <DataTableCrud
    value={addresses}
    loading={getAddressesIsLoading}
    title="addresses"
    header={
     <DataTableSearchHeader
      title={t("User's Addresses")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
    filters={filters}
   >
    <Column
     field="title"
     header={t("Title")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="name"
     header={t("Name")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="surname"
     header={t("Surname")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="city"
     header={t("City")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>

    <Column
     field="district"
     header={t("District")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>

    <Column
     field="addressDescription"
     header={t("Address Description")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>

    <Column
     field="phoneNumber"
     header={t("Phone Number")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>
  </>
 );
};

export default AddressDataTable;
