import { getCustomers } from "@/services";
import { useQuery } from "react-query";

export const useGetCustomersData = (
    page: number | null,
    rowPerPage: number | null,
    isDeleted: boolean | null,
    isInactive: boolean | null,
    email: String | null,
    orderBy: String | null,
    isDesc: boolean | null,
) => {
    const { data: customers, isLoading: getCustomersIsLoading } = useQuery(
        ["customers", page, rowPerPage, isDeleted, isInactive, email, orderBy, isDesc],
        async () => {
            const customersData = await getCustomers(page, rowPerPage, isDeleted, isInactive, email, orderBy, isDesc);
            return customersData || [];
        },
    );
    return { customers, getCustomersIsLoading };
};
