import moment from "moment";
import { ICampaign } from "@/models";
import React, { useState } from "react";
import Draw from "@/pages/Campaigns/Draw";
import { Button, Dialog } from "primereact";
import { DailyDrawTicketDataTable } from "@/components/DataTable";

interface DrawDialogProps {
    visible: boolean;
    onHide: () => void;
    campaign: ICampaign | null;
}

const DrawDialog: React.FC<DrawDialogProps> = ({
    visible,
    onHide,
    campaign,
}) => {

    const [ticketListDialog, setTicketListDialog] = useState(false);

    const [ticketCountsDialog, setTicketCountsDialog] = useState(false);

    const openTicketListDialog = async () => {
        setTicketListDialog(true);
    };

    const hideTicketListDialog = () => {
        setTicketListDialog(false);
    };

    const openTicketCountsDialog = async () => {
        setTicketCountsDialog(true);
    };

    const hideTicketCountsDialog = () => {
        setTicketCountsDialog(false);
    };

    const cellStyle = {
        display: "table-cell",
        border: "1px solid black",
        padding: "0.5rem",
    };
    const headerCellStyle = {
        ...cellStyle,
        fontWeight: "bold",
    };
    const tableRowStyle = {
        display: "table-row",
    };
    const tableStyle: React.CSSProperties = {
        display: "table",
        borderCollapse: "collapse",
        width: "100%",
    };

    return (
        <>
            <Dialog visible={visible} onHide={onHide} header="Info">
                {campaign && (
                    <div style={tableStyle}>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Name</div>
                            <div style={cellStyle}>{campaign.name}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Description</div>
                            <div style={cellStyle}>{campaign.description}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Adv Count for Bonus</div>
                            <div style={cellStyle}>{campaign.limitValue}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Draw Code</div>
                            <div style={cellStyle}>{campaign.drawCode}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Draw Join End Date</div>
                            <div style={cellStyle}>
                                {moment(campaign.drawJoinEndDate).format("DD-MM-YYYY")}
                            </div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Draw Date</div>
                            <div style={cellStyle}>
                                {moment(campaign.drawDate).format("DD-MM-YYYY")}
                            </div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Total Ticket Count</div>
                            <div style={cellStyle}>{campaign.totalDrawTicketCount}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Remaining Ticket Count</div>
                            <div style={cellStyle}>{campaign.amount}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Ticket List</div>
                            <div style={cellStyle}><Button
                                id="p-ticket-list-button"
                                icon="pi pi-money-bill"
                                className="p-button p-button-primary m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={openTicketListDialog}
                            /></div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Daily Basis Ticket Counts</div>
                            <div style={cellStyle}><Button
                                id="p-ticket-counts-button"
                                icon="pi pi-chart-bar"
                                className="p-button p-button-warning m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={openTicketCountsDialog}
                            /></div>
                        </div>
                    </div>
                )}
            </Dialog>

            <Dialog
                visible={ticketListDialog}
                onHide={hideTicketListDialog}
                header={campaign?.name ?? 'Draw Info'}
            >
                <div className="datatable-crud">
                    <Draw campaign={campaign} />
                </div>
            </Dialog>

            <Dialog
                visible={ticketCountsDialog}
                onHide={hideTicketCountsDialog}
                header={campaign?.name ?? 'Draw Info'}
            >
                <DailyDrawTicketDataTable campaign={campaign} />
            </Dialog>

        </>
    );
};

export default DrawDialog;
