import { axiosInstance } from "@/api/axios.instance";
import { IEcpm } from "@/models";

export const getEcpms = async (): Promise<IEcpm[]> =>
    await axiosInstance.get(`/customer/ecpm`).then((res) => res.data);

export const addEcpm = async (ecpm: IEcpm) =>
    await axiosInstance.post(`/customer/ecpm/create`, ecpm);

export const updateEcpm = async (ecpm: IEcpm) =>
    await axiosInstance.put(`/customer/ecpm/update`, ecpm);

export const deleteEcpm = async (ecpmId: bigint) =>
    await axiosInstance.delete(`/customer/ecpm/delete`, {
        params: { id: ecpmId }
    });
