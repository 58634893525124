import { useQuery } from "react-query";
import { getCustomerBasic } from "@/services";

export const useGetCustomerBasicData = (customerId: number | null) => {
    const {
        data: customerBasic,
        isLoading: getCustomerBasicIsLoading,
    } = useQuery(
        ["customerBasic", customerId],
        async () => await getCustomerBasic(customerId ?? 0),
    );
    return { customerBasic, getCustomerBasicIsLoading };
};
