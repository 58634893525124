import * as Yup from "yup";

export const notificationSchema = Yup.object().shape({
    msgCode: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Message Code is required"),
    title: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Title is required"),
    text: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Text is required"),
});
