import { Chart, ProgressSpinner } from "primereact";
import { useState, useEffect } from "react";
import { useGetCampaignsUsageCountsData } from "@/hooks";

export default function CampaignsUsageCountsChart() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const {
        campaignsUsageCounts,
        getCampaignsUsageCountsIsLoading,
    } = useGetCampaignsUsageCountsData();

    useEffect(() => {
        if (campaignsUsageCounts) {
            const labels = campaignsUsageCounts.map((item: any) => item[0]);
            const data = campaignsUsageCounts.map((item: any) => item[1]);

            const backgroundColors = [
                '#1f78b4', // royal blue
                '#33a02c', // forest green
                '#e31a1c', // red
                '#ff7f00', // orange
                '#6a3d9a', // slate blue
                '#b15928', // brown
                '#a6cee3', // light blue
                '#b2df8a', // light green
                '#fb9a99', // light red
                '#fdbf6f', // light orange
                '#cab2d6', // light purple
                '#ffbb78', // light peach
                '#d2d2d2', // light gray
                '#8dd3c7', // light teal
                '#ffffb3'  // light yellow
            ];


            const chartData = {
                labels,
                datasets: [
                    {
                        data,
                        backgroundColor: backgroundColors,
                    }
                ],
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true
                        },
                    },
                },
            };

            setChartData(chartData);
            setChartOptions(options);
        }

    }, [campaignsUsageCounts, getCampaignsUsageCountsIsLoading]);

    return (
        <div className="card mb-0">
            <h4 style={{ textAlign: "center" }}>
                Usage Counts of Campaigns
            </h4>

            {getCampaignsUsageCountsIsLoading ?
                <div style={{ position: 'relative', top: '50%', left: '50%' }}>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
                </div>
                : <Chart type="pie" data={chartData} options={chartOptions} />
            }
        </div>
    );
}
