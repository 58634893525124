import moment from "moment";
import { Column } from "primereact";
import React, { useState } from "react";
import { DataTableCrud } from "@/components";
import { customerFilters } from "@/constants";
import { useTranslation } from "react-i18next";
import IAdsLog from "@/models/Entities/ads/IAdsLog";
import useGetCustomerAdsData from "@/hooks/analytic/userGetCustomerAds";

interface Props {
    customerId: number | null;
}

const CustomerAdsLogDataTable: React.FC<Props> = ({ customerId }) => {
    const [filters, setFilters] = useState(customerFilters);

    const { customerAds, getCustomerAdsIsLoading } =
        useGetCustomerAdsData(customerId);
    const { t } = useTranslation();

    return (
        <>
            <DataTableCrud
                value={customerAds}
                loading={getCustomerAdsIsLoading}
                title="customer ad records"
                filters={filters}
            >
                <Column
                    field="id"
                    header={t("id")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="source"
                    header={t("source")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="date"
                    header={t("date")}
                    sortable
                    style={{ minWidth: "12rem" }}
                    // DD-MM-YYYY hh:mm
                    body={(rowData: IAdsLog) => (
                        <>{moment.utc(rowData.date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                ></Column>
            </DataTableCrud>
        </>
    );
};

export default CustomerAdsLogDataTable;
