import moment from "moment";
import { Column } from "primereact";
import React, { useState } from "react";
import { DataTableCrud } from "@/components";
import { customerFilters } from "@/constants";
import { useTranslation } from "react-i18next";
import useGetCampaignLogData from "@/hooks/analytic/useGetCampaignLogData";

interface Props {
    customerId: number | null;
}

const CustomerCampaignLogDataTable: React.FC<Props> = ({ customerId }) => {
    const [filters, setFilters] = useState(customerFilters);

    const { campaignLogs, getCampaignLogDataLoading } =
        useGetCampaignLogData(customerId);
    const { t } = useTranslation();

    return (
        <>
            <DataTableCrud
                value={campaignLogs}
                loading={getCampaignLogDataLoading}
                title="campaign logs"
                // header={
                //  <DataTableSearchHeader
                //   title={t("User Address")}
                //   filters={filters}
                //   onChange={(e: any) => setFilters(e)}
                //  />
                // }
                filters={filters}
            >
                <Column
                    field="id"
                    header="Id"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="campaignName"
                    header="Campaign Name"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="date"
                    header="Date"
                    sortable
                    style={{ minWidth: "12rem" }}
                    body={(rowData) => <>{moment(rowData.date).format("DD-MM-YYYY")}</>}
                ></Column>
            </DataTableCrud>
        </>
    );
};

export default CustomerCampaignLogDataTable;
