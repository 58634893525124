import React from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { IDraw } from "@/models";
import { useGetDrawUserData } from "@/hooks";

interface Props {
    visible: boolean;
    onHide: () => void;
    rowData: IDraw | null;
}

const DrawUserDialog: React.FC<Props> = ({ visible, onHide, rowData }) => {
    const { user, getDrawUserDataIsLoading } = useGetDrawUserData(
        rowData?.customerFBId ?? "",
    );

    const renderDialogContent = () => {
        if (getDrawUserDataIsLoading) {
            return <div>User Info is Loading...</div>;
        }
        if (user) {
            const cellStyle = {
                display: "table-cell",
                border: "1px solid black",
                padding: "0.5rem",
            };
            const headerCellStyle = {
                ...cellStyle,
                fontWeight: "bold",
            };
            const tableRowStyle = {
                display: "table-row",
            };
            const tableStyle: React.CSSProperties = {
                display: "table",
                borderCollapse: "collapse",
                width: "100%",
            };

            return (
                <div style={tableStyle}>
                    <div style={tableRowStyle}>
                        <div style={headerCellStyle}>ID:</div>
                        <div style={cellStyle}>{`${user?.id ?? 0}`}</div>
                    </div>
                    <div style={tableRowStyle}>
                        <div style={headerCellStyle}>Name:</div>
                        <div style={cellStyle}>{user.name}</div>
                    </div>
                    <div style={tableRowStyle}>
                        <div style={headerCellStyle}>Surname:</div>
                        <div style={cellStyle}>{user.surname}</div>
                    </div>
                    <div style={tableRowStyle}>
                        <div style={headerCellStyle}>Email:</div>
                        <div style={cellStyle}>{user.email}</div>
                    </div>
                    <div style={tableRowStyle}>
                        <div style={headerCellStyle}>Birth Date:</div>
                        <div style={cellStyle}>
                            {user.birthDate ? moment(user.birthDate).format("DD-MM-YYYY") : "-"}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <strong>No user information available.</strong>
            </div>
        );
    };

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header="User Information"
            modal
            style={{ width: "40rem" }}
        >
            {renderDialogContent()}
        </Dialog>
    );
};

export default DrawUserDialog;
