import { useQuery } from "react-query";
import { getSingleNotificationMessages } from "@/services";

export const useGetSingleNotificationMessagesData = () => {
    const { data: notificationMessages, isLoading: getNotificationMessagesIsLoading } = useQuery(
        "singleNotificationMessages",
        getSingleNotificationMessages,
    );
    return { notificationMessages, getNotificationMessagesIsLoading };
};
