import {
  DataTableCrud,
  ExcelExporter,
  DrawUserDialog,
  DataTableSearchHeader,
} from "@/components";
import moment from "moment";
import { IDraw } from "@/models";
import { useState } from "react";
import { drawFilters } from "@/constants";
import { useTranslation } from "react-i18next";
import { Column, Button, Tooltip } from "primereact";
import {
  useGetDrawData,
  useGetDrawTicketListData
} from "@/hooks";

interface Props {
  campaignId: bigint | null;
  drawJoinEndDate: string | null;
}

const DrawDataTable: React.FC<Props> = ({ campaignId, drawJoinEndDate }) => {
  const [filters, setFilters] = useState(drawFilters);
  const { draw, getDrawDataIsLoading } = useGetDrawData(campaignId);
  const { drawTicketList, getDrawTicketListIsLoading } = useGetDrawTicketListData(campaignId);

  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<IDraw | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleInfoButtonClick = (rowData: IDraw) => {
    setSelectedRowData(rowData);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const exportExcelData = drawTicketList
    ? drawTicketList.map((rowData: any) => ({
      name: rowData[0],
      surname: rowData[1],
      email: rowData[2],
      ticketCode: rowData[3],
    }))
    : [];

  return (
    <>

      <DataTableCrud
        value={draw}
        header={
          <DataTableSearchHeader
            title={t("Ticket List")}
            filters={filters}
            onChange={(e) => setFilters(e)}
            customTitleStyles={{ fontSize: "1.1rem" }}
            inputStyles={{ width: "150px" }}
          />
        }
        filters={filters}
        loading={getDrawDataIsLoading}
        title="tickets"
        emptyMessage={t("No tickets found")}
      >
        <Column field="id" header="Id" style={{ minWidth: "5rem" }} />
        <Column
          field="email"
          header="Email"
          sortable
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="ticketDateStr"
          header="Ticket Date"
          dataType="date"
          sortable
          style={{ minWidth: "12rem" }}
          body={(rowData) => <>{moment(rowData.ticketDateStr).format("DD-MM-YYYY")}</>}
        />
        <Column
          body={(rowData: IDraw) => (
            <>
              <Tooltip target="#p-info-button" content="Info" position="bottom" />
              <Button
                id={"p-info-button"}
                icon="pi pi-info-circle"
                className="p-button-rounded p-button-secondary m-2"
                onClick={() => handleInfoButtonClick(rowData)}
                style={{ height: 35, width: 35 }}
              />
            </>
          )}
        />
      </DataTableCrud>

      <DrawUserDialog
        visible={showDialog}
        onHide={hideDialog}
        rowData={selectedRowData}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <ExcelExporter
          headers={["Name", "Surname", "Email", "Ticket Code"]}
          data={exportExcelData}
          isLoading={getDrawTicketListIsLoading}
          disabled={new Date() < new Date(drawJoinEndDate ?? '')}
          filename={"draw_data - " + campaignId}
        />
      </div>
      <div style={{ height: "1rem" }} />
    </>
  );
};

export default DrawDataTable;
