import { useQuery } from "react-query";
import { getDrawPeriods } from "@/services";

export const useGetDrawPeriodsData = (
    campaignId: bigint | null,
) => {
    const {
        data: drawPeriods,
        isLoading: getDrawPeriodsIsLoading,
    } = useQuery(["drawPeriods", campaignId], async () => {
        if (campaignId !== null) {
            return await getDrawPeriods(campaignId);
        }
    });
    return { drawPeriods, getDrawPeriodsIsLoading };
};
