import { Button, Toolbar, Tooltip } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableToolbarProps {
 disableDeleteButton?: boolean;
 openDeleteDialog?: () => void;
 openAddDialog?: () => void;
 children?: React.ReactNode;
}

const DataTableToolbar: React.FC<DataTableToolbarProps> = ({
 disableDeleteButton,
 openAddDialog,
 openDeleteDialog,
 children,
}) => {
 const { t } = useTranslation();

 const leftToolbarTemplate = () => {
  return (
   <>
    <Tooltip target="#p-add-button" position="bottom" content="Add" />
    <Tooltip target="#p-delete-button" position="bottom" content="Delete" />
    {openAddDialog && (<Button
     label={t("New")}
     id="p-add-button"
     icon="pi pi-plus"
     className="p-button-success mr-2"
     onClick={openAddDialog}
    />)}

    {/*     <Button
     id="p-delete-button"
     label={t("Delete")}
     icon="pi pi-trash"
     className="p-button-danger mr-2"
     onClick={openDeleteDialog}
     disabled={disableDeleteButton}
    /> */}

    {children}
   </>
  );
 };

 return <Toolbar className="mb-4" left={leftToolbarTemplate} />;
};

export default DataTableToolbar;
