import { ICampaign, IMbi } from "@/models";
import React, { useRef, useState } from "react";
import { PopupImageUploadDialog } from "@/components";
import { Button, Column, DataTable, Dialog, Toast } from "primereact";

interface CampaignPopupImagesDialogProps {
    visible: boolean;
    onHide: () => void;
    campaign: ICampaign | null;
    savePopupImages: (rowData: ICampaign | null, form: FormData) => void;
}

const CampaignPopupImagesDialog: React.FC<CampaignPopupImagesDialogProps> = ({
    visible,
    onHide,
    campaign,
    savePopupImages,
}) => {
    const toast = useRef<Toast>(null);
    const formDataRef = useRef(new FormData());

    const [imageType, setImageType] = useState<number | null>(null);
    const [imageDialog, setImageDialog] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({
        file1: null,
        file2: null,
    });

    const openImageDialog = (imageType: number | null) => {
        setImageType(imageType);
        setImageDialog(true);
    };

    const hideImageDialog = () => {
        setImageDialog(false);
    };

    const saveImage = (file: FileList, imageType: number | null) => {
        const key = `file${imageType?.toString() ?? '0'}`;
        if (formDataRef.current.has(key)) {
            formDataRef.current.delete(key);
        }
        formDataRef.current.append(key, file[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
            setUploadedFiles((prev) => ({
                ...prev,
                [key]: e.target?.result as string,
            }));
        };

        reader.readAsDataURL(file[0]);
        hideImageDialog();
    };

    const handleDialogHide = () => {
        setUploadedFiles({
            file1: null,
            file2: null,
        });
        onHide();
    };


    const renderDialogContent = () => {

        return (
            <DataTable
                value={[{}]}
            >
                <Column
                    header="Top Image"
                    body={() => (
                        <>
                            <Button
                                icon="pi pi-images"
                                className="p-button p-button-primary m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={() => openImageDialog(1)}
                            />
                            <div
                                className={`pi pi-check-circle`}
                                style={{
                                    fontSize: '1.5rem', marginLeft: '1rem',
                                    color: uploadedFiles.file1 ? 'green' : 'grey',
                                }}
                            />
                        </>
                    )}
                />
                <Column
                    header="Bottom Image"
                    body={() => (
                        <>
                            <Button
                                icon="pi pi-images"
                                className="p-button p-button-success m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={() => openImageDialog(2)}
                            />
                            <div
                                className={`pi pi-check-circle`}
                                style={{
                                    fontSize: '1.5rem', marginLeft: '1rem',
                                    color: uploadedFiles.file2 ? 'green' : 'grey',
                                }}
                            />
                        </>
                    )}
                />
            </DataTable>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                footer={
                    <div>
                        <Button
                            label="UPLOAD"
                            icon="pi pi-check"
                            onClick={() => {
                                savePopupImages(campaign, formDataRef.current);
                                setUploadedFiles({
                                    file1: null,
                                    file2: null,
                                });
                            }}
                            disabled={!uploadedFiles.file1 || !uploadedFiles.file2}
                        />
                    </div>
                }
                header="Campaign Pop-up Images"
                modal
                className="p-fluid"
                onHide={handleDialogHide}
            >
                {renderDialogContent()}
            </Dialog>

            <PopupImageUploadDialog
                rowData={campaign}
                visible={imageDialog}
                imageSrc={imageType === 1 ? uploadedFiles.file1 : uploadedFiles.file2}
                onHide={hideImageDialog}
                saveImage={saveImage}
                imageType={imageType}
            />
        </>
    );
};

export default CampaignPopupImagesDialog;
