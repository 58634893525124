import { useQuery } from "react-query";
import { getCustomerDetailedInfo } from "@/services";

export const useGetCustomerDetailedInfoData = (customerId: number | null) => {
    const {
        data: customerDetailedInfo,
        isLoading: getCustomerDetailedInfoIsLoading,
    } = useQuery(
        ["customerDetailedInfo", customerId],
        async () => await getCustomerDetailedInfo(customerId ?? 0),
    );
    return { customerDetailedInfo, getCustomerDetailedInfoIsLoading };
};
