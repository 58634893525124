import { DrawProductDataTable } from "@/components";

const DrawProducts = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <DrawProductDataTable />
            </div>
        </div>
    );
};

export default DrawProducts;
