import { IFieldProps } from "@/models";
import { InputText, InputTextProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface InputFieldProps extends InputTextProps, IFieldProps {
    multiline?: boolean;
    visible?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
    getFormErrorMessage,
    labelName,
    labelText,
    multiline,
    visible = true,
    ...rest
}) => {
    const { t } = useTranslation();
    if (visible) {
        return (
            <div className="field">
                <label htmlFor={labelName}>{t(labelText)}</label>
                {multiline ? (
                    <textarea
                        id={labelName}
                        name={labelName}
                        rows={6}
                        style={{
                            width: "100%",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "6px",
                            resize: "vertical",
                        }}
                        {...(rest as any)}
                    />
                ) : (
                    <InputText
                        style={{
                            width: "100%",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "6px",
                        }}
                        {...rest}
                    />
                )}
                {getFormErrorMessage != null && getFormErrorMessage()}
            </div>
        );
    } else {
        return null;
    }

};

export default InputField;
