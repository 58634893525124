import moment from "moment";
import { Column, DataTable } from "primereact";
import { useGetApuAndAdWatchedUserCountInLast7DaysData } from "@/hooks";

const ApuAndAdWatchedUserCountInLast7DaysDataTable = () => {
    const { apuAndAdWatchedUserCountInLast7Days, getApuAndAdWatchedUserCountInLast7DaysIsLoading } = useGetApuAndAdWatchedUserCountInLast7DaysData();

    return (
        <div className="col-12 m-0">
            <div className="card mb-0">
                <h5>Ad Watched Users and Ads per User Count</h5>
                <DataTable
                    value={apuAndAdWatchedUserCountInLast7Days}
                    loading={getApuAndAdWatchedUserCountInLast7DaysIsLoading}
                    responsiveLayout="scroll"
                    paginator
                    rows={25}
                    rowsPerPageOptions={[25, 50, 100]}
                >
                    <Column
                        field="date"
                        header="Date"
                        sortable
                        body={(rowData) => <>{moment(rowData.date).format("DD-MM-YYYY")}</>}
                    />
                    <Column
                        field="numbersOfAdsViewUniqueUserCount"
                        header="Ad Watched User"
                        sortable
                        style={{ width: "35%" }}
                    />
                    <Column
                        field="userAdViewRate"
                        header="Ads per User"
                        sortable
                        style={{ width: "35%" }}
                    />
                </DataTable>
            </div>
        </div>
    );
};

export default ApuAndAdWatchedUserCountInLast7DaysDataTable;
