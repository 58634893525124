import { useState, useRef } from "react";
import {
    Column,
    Button,
    Toast,
    Dropdown,
    ColumnFilterElementTemplateOptions,
    Tooltip,
} from "primereact";
import { DataTableCrud, DataTableSearchHeader, OrderDetailDialog } from "@/components";
import { useTranslation } from "react-i18next";
import { orderFilters, inActiveOrderTypes } from "@/constants";
import { useGetInActiveOrdersData } from "@/hooks";
import { IOrder } from "@/models";
import moment from "moment";

const InActiveOrderDataTable = () => {
    const [filters, setFilters] = useState(orderFilters);
    const toast = useRef<Toast>(null);
    const { orders, getInActiveOrdersIsLoading } = useGetInActiveOrdersData();
    const { t } = useTranslation();

    const [order, setOrder] = useState<IOrder>();
    const [orderDialog, setOrderDialog] = useState(false);

    const openOrderDialog = (rowData: IOrder) => {
        setOrder({ ...rowData });
        setOrderDialog(true);
    };

    const hideOrderDialog = () => {
        setOrderDialog(false);
    };


    return (
        <>
            <Toast ref={toast} />

            <DataTableCrud
                value={orders}
                filterDisplay="row"
                header={
                    <DataTableSearchHeader
                        title={t("Manage In Active Orders")}
                        filters={filters}
                        onChange={(e) => setFilters(e)}
                    />
                }
                filters={filters}
                globalFilterFields={["customerName", "productName", "date", "status"]}
                loading={getInActiveOrdersIsLoading}
                title="orders"
                emptyMessage={t("No orders found")}
            >
                <Column field="id" header="Id" />
                <Column
                    field="customerName"
                    header="User Name"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by name"
                />
                <Column
                    field="productName"
                    header="Product Name"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by name"
                />
                <Column
                    field="date"
                    header="Date"
                    dataType="date"
                    sortable
                    style={{ minWidth: "12rem" }}
                    body={(rowData: IOrder) => (
                        <>{moment.utc(rowData.date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                />
                <Column
                    field="status"
                    header="Status"
                    sortable
                    filter
                    showFilterMenu={false}
                    filterElement={(options: ColumnFilterElementTemplateOptions) => {
                        return (
                            <Dropdown
                                value={options.value}
                                options={inActiveOrderTypes}
                                onChange={(e) => options.filterApplyCallback(e.value)}
                                itemTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option?.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                valueTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option?.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                className="p-column-filter"
                            />
                        );
                    }}
                    body={(rowData: IOrder) => (
                        <span
                            className={`badge status-${rowData.status ? rowData?.status?.toLowerCase() : ""
                                }`}
                        >
                            {rowData.status}
                        </span>
                    )}
                />
                <Column
                    body={(rowData: IOrder) => (
                        <>
                            <Tooltip target="#p-info-button" content="Info" position="bottom" />
                            <Button
                                id="p-info-button"
                                icon="pi pi-info-circle"
                                className="p-button-rounded p-button-secondary m-2"
                                onClick={() => {
                                    openOrderDialog(rowData);
                                }}
                            />
                        </>
                    )}
                    exportable={false}
                ></Column>
            </DataTableCrud>

            <OrderDetailDialog
                visible={orderDialog}
                isLoading={getInActiveOrdersIsLoading}
                onHide={hideOrderDialog}
                order={order}
            />

        </>
    );
};

export default InActiveOrderDataTable;
