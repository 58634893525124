import { IFieldProps } from "@/models";
import { Dropdown, DropdownProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DropdownFieldProps extends DropdownProps, IFieldProps { }

const DropdownField: React.FC<DropdownFieldProps> = ({
    getFormErrorMessage,
    labelName,
    labelText,
    visible = true,
    ...rest
}) => {
    const { t } = useTranslation();
    if (visible) {
        return (
            <div className="field">
                <label htmlFor={labelName}>{t(labelText)}</label>
                <Dropdown {...rest} />
                {getFormErrorMessage != null && getFormErrorMessage()}
            </div>
        );
    } else {
        return null;
    }
};

export default DropdownField;
