import { NotificationMessagesDataTable } from "@/components";

const NotificationMessages = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <NotificationMessagesDataTable />
            </div>
        </div>
    );
};

export default NotificationMessages;
