import { Toast } from "primereact";
import { IPeriod } from "@/models";
import { useMutation } from "react-query";
import { addDrawPeriods } from "@/services";
import { toastError, toastSuccess } from "@/utils";

export const useAddDrawPeriodsData = (toast: React.RefObject<Toast>) => {
    const {
        mutate,
        isLoading: addDrawPeriodIsLoading,
        isSuccess: addDrawPeriodIsSuccess,
    } = useMutation(
        async (periods: IPeriod[]) => await addDrawPeriods(periods),
        {
            onSuccess: () => {
                toastSuccess(toast, "Periods added");
            },
            onError: (error: any) => {
                let errorMessage = "Periods not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        addDrawPeriodIsLoading,
        addDrawPeriodIsSuccess,
    };
};
