import { Toast } from "primereact";
import { useMutation } from "react-query";
import { deleteCampaignImage } from "@/services";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteCampaignImageData = (toast: React.RefObject<Toast>) => {
    const { mutate, isLoading: deleteCampaignImageIsLoading } = useMutation(
        async (campaignId: bigint | number) => await deleteCampaignImage(campaignId),
        {
            onSuccess: () => {
                toastSuccess(toast, "Campaign image deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign image not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteCampaignImageIsLoading,
    };
};
