import { useQuery } from "react-query";
import { getLastWeekGameInfo } from "@/services";

export const useGetLastWeekGameInfoData = () => {
    const { data: lastWeekGameInfo, isLoading: getLastWeekGameInfoIsLoading } = useQuery(
        "lastWeekGameInfo",
        getLastWeekGameInfo,
    );
    return { lastWeekGameInfo, getLastWeekGameInfoIsLoading };
};
