import React from "react";
import { CONFIG_VARS } from "@/constants";
import { ProgressSpinner } from "primereact";
import { useGetCustomerDetailedInfoData, useTheme } from "@/hooks";

interface Props {
    customerId: number | null;
}

const CustomerDetailedInfoDataTable: React.FC<Props> = ({ customerId }) => {
    const { layoutColorMode } = useTheme();
    const { customerDetailedInfo, getCustomerDetailedInfoIsLoading } = useGetCustomerDetailedInfoData(customerId ?? 0);

    const getColor = (percentage: number): string => {
        if (percentage >= 70) {
            return 'active';
        } else if (percentage >= 40) {
            return 'preparing';
        } else {
            return 'deleted';
        }
    };

    const renderRow = (label: string, value: any) => (
        <tr>
            <th
                style={{
                    border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT ? "rgba(0, 0, 0, .05)" : "rgba(255, 255, 255, 0.6)"}`,
                    padding: "8px",
                }}
            >
                {label}
            </th>
            <td
                style={{
                    border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT ? "rgba(0, 0, 0, .05)" : "rgba(255, 255, 255, 0.6)"}`,
                    padding: "8px",
                }}
            >
                {typeof value === 'string' || typeof value === 'number' ? value : value}
            </td>
        </tr>
    );

    if (getCustomerDetailedInfoIsLoading) {
        return (
            <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
            </div>
        );
    }

    if (!customerDetailedInfo) {
        return null;
    }

    const usageRatio = ((customerDetailedInfo.totalLoginDayCount ?? 0) * 100) / (customerDetailedInfo.totalPassedDayFromRegister ?? 1);

    return (
        <table
            style={{
                border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT ? "rgba(0, 0, 0, .05)" : "rgba(255, 255, 255, 0.6)"}`,
                borderCollapse: "collapse",
                textAlign: "left",
                width: "100%",
            }}
        >
            <tbody>
                {renderRow("Number of days user logged in:", customerDetailedInfo.totalLoginDayCount?.toString())}
                {renderRow("Number of days since registration:", customerDetailedInfo.totalPassedDayFromRegister?.toString())}
                {renderRow("Usage Ratio:", <span className={`badge status-${getColor(usageRatio)}`}>{usageRatio.toFixed()}%</span>)}
                {renderRow("Invited User Count:", customerDetailedInfo.invitedUserCount?.toString())}
                {renderRow("Number of Ads Watched:", customerDetailedInfo.totalWatchedAdCount?.toString())}
                {renderRow("User Based Income($):", customerDetailedInfo.overallIncome?.toString())}
            </tbody>
        </table>
    );
};

export default CustomerDetailedInfoDataTable;
