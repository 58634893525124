import { useQuery } from "react-query";
import { getCustomerAds } from "@/services";

export const useGetCustomerAdsData = (userUId: number | bigint | null) => {
 const { data: customerAds, isLoading: getCustomerAdsIsLoading } = useQuery(
  ["customerAds", userUId],
  async () => {
   const customerAdsData = await getCustomerAds(userUId);
   console.log("customerAdsData", customerAdsData);
   return customerAdsData || []; // Make sure to handle potential null or undefined cases
  },
 );

 return { customerAds, getCustomerAdsIsLoading };
};

export default useGetCustomerAdsData;
