import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";
import { addCampaignImages, addCampaignInfographImage, addCampaignInfographScndImage } from "@/services";

export const useAddCampaignImagesData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCampaignImagesIsLoading,
        isSuccess: addCampaignImagesIsSuccess,
    } = useMutation(
        async ({ campaignImages, imageType }: {
            campaignImages: FormData,
            imageType?: number | null
        }) => {
            if (imageType === 2) {
                await addCampaignInfographScndImage(campaignImages);
            } else if (imageType === 1) {
                await addCampaignInfographImage(campaignImages);
            } else {
                await addCampaignImages(campaignImages);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Campaign image added");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign image not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addCampaignImagesIsLoading, addCampaignImagesIsSuccess };
};
