import { PopupHistoryDataTable } from "@/components";

const PopupHistory = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <PopupHistoryDataTable />
            </div>
        </div>
    );
};

export default PopupHistory;
