import { useQuery } from "react-query";
import { getCustomerNotifications } from "@/services";

export const useGetCustomerNotificationsData = (customerId: number | null) => {
    const { data: customerNotifications, isLoading: getCustomerNotificationsIsLoading } = useQuery(
        ["customerNotifications", customerId],
        async () => {
            const customerNotificationsData = await getCustomerNotifications(customerId);
            console.log("customerNotificationsData", customerNotificationsData);
            return customerNotificationsData || [];
        },
    );

    return { customerNotifications, getCustomerNotificationsIsLoading };
};

