import { useQuery } from "react-query";
import { getOrderDetails } from "@/services";

export const useGetOrderDetailsData = (orderId: bigint | null) => {
    const { data: orderDetails, isLoading: getOrderDetailsIsLoading } = useQuery(
        ["orderDetails", orderId],
        async () => {
            if (orderId !== null && orderId !== undefined) {
                return await getOrderDetails(orderId);
            }
            return null;
        },
    );
    return { orderDetails, getOrderDetailsIsLoading };
};

