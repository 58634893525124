import { Toast } from "primereact";
import { IEcpm } from "@/models";
import { addEcpm } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddEcpmData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addEcpmIsLoading,
        isSuccess: addEcpmIsSuccess,
    } = useMutation(async (ecpm: IEcpm) => await addEcpm(ecpm), {
        onSuccess: () => {
            queryClient.invalidateQueries("ecpms");
            toastSuccess(toast, "Ecpm added");
        },
        onError: (error: any) => {
            let errorMessage = "Ecpm not added";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        addEcpmIsLoading,
        addEcpmIsSuccess,
    };
};
