import { Toast } from "primereact";
import { ICampaign } from "@/models";
import { updateCampaign } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateCampaignData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: updateCampaignIsLoading,
        isSuccess: updateCampaignIsSuccess,
    } = useMutation(
        async (campaign: Omit<ICampaign, "imageUrl">) =>
            await updateCampaign(campaign),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Campaign updated");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign not updated";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        updateCampaignIsLoading,
        updateCampaignIsSuccess,
    };
};
