import { getFootnotes } from "@/services";
import { useQuery } from "react-query";

export const useGetFootnotesData = (enabled?: boolean) => {
    const {
        data: footnotes,
        isLoading: getFootnotesIsLoading,
        refetch: refetchGetFootnotes,
    } = useQuery("footnotes", getFootnotes, {
        enabled,
    });
    return { footnotes, getFootnotesIsLoading, refetchGetFootnotes };
};
