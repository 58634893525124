import { axiosInstance } from "@/api/axios.instance";
import { IFootnote, IProduct } from "@/models";
import { QueryFunctionContext } from "react-query";

export const getProducts = async (): Promise<IProduct[]> =>
  await axiosInstance.get(`/product`).then((res) => res.data);

export const getDrawProducts = async (): Promise<IProduct[]> =>
  await axiosInstance.get(`/product/draw`).then((res) => res.data);

export const getFootnotes = async (): Promise<IFootnote[]> =>
  await axiosInstance.get(`/product/footnote`).then((res) => res.data);

export const getProduct = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<IProduct> => {
  const [, productId] = queryKey;
  return await axiosInstance
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    .get(`/product/${productId}`)
    .then((res) => res.data);
};

export const getProductDetail = async (productId: bigint | number) => {
  return await axiosInstance
    .get(`/product/${productId}`)
    .then((res) => res.data);
};

export const addProduct = async (product: Omit<IProduct, "imageUrl">) =>
  await axiosInstance.post(`/product`, product);

export const addProductImages = async (productImages: FormData) =>
  await axiosInstance.post(`/product/images/upload`, productImages, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const addProductInfographImage = async (infographImage: FormData) =>
  await axiosInstance.post(`/product/images/uploadInfoGraph`, infographImage, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const addProductInfographScndImage = async (infographScndImage: FormData) =>
  await axiosInstance.post(`/product/images/uploadInfoGraphScnd`, infographScndImage, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteProductImage = async (
  imageId: bigint | number,
): Promise<any> =>
  await axiosInstance.delete(`/product/images/delete/${imageId}`);

export const updateProduct = async (product: Omit<IProduct, "imageUrl">) =>
  await axiosInstance.put(`/product`, product);

export const updateProductStatus = async (productId: number, status: string) =>
  await axiosInstance.put(`/product/status`, null, {
    params: {
      id: productId,
      status: status,
    }
  });

export const deleteProduct = async (productId: number) =>
  await axiosInstance.delete(`/product/${productId}`);

export const setAsMainImage = async (
  productId: bigint | number | null,
  imageId: bigint | number | null,
) => {
  try {
    await axiosInstance.post("/product/imagesetmain/", null, {
      params: {
        productId: productId,
        imageId: imageId,
      },
    });
    console.log("Request successful");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const orderProducts = async (products: Array<Omit<IProduct, "imageUrl">>) =>
  await axiosInstance.post(`/product/updateOrderAndStatus`, products);
