import { GENDER } from "@/constants";
import { CAMPAIGN_TYPES, ORDERS_STATUS, POPUP_STATUS, ROLE, STATUS } from "./enums";

export const customerRadioButtonFields = [GENDER.MAN, GENDER.WOMAN];
export const statusRadioButtonFields = [STATUS.ACTIVE, STATUS.INACTIVE];

export const supplierTypes = ["STANDART"];
export const roleTypes = [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR];
export const activeOrderTypes = [
    ORDERS_STATUS.NEW,
    ORDERS_STATUS.PREPARING,
    ORDERS_STATUS.SENT,
    ORDERS_STATUS.RESENT_NEW,
];

export const inActiveOrderTypes = [
    ORDERS_STATUS.FINISHED,
    ORDERS_STATUS.CANCELED,
    ORDERS_STATUS.RETURN,
];
export const statusTypes = [STATUS.ACTIVE, STATUS.INACTIVE];
export const customerStatusTypes = [STATUS.ACTIVE, STATUS.INACTIVE, "DELETED"];
export const popupStatusTypes = [POPUP_STATUS.ALL,POPUP_STATUS.ACTIVE, POPUP_STATUS.INACTIVE];
export const campaignTypes = [
 CAMPAIGN_TYPES.DW,
 CAMPAIGN_TYPES.OT,
 CAMPAIGN_TYPES.RC,
 CAMPAIGN_TYPES.SC,
 CAMPAIGN_TYPES.VC,
 CAMPAIGN_TYPES.DRW,
 CAMPAIGN_TYPES.TBD,
 CAMPAIGN_TYPES.DRT,
];
