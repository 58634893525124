import * as Yup from "yup";

export const addCkpSchema = Yup.object().shape({
    title: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Title is required"),
    text: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Text is required"),
    amount: Yup.string().nullable()
        .min(1, "Please enter at least 2 characters!")
        .required("Amount is required"),
});
