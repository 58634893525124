import { Toast } from "primereact";
import { deleteProductImage } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteProductImageData = (toast: React.RefObject<Toast>, isItProduct: boolean = true) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: deleteProductImageIsLoading } = useMutation(
        async (imageId: bigint | number) => await deleteProductImage(imageId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isItProduct ? "products" : "drawProducts");
                toastSuccess(toast, "Product image deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Product image not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteProductImageIsLoading,
    };
};
