import { axiosInstance } from "@/api/axios.instance";
import {
  IAdsCountWithDate,
  ICustomer,
  ICustomerCount,
  IOrder,
  IProduct,
} from "@/models";
import IAdsLog from "@/models/Entities/ads/IAdsLog";
import ICampaignAds from "@/models/Entities/ads/ICampaignAds";
import ICustomerConvertStep from "@/models/Entities/customer/ICustomerConvertStep";
import { QueryFunctionContext } from "react-query";
import { Logger } from "sass";

export const getOrderList = async (): Promise<IOrder[]> =>
  await axiosInstance.get(`analytics/order/countbynew`).then((res) => res.data);

export const getCustomerCount = async (): Promise<ICustomerCount> =>
  await axiosInstance.get(`analytics/customers/count`).then((res) => res.data);

export const getRetention = async (): Promise<any> =>
  await axiosInstance.get(`analytics/customers/retList`).then((res) => res.data);

export const getRegisteredCustomerCountWeekly = async (): Promise<any> =>
  await axiosInstance
    .get(`/analytics/customers/weeklyregisteredcount`)
    .then((res) => res.data);

export const getTopTenCustomers = async (): Promise<ICustomer[]> =>
  await axiosInstance.get(`analytics/customers/topten`).then((res) => res.data);

export const getPromotionCodes = async (): Promise<any> =>
  await axiosInstance.get(`/analytics/campaigns/getActivePromotionCodes`).then((res) => res.data);

export const getQuantityProducts = async (): Promise<IProduct[]> =>
  await axiosInstance
    .get(`analytics/products/listbyquantity`)
    .then((res) => res.data);

export const getAdsCountWithDate = async (): Promise<IAdsCountWithDate> =>
  await axiosInstance
    .get(`/analytics/customers/dailyadsview`)
    .then((res) => res.data);

export const getCustomerAds = async (
  userUId: bigint | number | null,
): Promise<IAdsLog> => {
  const response = await axiosInstance.get("/analytics/customers/ads", {
    params: {
      userUId: userUId,
    },
  });

  const campaignLogs: IAdsLog = response.data;
  return campaignLogs;
};

export const getCustomerOrders = async (
  customerId: number | null,
): Promise<IOrder> => {
  const response = await axiosInstance.get(`/orders/orderList/${customerId ?? ''}`);

  const orderLogs: IOrder = response.data;
  return orderLogs;
};

export const getCustomerCampaignLogs = async (
  userUId: bigint | number | null,
): Promise<ICampaignAds> => {
  const response = await axiosInstance.get(
    "/analytics/customers/campaignlogs",
    {
      params: {
        userUId: userUId,
      },
    },
  );

  const campaignLogs: ICampaignAds = response.data;
  return campaignLogs;
};



export const getCustomerConvertStepLogs = async (
  customerId: bigint | number | null,
): Promise<ICustomerConvertStep> => {
  const response = await axiosInstance.get(
    "/analytics/customers/convertStepStats",
    {
      params: {
        customerId: customerId,
      },
    },
  );
  // console.log("Request successful");

  // console.log("response.data", response.data);
  // Assuming that the response.data contains the campaign logs data
  const customerConvertStep: ICustomerConvertStep = response.data;
  return customerConvertStep;
};



export const getAdsWatchCountPerDay = async (): Promise<any> =>
  await axiosInstance
    .get(`/analytics/ads/watchcountperday`)
    .then((res) => res.data);

export const getAdsWatchedAndNeverWatchedCustomerCount =
  async (): Promise<any> =>
    await axiosInstance
      .get(`/analytics/customers/getCustomerWithAdWatchedAndNoAdWatchedCount`)
      .then((res) => res.data);

export const getAdsWatchCountByActionType = async (): Promise<any> =>
  await axiosInstance
    .get(`/analytics/ads/watchcountperdaybysource`)
    .then((res) => res.data);

export const getCustomerCountOneMonth = async (): Promise<any> =>
  await axiosInstance
    .get(`/analytics/customers/countonemounth`)
    .then((res) => res.data);

export const getCustomerCountByCkp = async (): Promise<any> =>
  await axiosInstance
    .get(`analytics/customers/getMoneyAmountRangeWithThousand`)
    .then((res) => res.data);

export const getCountWithMoneyBetween = async ({
  queryKey,
}: QueryFunctionContext<
  [
    string,
    {
      moneyAmountFirst: number;
      moneyAmountSecond: number;
    },
  ]
>): Promise<any> => {
  const [, { moneyAmountFirst, moneyAmountSecond }] = queryKey;
  return await axiosInstance
    .get(
      `customer/countwithmoneybetween?moneyAmountFirst=${moneyAmountFirst}&moneyAmountSecond=${moneyAmountSecond}`,
    )
    .then((res) => res.data);
};

export const getDrawList = async (): Promise<any> =>
  await axiosInstance.get(`campaigns/ticketCounts`).then((res) => {
    const transformedData = res?.data?.map((item: any) => ({
      drawId: item[0],
      drawName: item[1],
      ticketCount: item[2],
    }))
    return transformedData;
  });

export const getCampaignsUsageCounts =
  async (): Promise<any> =>
    await axiosInstance
      .get(`/analytics/campaigns/campaignFinishUsageCounts`)
      .then((res) => res.data);