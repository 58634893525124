import { useQuery } from "react-query";
import { getNotificationHistory } from "@/services";

export const useGetNotificationHistoryData = (page: number | null, rowPerPage: number | null) => {
    const { data: notificationHistory, isLoading: getNotificationHistoryIsLoading } = useQuery(
        ["notificationHistory", page, rowPerPage],
        async () => {
            const notificationHistoryData = await getNotificationHistory(page, rowPerPage);
            console.log("notificationHistoryData", notificationHistoryData);
            return notificationHistoryData || [];
        },
    );

    return { notificationHistory, getNotificationHistoryIsLoading };
};

