import { Toast } from "primereact";
import { ICampaign } from "@/models";
import { orderCampaigns } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useOrderCampaignsData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: orderCampaignsIsLoading } = useMutation(
        async (campaigns: Array<Omit<ICampaign, "imageUrl">>) =>
            await orderCampaigns(campaigns),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Order campaign");
            },
            onError: (error: any) => {
                let errorMessage = "Not order campaign";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        orderCampaignsIsLoading,
    };
};
