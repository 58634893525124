import React, { useState } from "react";
import { INotificationMessage } from "@/models";
import { notificationFilters } from "@/constants";
import { Badge, Column, Dialog } from "primereact";
import { useGetSingleNotificationMessagesData } from "@/hooks";
import {
    DataTableDialogFooter,
    DataTableCrud,
    DataTableSearchHeader,
} from "@/components";

interface NotificationMessageDialogProps {
    visible: boolean;
    isLoading: boolean;
    onHide: () => void;
    sendNotification: (notificationMessage: INotificationMessage) => void;
}

const NotificationMessageDialog: React.FC<NotificationMessageDialogProps> = ({
    visible,
    isLoading,
    onHide,
    sendNotification,
}) => {
    const [filters, setFilters] = useState(notificationFilters);
    const [selectedNotificationMessage, setSelectedNotificationMessage] = useState<INotificationMessage | null>(null);

    const { notificationMessages, getNotificationMessagesIsLoading } = useGetSingleNotificationMessagesData();

    const handleSubmit = () => {
        selectedNotificationMessage && (sendNotification(selectedNotificationMessage));
        hideDialog();
    };

    const hideDialog = () => {
        onHide();
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "75%" }}
            header="Send Notification"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={handleSubmit}
                    submitButtonText="Send"
                />
            }
            onHide={hideDialog}
        >
            <DataTableCrud
                value={notificationMessages}
                selection={selectedNotificationMessage}
                onSelectionChange={(e: any) => setSelectedNotificationMessage(e.value)}
                loading={getNotificationMessagesIsLoading}
                title="notification messages"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["msgCode", "title", "text"]}
                emptyMessage="Notification messages not found"
            >
                <Column
                    selectionMode="single"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                ></Column>
                <Column
                    field="msgCode"
                    header="Message Code"
                    sortable
                    style={{ maxWidth: "8rem" }}
                    filter
                    filterPlaceholder="Search by message code"
                    body={(rowData: INotificationMessage) => {
                        return (
                            <Badge value={rowData?.msgCode} className={getBadgeColor(rowData?.msgCode)} />
                        );
                    }}
                ></Column>
                <Column
                    field="title"
                    header="Title"
                    sortable
                    style={{ maxWidth: "10rem" }}
                    filter
                    filterPlaceholder="Search by title"
                ></Column>
                <Column
                    field="text"
                    header="Text"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by text"
                ></Column>
            </DataTableCrud>
        </Dialog>
    );
};

export default NotificationMessageDialog;

function getBadgeColor(msgCode: string | null) {
    switch (msgCode) {
        case "NCS":
            return "p-badge-success";
        case "CMP_1000":
            return "p-badge-info";
        case "CMP_3000":
            return "p-badge-warning";
        case "CMP_5000":
            return "p-badge-danger";
        case "CMP_10000":
            return "p-badge-primary";
        case "CMP_20000":
            return "p-badge-secondary";
        case "APP_OPEN_REMINDER":
            return "p-badge-tertiary";
        case "SINGLE_NOTIFICATION":
            return "p-badge-quaternary";
        case "ORDER_SENT_NOTIFICATION":
            return "p-badge-info";
        default:
            return "p-badge-rose";
    }
}
