import { useQuery } from "react-query";
import { getCustomerOrders } from "@/services";

export const useGetCustomerOrdersData = (userUId: number | null) => {
    const { data: customerOrders, isLoading: getCustomerOrdersIsLoading } = useQuery(
        ["customerOrders", userUId],
        async () => {
            const customerOrdersData = await getCustomerOrders(userUId);
            console.log("customerOrdersData", customerOrdersData);
            return customerOrdersData || [];
        },
    );

    return { customerOrders, getCustomerOrdersIsLoading };
};

export default useGetCustomerOrdersData;
