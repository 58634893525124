import { Button, Dialog, ProgressSpinner, TabPanel, TabView } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";
import { DataTableDialogFooter, ImageUploadField } from "@/components";
import { ICampaign, IProduct } from "@/models";
import { setAsMainImage } from "../../../../services/product-services/productServices";

interface Props {
    visible: boolean;
    fileList: File[];
    multiple?: boolean;
    isLoading?: boolean;
    isLoadingFooter?: boolean;
    data?: IProduct | ICampaign | null;
    setFileList: (fileList: File[]) => void;
    onHide: () => void;
    addImage: () => void;
    deleteImage?: (id: bigint | number) => void;
}

const ImageDialog: React.FC<Props> = ({
    visible,
    onHide,
    addImage,
    fileList,
    setFileList,
    multiple,
    isLoading,
    isLoadingFooter,
    data,
    deleteImage,
}) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = React.useState(0);

    console.log("data", data);

    const closeDialog = () => {
        onHide();
        setFileList([]);
        setActiveIndex(0);
    };

    const handleSubmit = () => {
        !activeIndex && addImage()
        setFileList([]);
    };

    const handleDeleteImage = (id: bigint | number | null) => {
        deleteImage && id && deleteImage(id);
        closeDialog();
    };

    const handleSetMain = (
        productId: bigint | number | null,
        imageId: bigint | number | null,
    ) => {
        setAsMainImage(productId, imageId);
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "60%", minHeight: "550px" }}
            header={t("Image Upload")}
            modal
            maximizable
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoadingFooter}
                    hideDialog={closeDialog}
                    handleSubmit={handleSubmit}
                />
            }
            onHide={closeDialog}
        >
            {!isLoading ? (
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    <TabPanel header="Upload Image">
                        <form onSubmit={handleSubmit}>
                            <ImageUploadField
                                setFileList={setFileList}
                                fileList={fileList}
                                multiple={multiple}
                                dataType={data}
                            />
                        </form>
                    </TabPanel>
                    <TabPanel header="Image List">
                        {data && "images" in data && data?.images.length ? (
                            data?.images?.map((image, index) => (
                                <div
                                    key={index}
                                    className="flex justify-content-between align-items-center mb-5"
                                >
                                    <img src={image.filePath} alt={image.name} />
                                    <div>
                                        {image?.isMainPoster === 0 ? (
                                            <Button
                                                icon="pi pi-check-square"
                                                className="p-button-info p-button-rounded p-button-outlined"
                                                style={{ marginRight: "10px" }}
                                                aria-label="Set as Main Image"
                                                onClick={() => handleSetMain(data?.id, image?.id)}
                                            />
                                        ) : null}

                                        <Button
                                            icon="pi pi-times"
                                            className="p-button-danger p-button-rounded p-button-outlined"
                                            aria-label="Cancel"
                                            onClick={() => handleDeleteImage(image.id)}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : data?.imageUrl ? (
                            <div className="flex justify-content-between align-items-center mb-5">
                                <img src={data.imageUrl} alt={data?.imageUrl} />
                                <Button
                                    icon="pi pi-times"
                                    className="p-button-danger p-button-rounded p-button-outlined"
                                    aria-label="Cancel"
                                    onClick={() => handleDeleteImage(data.id)}
                                />
                            </div>
                        ) : (
                            <div className="text-center">No Image</div>
                        )}
                    </TabPanel>
                </TabView>
            ) : (
                <div
                    className="flex align-items-center"
                    style={{ height: 400, width: "100%" }}
                >
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                </div>
            )}
        </Dialog>
    );
};

export default ImageDialog;
