import { getNotificationMessages } from "@/services";
import { useQuery } from "react-query";

export const useGetNotificationMessagesData = () => {
    const { data: notificationMessages, isLoading: getNotificationMessagesIsLoading } = useQuery(
        "notificationMessages",
        getNotificationMessages,
    );
    return { notificationMessages, getNotificationMessagesIsLoading };
};
