import { Button, classNames, Dialog, ColorPicker } from "primereact";
import React from "react";
import { ErrorField, InputField } from "@/components";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { useFormik } from "formik";
import { IOrder } from "@/models";
import { digitalOrderSchema } from "@/validations";

interface Props {
    visible: boolean;
    order: IOrder | undefined;
    onHide: () => void;
    onSave: (data: {
        orderId: bigint;
        expiryDate: string;
        digitalCode: string;
    }) => void;
}

const OrderDigitalProductDialog: React.FC<Props> = ({
    visible,
    onHide,
    order,
    onSave,
}) => {
    const formik = useFormik({
        initialValues: {
            orderId: order ? order.id : BigInt(0),
            expiryDate: order ? order.expiryDate : "",
            digitalCode: order ? order.digitalCode : "",
        },
        enableReinitialize: true,
        validationSchema: digitalOrderSchema,
        onSubmit: (data) => {
            formik.setFieldValue("orderId", order?.id);
            data.expiryDate = data.expiryDate + " 13:00:00";
            console.log("formik daqta:", data);
            order && onSave(data);
            formik.resetForm();
            onHide();
        },
    });

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header="Digital Product Details"
            modal
            className="p-fluid"
            onHide={onHide}
            footer={
                <div>
                    <Button
                        label="Cancel"
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={onHide}
                    />
                    <Button
                        label="Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <InputField
                    id="expiryDate"
                    value={formik.values.expiryDate ?? ""}
                    type="date"
                    required
                    autoFocus={true}
                    onChange={formik.handleChange}
                    labelName="expiryDate"
                    labelText={capitalizeFirstLetter("expiry Date")}
                    getFormErrorMessage={() => (
                        <ErrorField formik={formik} name="expiryDate" />
                    )}
                    className={classNames({
                        "p-invalid": isFormFieldValid(formik, "expiryDate"),
                    })}
                />

                <InputField
                    id="digitalCode"
                    value={formik.values.digitalCode ?? ""}
                    type="text"
                    required
                    onChange={formik.handleChange}
                    labelName="digitalCode"
                    labelText={capitalizeFirstLetter("digital Code")}
                    getFormErrorMessage={() => (
                        <ErrorField formik={formik} name="digitalCode" />
                    )}
                    className={classNames({
                        "p-invalid": isFormFieldValid(formik, "digitalCode"),
                    })}
                />
            </form>
        </Dialog>
    );
};

export default OrderDigitalProductDialog;
