import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog, TabMenu } from "primereact";
import React, { useEffect, useState } from "react";
import {
    InputField,
    ErrorField,
    DropdownField,
    InputNumberField,
    DataTableDialogFooter,
} from "@/components";
import { isFormFieldValid } from "@/utils";
import { addCkpSchema } from "@/validations";
import { INotificationMessage } from "@/models";
import { initialNotificationMessage } from "@/constants";
import { useGetSingleNotificationMessagesData } from "@/hooks";

interface CustomerAddCkpDialogProps {
    visible: boolean;
    isLoading: boolean;
    onHide: () => void;
    saveNotificationMessage: (notificationMessage: INotificationMessage) => void;
}

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "Second Language", icon: "pi pi-fw pi-language" },
];

const CustomerAddCkpDialog: React.FC<CustomerAddCkpDialogProps> = ({
    visible,
    isLoading,
    onHide,
    saveNotificationMessage,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedNotification, setSelectedNotification] = useState(initialNotificationMessage);

    const { notificationMessages, getNotificationMessagesIsLoading } = useGetSingleNotificationMessagesData();

    const notificationOptions = notificationMessages?.map((notification: INotificationMessage) => ({
        label: notification.title,
        value: notification,
    }));

    const formik = useFormik({
        initialValues: initialNotificationMessage,
        enableReinitialize: true,
        validationSchema: addCkpSchema,
        onSubmit: (data) => {
            saveNotificationMessage(data);
        },
    });

    useEffect(() => {
        if (selectedNotification) {
            formik.setValues({
                ...formik.values,
                title: selectedNotification.title ?? "",
                text: selectedNotification.text ?? "",
                textScnd: selectedNotification.textScnd ?? "",
                titleScnd: selectedNotification.titleScnd ?? "",
            });
        }
    }, [selectedNotification]);

    const hideDialog = () => {
        onHide();
        formik.resetForm();
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header="Add CKP"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                    submitButtonText="Add"
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <InputNumberField
                            id="amount"
                            inputId="amount"
                            showButtons
                            mode="currency"
                            currency="CKP"
                            value={formik.values.amount ?? 0}
                            required
                            onValueChange={formik.handleChange}
                            labelName="amount"
                            labelText="CKP Amount"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="amount" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "amount"),
                            })}
                        />
                        <DropdownField
                            id="notification"
                            value={selectedNotification}
                            type="text"
                            required
                            options={notificationOptions}
                            onChange={(e) => setSelectedNotification(e.value)}
                            labelName="notification"
                            labelText="Notification"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"notification"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "notification"),
                            })}
                        />
                        <InputField
                            id="title"
                            value={formik.values.title ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="title"
                            labelText="Notification Title"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="title" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "title"),
                            })}
                        />
                        <InputField
                            id="text"
                            value={formik.values.text ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="text"
                            labelText="Notification Message"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="text" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "text"),
                            })}
                        />
                    </>
                ) : (
                    <>

                        <InputField
                            id="titleScnd"
                            value={formik.values.titleScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="titleScnd"
                            labelText="Notification Title"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="titleScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "titleScnd"),
                            })}
                        />
                        <InputField
                            id="textScnd"
                            value={formik.values.textScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="textScnd"
                            labelText="Notification Message"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="textScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "textScnd"),
                            })}
                        />
                    </>
                )}
            </form>
        </Dialog>
    );
};

export default CustomerAddCkpDialog;