import { ICampaign, IMbi, IProduct } from "@/models";
import { useAddCampaignImagesData } from "@/hooks";
import { SingleImageUploadDialog } from "@/components";
import React, { useEffect, useRef, useState } from "react";
import { Button, Column, DataTable, Dialog, Toast } from "primereact";

interface CampaignImagesDialogProps {
    visible: boolean;
    onHide: () => void;
    campaign: ICampaign | null;
}

const CampaignImagesDialog: React.FC<CampaignImagesDialogProps> = ({
    visible,
    onHide,
    campaign,
}) => {
    const toast = useRef<Toast>(null);

    const formData = new FormData();

    const [imageType, setImageType] = useState<number | null>(null);
    const [imageDialog, setImageDialog] = useState(false);

    const { mutate: addCampaignImagesMutate,
        addCampaignImagesIsLoading,
        addCampaignImagesIsSuccess
    } = useAddCampaignImagesData(toast);

    useEffect(() => {
        if (addCampaignImagesIsSuccess) {
            hideImageDialog();
        }
    }, [addCampaignImagesIsSuccess]);

    const openImageDialog = (imageType: number | null) => {
        setImageType(imageType);
        setImageDialog(true);
    };

    const hideImageDialog = () => {
        setImageDialog(false);
    };

    const saveImage = (rowData: IMbi | ICampaign | IProduct | null, file: FileList) => {
        formData.append("campaignId", rowData?.id?.toString() ?? "");
        formData.append("file", file[0]);
        addCampaignImagesMutate({ campaignImages: formData, imageType: imageType });
    };


    const renderDialogContent = () => {

        return (
            <DataTable
                value={[{}]}
            >
                <Column
                    header="Campaign Image"
                    body={() => (
                        <>
                            <Button
                                icon="pi pi-images"
                                className="p-button p-button-primary m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={() => openImageDialog(0)}
                            />
                            <div
                                className={`pi pi-check-circle`}
                                style={{
                                    fontSize: '1.5rem', marginLeft: '1rem',
                                    color: campaign?.imageUrl ? 'green' : 'grey',
                                }}
                            />
                        </>
                    )}
                />
                <Column
                    header="Infograph Image"
                    body={() => (
                        <>
                            <Button
                                icon="pi pi-images"
                                className="p-button p-button-success m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={() => openImageDialog(1)}
                            />
                            <div
                                className={`pi pi-check-circle`}
                                style={{
                                    fontSize: '1.5rem', marginLeft: '1rem',
                                    color: campaign?.targetUrl ? 'green' : 'grey',
                                }}
                            />
                        </>
                    )}
                />
                <Column
                    header="Second Lang Infograph Image"
                    body={() => (
                        <>
                            <Button
                                icon="pi pi-images"
                                className="p-button p-button-warning m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={() => openImageDialog(2)}
                            />
                            <div
                                className={`pi pi-check-circle`}
                                style={{
                                    fontSize: '1.5rem', marginLeft: '1rem',
                                    color: campaign?.targetUrlScnd ? 'green' : 'grey',
                                }}
                            />
                        </>
                    )}
                />
            </DataTable>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                footer={
                    <div>
                        <Button label="OK" icon="pi pi-check" onClick={onHide} />
                    </div>
                }
                header="Campaign Images"
                modal
                className="p-fluid"
                onHide={onHide}
            >
                {renderDialogContent()}
            </Dialog>

            <SingleImageUploadDialog
                rowData={campaign}
                visible={imageDialog}
                onHide={hideImageDialog}
                saveImage={saveImage}
                imageType={imageType}
                isLoading={addCampaignImagesIsLoading}
            />
        </>
    );
};

export default CampaignImagesDialog;
