import { InputText } from "primereact";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface DataTableSearchHeaderProps {
 title: string;
 filters?: any;
 onChange?: (filters: any) => void;
 customTitleStyles?: React.CSSProperties;
 inputStyles?: React.CSSProperties;
}

const DataTableSearchHeader: React.FC<DataTableSearchHeaderProps> = ({
 title,
 filters,
 onChange,
 customTitleStyles,
 inputStyles,
}) => {
 const [globalFilterValue, setGlobalFilterValue] = useState("");
 const { t } = useTranslation();

 const onGlobalFilterChange = (e: any) => {
  const value = e.target.value;
  const _filters = { ...filters };
  _filters.global.value = value;

  onChange?.(_filters);
  setGlobalFilterValue(value);
 };

 return (
  <div className="table-header">
   <h5
    className="mx-0 my-1"
    style={{ ...customTitleStyles, minWidth: "200px" }}
   >
    {title}
   </h5>
   {filters && (
    <span className="p-input-icon-left">
     <i className="pi pi-search" />
     <InputText
      type="search"
      value={globalFilterValue}
      placeholder={t("Search...")}
      onInput={(e: any) => onGlobalFilterChange(e)}
      className="p-inputtext-sm"
      style={inputStyles}
     />
    </span>
   )}
  </div>
 );
};

export default DataTableSearchHeader;
