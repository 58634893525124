import { IPopup } from "@/models";
import { axiosInstance } from "@/api/axios.instance";

export const getPopupHistory = async (page: number | null, rowPerPage: number | null): Promise<IPopup[]> => {

    const response = await axiosInstance.get("/customer/popup/getPopUpLogs", {
        params: { page: page, limit: rowPerPage }
    });
    const responseData = response.data;

    responseData.content = responseData.content.map((item: any) => ({
        ...item,
        totalElements: responseData.totalElements,
    }));
    const popupLogs: IPopup[] = responseData.content;
    return popupLogs;
}

export const sendPopup = async (popup: IPopup) =>
    await axiosInstance.post(`/customer/popup/sendPopUp`, popup);
