import moment from "moment";
import { useState, useRef, useEffect } from "react";
import {
    EcpmDialog,
    DataTableCrud,
    DataTableToolbar,
    DataTableDeleteDialog,
    DataTableSearchHeader,
    DataTableActionTemplate,
} from "@/components";
import { IEcpm } from "@/models";
import { Column, Toast } from "primereact";
import { ROLE, initialEcpm } from "@/constants";
import {
    useRole,
    useAddEcpmData,
    useGetEcpmsData,
    useDeleteEcpmData,
    useUpdateEcpmData,
} from "@/hooks";

const EcpmDataTable = () => {
    const { role } = useRole();
    const [ecpm, setEcpm] = useState<IEcpm>(initialEcpm);
    const [deleteEcpmDialog, setDeleteEcpmDialog] = useState(false);
    const [isAddItem, setIsAddItem] = useState(false);
    const [selectedEcpm, setSelectedEcpm] = useState<any>(null);
    const [ecpmDialog, setEcpmDialog] = useState(false);

    const toast = useRef<Toast>(null);

    const {
        mutate: addEcpmMutate,
        addEcpmIsLoading,
        addEcpmIsSuccess,
    } = useAddEcpmData(toast);
    const {
        mutate: updateEcpmMutate,
        updateEcpmIsLoading,
        updateEcpmIsSuccess,
    } = useUpdateEcpmData(toast);
    const { mutate: deleteEcpmMutate } = useDeleteEcpmData(toast);

    const { ecpms, getEcpmsIsLoading } = useGetEcpmsData();

    useEffect(() => {
        if (addEcpmIsSuccess || updateEcpmIsSuccess) {
            hideEcpmDialog();
        }
    }, [addEcpmIsSuccess, updateEcpmIsSuccess]);

    const hideEcpmDialog = () => {
        setEcpmDialog(false);
    };

    const hideDeleteEcpmDialog = () => {
        setDeleteEcpmDialog(false);
    };

    const saveEcpm = (newEcpm: IEcpm) => {
        isAddItem
            ? addEcpmMutate(newEcpm)
            : updateEcpmMutate(newEcpm);
    };

    const openDeleteEcpmDialog = (ecpm?: IEcpm) => {
        console.log(ecpm);

        ecpm && setEcpm(ecpm);
        setDeleteEcpmDialog(true);
    };

    const openAddEcpmDialog = () => {
        setEcpm(initialEcpm);
        setIsAddItem(true);
        setEcpmDialog(true);
    };

    const openEditEcpmDialog = (rowData: IEcpm) => {
        setEcpm({ ...rowData });
        setIsAddItem(false);
        setEcpmDialog(true);
    };

    const deleteEcpm = () => {
        ecpm.id && deleteEcpmMutate(ecpm.id);
        hideDeleteEcpmDialog();
    };

    return (
        <>
            <Toast ref={toast} />
            {role === ROLE.ADMIN && (<DataTableToolbar
                openAddDialog={openAddEcpmDialog}
            />)}

            <DataTableCrud
                value={ecpms}
                title="ecpms"
                selection={selectedEcpm}
                filters={null}
                loading={getEcpmsIsLoading}
                onSelectionChange={(e: any) => setSelectedEcpm(e.value)}
                header={
                    <DataTableSearchHeader
                        title="Manage eCPM's"
                        filters={null}
                        onChange={(e: any) => { }}
                    />
                }
            >
                <Column
                    field="date"
                    header={"Date"}
                    sortable
                    style={{ minWidth: "4rem" }}
                    body={(rowData) => <>{moment(rowData.date).format("DD-MM-YYYY")}</>}
                ></Column>
                <Column
                    field="ecpm"
                    header={"eCPM"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                {role === ROLE.ADMIN && (<Column
                    body={(rowData: IEcpm) => (
                        <DataTableActionTemplate
                            openDeleteDialog={() => openDeleteEcpmDialog(rowData)}
                            openEditDialog={() => openEditEcpmDialog(rowData)}
                        />
                    )}
                    exportable={false}
                    style={{ minWidth: "4rem" }}
                ></Column>)}
            </DataTableCrud>

            <DataTableDeleteDialog
                visible={deleteEcpmDialog}
                data={selectedEcpm ?? ecpm}
                onHide={hideDeleteEcpmDialog}
                onDelete={deleteEcpm}
            />

            <EcpmDialog
                visible={ecpmDialog}
                ecpm={ecpm}
                isLoading={isAddItem ? addEcpmIsLoading : updateEcpmIsLoading}
                onHide={hideEcpmDialog}
                addEcpm={saveEcpm}
            />
        </>
    );
};

export default EcpmDataTable;
