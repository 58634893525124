import { Toast } from "primereact";
import { ISupplier } from "@/models";
import { addSupplier } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddSupplierData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addSupplierIsLoading,
        isSuccess: addSupplierIsSuccess,
    } = useMutation(async (supplier: ISupplier) => await addSupplier(supplier), {
        onSuccess: () => {
            queryClient.invalidateQueries("suppliers");
            toastSuccess(toast, "Supplier added");
        },
        onError: (error: any) => {
            let errorMessage = "Supplier not added";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        addSupplierIsLoading,
        addSupplierIsSuccess,
    };
};
