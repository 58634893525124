import { IFieldProps } from "@/models";
import { InputNumber, InputNumberProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface InputNumberFieldProps extends InputNumberProps, IFieldProps { }

const InputNumberField: React.FC<InputNumberFieldProps> = ({
    getFormErrorMessage,
    labelName,
    labelText,
    visible = true,
    ...rest
}) => {
    const { t } = useTranslation();

    if (visible) {
        return (
            <div className="field">
                <label htmlFor={labelName}>{t(labelText)}</label>
                <InputNumber {...rest} />
                {getFormErrorMessage != null && getFormErrorMessage()}
            </div>
        );
    } else {
        return null;
    }
};

export default InputNumberField;
