import { Column, Toast, Image, Button, Tooltip, Dropdown } from "primereact";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  MbiDialog,
  MbiSortDialog,
  DataTableCrud,
  DataTableToolbar,
  DataTableSearchHeader,
  DataTableUpdateDialog,
  DataTableDeleteDialog,
  DataTableActionTemplate,
  SingleImageUploadDialog,
} from "@/components";
import { ICampaign, IMbi, IProduct } from "@/models";
import { initialMbi, mbiFilters, STATUS, statusTypes } from "@/constants";
import {
  useAddMbiData,
  useDeleteMbiData,
  useGetMbisData,
  useOrderMbisData,
  useUpdateMbiData,
  useUpdateMbiStatusData,
} from "@/hooks";
import { getProductDetail } from "@/services";
import { useTranslation } from "react-i18next";
import NoImage from "@assets/images/no-image.png";
import { useAddMbiImagesData } from "@/hooks/mbi/useAddMbiImageData";

const MbiDataTable = () => {
  const [selectedMbi, setSelectedMbi] = useState<IMbi | null>(null);
  const [filters, setFilters] = useState(mbiFilters);
  const [mbi, setMbi] = useState<Omit<IMbi, "imageUrl">>(initialMbi);
  const [deleteMbiDialog, setDeleteMbiDialog] = useState(false);
  const [updateMbiStatusDialog, setUpdateMbiStatusDialog] = useState(false);
  const [mbiDialog, setMbiDialog] = useState(false);
  const [mbiSortDialog, setMbiSortDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const toast = useRef<Toast>(null);
  const formData = new FormData();
  const [imageDialog, setImageDialog] = useState(false);
  const { mutate: addMbiImage, addProductImagesIsLoading: isLoadingImage } =
    useAddMbiImagesData(toast, setImageDialog);

  const {
    mutate: addMbiMutate,
    addMbiIsLoading,
    addMbiIsSuccess,
  } = useAddMbiData(toast);
  //  const { mutate: addProductImages } = useAddProductImagesData(toast);
  const {
    mutate: updateMbiMutate,
    updateMbiIsLoading,
    updateMbiIsSuccess,
  } = useUpdateMbiData(toast);
  const { mutate: updateMbiStatusMutate } = useUpdateMbiStatusData(toast);
  const { mutate: deleteMbiMutate } = useDeleteMbiData(toast);
  const { mbis, getMbisIsLoading } = useGetMbisData();
  const { mutate: orderMbiMutate } = useOrderMbisData(toast);

  const { t } = useTranslation();

  useEffect(() => {
    if (addMbiIsSuccess || updateMbiIsSuccess) {
      setMbiDialog(false);
    }
  }, [addMbiIsSuccess, updateMbiIsSuccess]);

  const openDeleteMbiDialog = (Mbi?: IMbi) => {
    Mbi && setMbi(Mbi);
    setDeleteMbiDialog(true);
  };

  const openUpdateMbiStatusDialog = (mbi?: IMbi) => {
    mbi && setMbi(mbi);
    setUpdateMbiStatusDialog(true);
  };

  const openAddMbiDialog = () => {
    setMbi(initialMbi);
    setIsAddItem(true);
    setMbiDialog(true);
  };

  const openEditMbiDialog = (rowData: IMbi) => {
    const { imageUrl, ...rest } = rowData;

    if (rowData.type === "PRD") {
      getProductDetail(rowData.targetId).then((productData) => {
        rest.product = productData;
        setMbi({ ...rest });
        setIsAddItem(false);
        setMbiDialog(true);
      });
    } else {
      setMbi({ ...rest });
      setIsAddItem(false);
      setMbiDialog(true);
    }
  };

  const openSortMbiDialog = () => {
    setMbiSortDialog(true);
  };

  const hideMbiDialog = () => {
    setMbiDialog(false);
  };
  const hideDeleteMbiDialog = () => {
    setDeleteMbiDialog(false);
  };

  const hideUpdateMbiStatusDialog = () => {
    setUpdateMbiStatusDialog(false);
  };

  const saveMbi = (mbi: Omit<IMbi, "imageUrl">) => {
    isAddItem ? addMbiMutate(mbi) : updateMbiMutate(mbi);
  };

  const saveImage = (rowData: IMbi | ICampaign | IProduct | null, file: FileList) => {
    formData.append("mbiId", rowData?.id?.toString() ?? "");
    formData.append("file", file[0]);
    addMbiImage(formData);
  };

  const deleteMbi = () => {
    mbi.id && deleteMbiMutate(mbi.id);
    hideDeleteMbiDialog();
  };

  const updateMbiStatus = () => {
    mbi.id && updateMbiStatusMutate({
      mbiId: mbi.id,
      status: mbi.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
    });
    hideUpdateMbiStatusDialog();
  };

  const hideImageDialog = () => {
    setImageDialog(false);
  };

  const imageBodyTemplate = (rowData: IMbi) => {
    return (
      <Image
        src={`${rowData.imageUrl}`}
        alt="mbi"
        height="80"
        width="80"
        preview
        className="mbi-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  const openImageMbiDialog = async (rowData: IMbi) => {
    setMbi(rowData);
    setImageDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTableToolbar
        disableDeleteButton={!selectedMbi}
        openAddDialog={openAddMbiDialog}
        openDeleteDialog={openDeleteMbiDialog}
      >
        <Button
          id="p-sort-button"
          label="Sort"
          icon="pi pi-sort"
          className="p-button-primary mr-2"
          onClick={openSortMbiDialog}
        />
      </DataTableToolbar>

      <DataTableCrud
        value={mbis}
        selection={selectedMbi}
        onSelectionChange={(e) => setSelectedMbi(e.value)}
        header={
          <DataTableSearchHeader
            title={t("Manage Main Board Items")}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={getMbisIsLoading}
        title="Main Board Items"
      >
        <Column
          field="title"
          header={t("Title")}
          sortable
        />
        <Column field="image" header="Image" body={imageBodyTemplate} />
        <Column
          field="description"
          header={t("Description")}
          sortable
        />
        <Column
          field="type"
          header={t("Type")}
          sortable
        />
        <Column
          field="status"
          header="Status"
          sortable
          body={(rowData: IMbi) => (
            <Dropdown
              value={rowData.status}
              options={statusTypes}
              onChange={(e) => {
                e.target.value && openUpdateMbiStatusDialog(rowData);
              }}
              itemTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              valueTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              style={{ minWidth: "10rem" }}
            />
          )}
        />
        <Column
          body={(rowData: IMbi) => (
            <>
              <DataTableActionTemplate
                openDeleteDialog={() => openDeleteMbiDialog(rowData)}
                openEditDialog={() => openEditMbiDialog(rowData)}
              />
              <Tooltip
                target="#p-upload-image-button"
                position="bottom"
                content="Image Upload"
              />
              <Button
                id="p-upload-image-button"
                icon="pi pi-upload"
                className="p-button-rounded m-1"
                onClick={async () => {
                  setSelectedMbi(rowData);
                  openImageMbiDialog(rowData);
                }}
              />
            </>
          )}
          exportable={false}
          style={{ minWidth: "13rem" }}
        />
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteMbiDialog}
        data={selectedMbi ?? mbi}
        onHide={hideDeleteMbiDialog}
        onDelete={deleteMbi}
      />

      <DataTableUpdateDialog
        visible={updateMbiStatusDialog}
        data={selectedMbi ?? mbi}
        onHide={hideUpdateMbiStatusDialog}
        onUpdate={updateMbiStatus}
        text={`Are you sure you want to change status to ${mbi.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE}?`}
      />

      <MbiDialog
        visible={mbiDialog}
        mbi={mbi}
        isLoading={isAddItem ? addMbiIsLoading : updateMbiIsLoading}
        onHide={hideMbiDialog}
        addMbi={saveMbi}
        isAddItem={isAddItem}
      />

      <MbiSortDialog
        visible={mbiSortDialog}
        onHide={() => setMbiSortDialog(false)}
        isLoading={updateMbiIsLoading}
        orderMbis={orderMbiMutate}
        mbis={
          mbis
            ?.filter((campaign) => campaign.status === STATUS.ACTIVE)
            .sort((a, b) => a.sequence - b.sequence) ?? []
        }
      />

      <SingleImageUploadDialog
        rowData={selectedMbi}
        visible={imageDialog}
        onHide={hideImageDialog}
        saveImage={saveImage}
        isLoading={isLoadingImage}
      />
    </>
  );
};

export default MbiDataTable;
