import { DataTable, DataTableProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableCrudProps extends DataTableProps {
    value: any;
    title: string;
    selection?: any;
    loading: boolean;
    header?: React.ReactNode;
    filters?: any;
    paginator?: boolean
    onSelectionChange?: (e: any) => void;
}

const DataTableCrud: React.FC<DataTableCrudProps> = ({
    children,
    title,
    paginator,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <DataTable
            {...rest}
            dataKey="id"
            paginator={paginator ?? true}
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t(
                `Showing {first} to {last} of {totalRecords} ${title}`,
            )}
            responsiveLayout="scroll"
        >
            {children}
        </DataTable>
    );
};

export default DataTableCrud;
