import { Toast } from "primereact";
import { ICampaign } from "@/models";
import { addCampaign } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddCampaignData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCampaignIsLoading,
        isSuccess: addCampaignIsSuccess,
    } = useMutation(
        async (campaign: Omit<ICampaign, "imageUrl">) => await addCampaign(campaign),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Campaign added");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        addCampaignIsLoading,
        addCampaignIsSuccess,
    };
};
