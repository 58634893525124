import React, { useEffect } from "react";
import ProductCard from "./product_card";
import ProductSplit from "./product_split";
import { IProduct } from "@/models";

interface ProductListProps {
    products: IProduct[];
    zoomOutFactor: number;
    onGroupedProductsChange: (groupedProducts: any[]) => void;
}

const ProductList: React.FC<ProductListProps> = ({ products, zoomOutFactor, onGroupedProductsChange }) => {

    const groupProductsByImageSizeType = (productList: IProduct[]) => {
        const groups = [];
        let currentGroup = [];
        let pendingImageSizeType3Product = null;

        for (const product of productList) {
            if (product.imageSizeType === 1 || product.imageSizeType === 2) {
                if (currentGroup.length > 0) {
                    groups.push({ type: 0, products: [...currentGroup] });
                    currentGroup = [];
                }
                groups.push({ type: 1, products: [product] });
            } else if (product.imageSizeType === 3) {
                pendingImageSizeType3Product = product;
            } else {
                if (pendingImageSizeType3Product) {
                    currentGroup.push(pendingImageSizeType3Product);
                }
                currentGroup.push(product);
                if (pendingImageSizeType3Product) {
                    currentGroup.push(null);
                    pendingImageSizeType3Product = null;
                }
            }
        }

        if (currentGroup.length > 0) {
            groups.push({ type: 0, products: [...currentGroup] });
        }

        return groups;
    };

    const groupedProducts = groupProductsByImageSizeType(products);

    const groupedProductsWithoutNull = [...groupedProducts.map((group) => ({
        ...group,
        products: group.products.filter((product) => product !== null),
    }))]

    useEffect(() => {
        if (products && products.length > 0) {
            const allGroupedProducts = groupedProductsWithoutNull.reduce((acc: any, group: any) => {
                return [...acc, ...group.products];
            }, []);

            const matchingProducts = products.filter((sortingProduct) => {
                return allGroupedProducts.some((groupedProduct: IProduct) => groupedProduct.id === sortingProduct.id);
            });

            const missingProducts = products.filter((sortingProduct) => {
                return !matchingProducts.some((matchingProduct) => matchingProduct.id === sortingProduct.id);
            });

            const missingProductIds = missingProducts.map((missingProduct) => missingProduct.id);

            onGroupedProductsChange(missingProductIds);
        }
    }, [products]);

    return (
        <div style={{ transform: `scale(${zoomOutFactor})`, transformOrigin: 'top center' }}>
            {groupedProducts.map((group, index) => (
                <div key={index}>
                    {group.type === 1 ? (
                        <div className="flexContainer">
                            {group.products.map((product) => (
                                product && (
                                    <ProductCard
                                        key={product.id}
                                        product={product}
                                    />
                                )
                            ))}
                        </div>
                    ) : (
                        <ProductSplit
                            leftContent={(
                                <div className="flexContainer">
                                    {group.products.filter((_, i) => i % 2 === 0).map((product) => (
                                        product && (
                                            <ProductCard
                                                key={product.id}
                                                product={product}
                                            />
                                        )
                                    ))}
                                </div>
                            )}
                            rightContent={(
                                <div className="flexContainer">
                                    {group.products.filter((_, i) => i % 2 !== 0).map((product) => (
                                        product && (
                                            <ProductCard
                                                key={product.id}
                                                product={product}
                                            />
                                        )
                                    ))}
                                </div>
                            )}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default ProductList;
