import { getDrawData } from "@/services";
import { useQuery } from "react-query";

export const useGetDrawData = (campaignId: bigint | null) => {
 const {
  data: draw,
  isLoading: getDrawDataIsLoading,
  refetch: refetchGetCampaigns,
 } = useQuery(
  ["campaigns", campaignId],
  async () => await getDrawData(campaignId),
 );
 return { draw, getDrawDataIsLoading, refetchGetCampaigns };
};
