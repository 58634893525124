import { useState, useEffect } from "react";
import { useGetCustomerCkpHistoryData } from "@/hooks";
import { ProgressSpinner, DataTable, Column, Message } from "primereact";

interface CustomerCkpHistoryListProps {
    customerId: number | null;
}

interface CkpHistoryItem {
    source: string;
    points: number;
}

const sourceMapping: { [key: string]: string } = {
    DW: "DAILY WATCH",
    SC_1000: "1000 STEPS",
    SC_3000: "3000 STEPS",
    SC_5000: "5000 STEPS",
    SC_10000: "10000 STEPS",
    SC_20000: "20000 STEPS",
    DRT: "DIRECT",
    "WC_20.0": "WELCOME 20",
    "WC_40.0": "WELCOME 40",
    "WC_60.0": "WELCOME 60",
    RC: "REGULAR",
    INVITE_BONUS: "INVITE BONUS",
    INVITED_BONUS: "INVITED BONUS",
    MANUAL_ADD: "MANUALLY ADDED POINTS VIA ADMINPANEL",
    CONVERT_STEP: "CONVERT STEP",
    PROMOTION_CODE: "POINTS EARNED FROM INFLUENCER",
    BOX: "GIFTBOX",
    GAME_GEM_CONVERT: "POINTS EARNED FROM GEM EXCHANGE (GAMES)",
    ANNIVERSARY: "ANNIVERSARY",
};

const CustomerCkpHistoryList: React.FC<CustomerCkpHistoryListProps> = ({
    customerId,
}) => {
    const [tableData, setTableData] = useState<CkpHistoryItem[]>([]);
    const {
        customerCkpHistory,
        getCustomerCkpHistoryIsLoading,
    } = useGetCustomerCkpHistoryData(customerId);

    useEffect(() => {
        if (customerCkpHistory) {
            // 0'dan büyük değerleri filtreleyerek tablo verilerini hazırlama
            const filteredData = customerCkpHistory
                .filter((item: any) => item.money > 0)
                .map((item: any) => ({
                    source: sourceMapping[item.source] || item.source, // Kaynağı anlamlı isimle değiştiriyoruz
                    points: item.money,
                }));

            // Verileri büyükten küçüğe sıralama
            const sortedData = filteredData.sort((a: CkpHistoryItem, b: CkpHistoryItem) => b.points - a.points);

            setTableData(sortedData); // Sadece sıralı verileri set ediyoruz
        }
    }, [customerCkpHistory]);

    const totalPoints = tableData.reduce((acc, item) => acc + item.points, 0); // Toplam puanı hesaplıyoruz

    return (
        <div className="card mb-0">
            {getCustomerCkpHistoryIsLoading ? (
                <div style={{ position: 'relative', top: '50%', left: '50%' }}>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
                </div>
            ) : (
                <div>
                    {/* Toplam Puanı gösteren mesaj */}
                    <div style={{ marginBottom: '10px' }}>
                        <Message text={"Total earned SMP : " + totalPoints} />
                    </div>
                    <div style={{ overflowX: 'auto' }}>
                        <DataTable value={tableData} responsiveLayout="scroll">
                            <Column
                                field="source"
                                header="Source"
                                body={(rowData) => (
                                    <span style={{ fontWeight: 'normal' }}>
                                        {rowData.source}
                                    </span>
                                )}
                            />
                            <Column
                                field="points"
                                header="Earned Points"
                                body={(rowData) => (
                                    <span style={{ fontWeight: 'normal' }}>
                                        {rowData.points}
                                    </span>
                                )}
                            />
                        </DataTable>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomerCkpHistoryList;