import { useQuery } from "react-query";
import { getLastWeekInviteInfo } from "@/services";

export const useGetLastWeekInviteInfoData = () => {
    const { data: lastWeekInviteInfo, isLoading: getlastWeekInviteInfoIsLoading } = useQuery(
        "lastWeekInviteInfo",
        getLastWeekInviteInfo,
    );
    return { lastWeekInviteInfo, getlastWeekInviteInfoIsLoading };
};
