import React from "react";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Column, DataTable } from "primereact";
import { useGetDailyDrawTicketCountData } from "@/hooks";

interface Props {
    visible: boolean;
    onHide: () => void;
    rowData: any;
}

const DrawTicketCountDialog: React.FC<Props> = ({ visible, onHide, rowData }) => {
    const { dailyDrawTicketCount, getDailyDrawTicketCountIsLoading } = useGetDailyDrawTicketCountData(
        rowData?.drawId ?? rowData?.id ?? "",
    );

    const renderDialogContent = () => {
        if (getDailyDrawTicketCountIsLoading) {
            return <div>Ticket Counts are Loading...</div>;
        }
        if (dailyDrawTicketCount) {
            return <DataTable
                value={dailyDrawTicketCount}
                loading={getDailyDrawTicketCountIsLoading}
                responsiveLayout="scroll"
                paginator
                rows={25}
                rowsPerPageOptions={[25, 50, 100]}
            >
                <Column
                    field="date"
                    header="Date"
                    sortable
                    body={(rowData) => <>{moment(rowData.date).format("DD-MM-YYYY")}</>}
                />
                <Column
                    field="ticketCount"
                    header="Ticket Count"
                    sortable
                />
            </DataTable>
        }
        return (
            <div>
                <strong>Ticket counts are not available!</strong>
            </div>
        );
    };

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header={rowData?.drawName ?? rowData?.name ?? 'Draw Info'}
            modal
            style={{ width: "40rem" }}
        >
            {renderDialogContent()}
        </Dialog>
    );
};

export default DrawTicketCountDialog;
