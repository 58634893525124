import * as Yup from "yup";

export const mbiSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Title is required"),
  description: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(80, "You can enter up to 80 characters!")
    .required("Description is required"),
  type: Yup.string().nullable()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Type is required"),
});
