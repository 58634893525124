import { Column } from "primereact";
import React from "react";
import { DataTableCrud } from "@/components";
import IAdsLog from "@/models/Entities/ads/IAdsLog";
import moment from "moment";
import { useGetCustomerOrdersData } from "@/hooks";
import { IOrder } from "@/models";

interface Props {
    customerId: number | null;
}

const CustomerOrdersLogDataTable: React.FC<Props> = ({ customerId }) => {

    const { customerOrders, getCustomerOrdersIsLoading } =
        useGetCustomerOrdersData(customerId);

    return (
        <>
            <DataTableCrud
                value={customerOrders}
                loading={getCustomerOrdersIsLoading}
                title="customer order records"
            >
                <Column
                    field="id"
                    header="Id"
                    sortable
                    style={{ minWidth: "5rem" }}
                ></Column>
                <Column
                    field="productName"
                    header="Product Name"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="date"
                    header="Date"
                    sortable
                    style={{ minWidth: "12rem" }}
                    body={(rowData: IAdsLog) => (
                        <>{moment.utc(rowData.date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                ></Column>
                <Column
                    field="type"
                    header="Type"
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="price"
                    header="Price"
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="addressInfo"
                    header="Address"
                    sortable
                    style={{ minWidth: "8rem" }}
                ></Column>
                <Column
                    field="status"
                    header="Status"
                    sortable
                    style={{ minWidth: "8rem" }}
                    body={(rowData: IOrder) => (
                        <span className={`badge status-${rowData.status ? rowData.status.toLowerCase() : ""}`}>
                            {rowData.status}
                        </span>
                    )}
                />
            </DataTableCrud>
        </>
    );
};

export default CustomerOrdersLogDataTable;
