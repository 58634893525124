import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";
import { AppWrapper } from "./layouts";
import { AppConfig } from "./components";
import { Route, Routes } from "react-router-dom";
import {
    Mbi,
    Users,
    Ecpms,
    Login,
    Orders,
    Products,
    NotFound,
    Customers,
    Reporting,
    Suppliers,
    Campaigns,
    Categories,
    PopupHistory,
    DrawProducts,
    MainDashboard,
    InActiveOrders,
    OperatorDashboard,
    NotificationHistory,
    NotificationMessages,
} from "./pages";
import { PrivateRoute } from "@/routes/PrivateRouter";
import { SpecialRoute } from "@/routes/SpecialRouter";
import { useRole } from "./hooks";
import { ROLE } from "./constants";

function App() {
    const { role } = useRole();
    return (
        <AppWrapper>
            <AppConfig />

            <Routes>
                <Route path="/login" element={<SpecialRoute />}>
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route path="/" element={<PrivateRoute />}>
                    {role === ROLE.ADMIN && (
                        <>
                            <Route path="/stuff" element={<Users />} />
                        </>
                    )}
                    <Route path="/user" element={<Customers />} />
                    <Route
                        path="/"
                        element={
                            role !== ROLE.OPERATOR ? <MainDashboard /> : <OperatorDashboard />
                        }
                    />
                    {role !== ROLE.OPERATOR && (<Route path="/mbi" element={<Mbi />} />)}
                    <Route path="*" element={<NotFound />} />
                    {role !== ROLE.OPERATOR && (<Route path="/ecpm" element={<Ecpms />} />)}
                    <Route path="/popupHistory" element={<PopupHistory />} />
                    <Route path="/product" element={<Products />} />
                    <Route path="/productDraw" element={<DrawProducts />} />
                    {role !== ROLE.OPERATOR && (<Route path="/campaign" element={<Campaigns />} />)}
                    <Route path="/supplier" element={<Suppliers />} />
                    <Route path="/activeorder" element={<Orders />} />
                    <Route path="/category" element={<Categories />} />
                    {role !== ROLE.OPERATOR && (<Route path="/reporting" element={<Reporting />} />)}
                    <Route path="/inactiveorder" element={<InActiveOrders />} />
                    <Route path="/notificationHistory" element={<NotificationHistory />} />
                    <Route path="/notificationMessages" element={<NotificationMessages />} />
                </Route>
            </Routes>
        </AppWrapper>
    );
}

export default App;
