import { DrawDataTable } from "@/components";
import { ICampaign } from "@/models";

interface DrawProps {
    campaign: ICampaign | null;
}

const Draw = ({ campaign }: DrawProps) => {
    return (
        <div className="datatable-crud">
            <div className="card" style={{ padding: 0 }}>
                <DrawDataTable campaignId={campaign?.id ?? null} drawJoinEndDate={campaign?.drawJoinEndDate ?? null} />
            </div>
        </div>
    );
};

export default Draw;
