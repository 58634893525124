import {
    ErrorField,
    InputField,
    DataTableDialogFooter,
    DropdownField,
} from "@/components";
import { MBI_TYPES, statusTypes } from "@/constants";
import { useGetProductsData } from "@/hooks";
import { IMbi } from "@/models";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { mbiSchema } from "@/validations";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog, InputSwitch, TabMenu } from "primereact";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface MbiDialogProps {
    visible: boolean;
    mbi: Omit<IMbi, "imageUrl">;
    isLoading: boolean;
    onHide: () => void;
    addMbi: (mbi: Omit<IMbi, "imageUrl">) => void;
    isAddItem: boolean;
}

const MbiDialog: React.FC<MbiDialogProps> = ({
    visible,
    mbi,
    isLoading,
    onHide,
    addMbi,
    isAddItem,
}) => {
    const { t } = useTranslation();

    const { refetchGetProducts, products } = useGetProductsData(false);

    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        { label: "Main", icon: "pi pi-fw pi-home" },
        { label: "Second Language", icon: "pi pi-fw pi-language" },
    ];

    const mbiTypes = [MBI_TYPES.CMP, MBI_TYPES.PRD, MBI_TYPES.URL, MBI_TYPES.VIW, MBI_TYPES.ROT];

    const typeOptions = mbiTypes.map((item) => ({
        label: item,
        value: item,
    }));

    const routeOptions = [
        { name: "Bonuses", value: "/campaign" },
        { name: "Account", value: "/profile" },
        { name: "Friends", value: "/friend" },
        { name: "Rewards", value: "/shop" },
        { name: "Profile", value: "/profile/user" },
        { name: "Invite Friends", value: "/profile/invite" },
        { name: "Address", value: "/profile/address" },
        { name: "FAQ", value: "/profile/faq" },
        { name: "Data", value: "/profile/data" },
        { name: "Orders", value: "/profile/reward" },
        { name: "Contact Us", value: "/profile/contact" },
        { name: "Language", value: "/profile/language" },
        { name: "Documents", value: "/profile/document" },
        { name: "RC Weekly Bonus", value: "/campaign/bonus" },
    ]?.map((route) => ({
        label: route.name,
        value: route.value,
    }));

    const productOptions = products?.map((product) => ({
        label: product.name,
        value: product,
    }));

    const statusOptions = statusTypes?.map((status) => ({
        label: status,
        value: status,
    }));

    const formik = useFormik({
        initialValues: mbi,
        enableReinitialize: true,
        validationSchema: mbiSchema,
        onSubmit: (data) => {
            data.targetId = Number(data.product?.id);
            data.targetUrl = data.targetUrl === '' ? null : data.targetUrl;
            data.targetUrlScnd = data.targetUrlScnd === '' ? null : data.targetUrlScnd;
            addMbi(data);
        },
    });

    useEffect(() => {
        if (visible) {
            callRefetch();
        }
    }, [visible, formik.values.type]);

    const hideDialog = () => {
        onHide();
        formik.resetForm();
    };

    const callRefetch = () => {
        if (formik.values.type === MBI_TYPES.PRD) {
            refetchGetProducts();
        }
    };

    const clearValues = () => {
        if (formik.values.type === MBI_TYPES.URL || formik.values.type === MBI_TYPES.VIW) {
            formik.values.campaign = "";
            formik.values.product = null;
        } else if (formik.values.type === MBI_TYPES.CMP) {
            formik.values.targetUrl = "";
            formik.values.targetUrlScnd = "";
            formik.values.product = null;
        } else if (formik.values.type === MBI_TYPES.PRD) {
            formik.values.targetUrl = "";
            formik.values.targetUrlScnd = "";
            formik.values.campaign = "";
        } else {
            formik.values.campaign = "";
            formik.values.targetUrl = "";
            formik.values.targetUrlScnd = "";
            formik.values.product = null;
        }
    };
    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header={t("Main Board Item Details")}
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <DropdownField
                            id="type"
                            name="type"
                            value={formik.values.type ?? ""}
                            required
                            onChange={formik.handleChange}
                            onClickCapture={clearValues}
                            options={typeOptions}
                            labelName="type"
                            labelText={capitalizeFirstLetter("type")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "type"),
                            })}
                        />
                        <InputField
                            id="title"
                            value={formik.values.title ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="title"
                            labelText={capitalizeFirstLetter("title")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="title" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "title"),
                            })}
                        />
                        <InputField
                            id="description"
                            value={formik.values.description ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="description"
                            labelText={capitalizeFirstLetter("description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="description" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "description"),
                            })}
                        />
                        <DropdownField
                            id="targetUrl"
                            name="targetUrl"
                            value={formik.values.targetUrl}
                            visible={formik.values.type === MBI_TYPES.ROT}
                            required
                            onChange={formik.handleChange}
                            onClickCapture={clearValues}
                            options={routeOptions}
                            labelName="targetUrl"
                            labelText="Route"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="targetUrl" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "targetUrl"),
                            })}
                        />
                        <InputField
                            id="targetUrl"
                            value={formik.values.type === MBI_TYPES.URL || formik.values.type === MBI_TYPES.VIW ? formik.values?.targetUrl ?? "" : ""}
                            type="text"
                            visible={formik.values.type === MBI_TYPES.URL || formik.values.type === MBI_TYPES.VIW}
                            required
                            onChange={formik.handleChange}
                            labelName="targetUrl"
                            labelText={capitalizeFirstLetter("url")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="targetUrl" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "targetUrl"),
                            })}
                        />
                        <DropdownField
                            id="product"
                            name="product"
                            value={formik.values.type === MBI_TYPES.PRD
                                ? productOptions?.find((option) => option.label === formik.values.product?.name)?.value ?? ""
                                : ""}
                            visible={formik.values.type === MBI_TYPES.PRD}
                            required
                            onChange={formik.handleChange}
                            options={productOptions ?? []}
                            filter={true}
                            labelName="product"
                            labelText={capitalizeFirstLetter("product")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="product" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "product"),
                            })}
                        />
                        {!isAddItem && (
                            <DropdownField
                                id="status"
                                name="status"
                                value={formik.values.status ?? ""}
                                required
                                autoFocus={false}
                                onChange={formik.handleChange}
                                options={statusOptions}
                                filter={true}
                                labelName="status"
                                labelText={capitalizeFirstLetter("status")}
                                getFormErrorMessage={() => <ErrorField formik={formik} name="status" />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "status"),
                                })}
                            />
                        )}
                        {formik.values.type === MBI_TYPES.VIW && (
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "16px" }}>
                                    <span>Show Header</span>
                                    <InputSwitch
                                        id="headerEnabled"
                                        checked={formik.values.headerEnabled === 1}
                                        onChange={(e) => {
                                            formik.setFieldValue('headerEnabled', e.value ? 1 : 0);
                                        }}
                                        className={classNames({
                                            "p-invalid": isFormFieldValid(formik, "headerEnabled"),
                                        })}
                                    />
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>Show Navigation Bar</span>
                                    <InputSwitch
                                        id="navbarEnabled"
                                        checked={formik.values.navbarEnabled === 1}
                                        onChange={(e) => {
                                            formik.setFieldValue('navbarEnabled', e.value ? 1 : 0);
                                        }}
                                        className={classNames({
                                            "p-invalid": isFormFieldValid(formik, "navbarEnabled"),
                                        })}
                                    />
                                </div>
                            </>
                        )}
                    </>) : (
                    <>
                        <InputField
                            id="titleScnd"
                            value={formik.values.titleScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="titleScnd"
                            labelText={capitalizeFirstLetter("Second Language Title")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="titleScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "titleScnd"),
                            })}
                        />

                        <InputField
                            id="descriptionScnd"
                            value={formik.values.descriptionScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="descriptionScnd"
                            labelText={capitalizeFirstLetter("Second Language Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="descriptionScnd" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "descriptionScnd"),
                            })}
                        />
                        <InputField
                            id="targetUrlScnd"
                            value={formik.values.type === MBI_TYPES.URL || formik.values.type === MBI_TYPES.VIW ? formik.values?.targetUrlScnd ?? "" : ""}
                            type="text"
                            visible={formik.values.type === MBI_TYPES.URL || formik.values.type === MBI_TYPES.VIW}
                            required
                            onChange={formik.handleChange}
                            labelName="targetUrlScnd"
                            labelText={capitalizeFirstLetter("Second Language Url")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="targetUrlScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "targetUrlScnd"),
                            })}
                        />
                    </>
                )}
            </form>
        </Dialog>
    );
};

export default MbiDialog;
