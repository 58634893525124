import { Chart } from "primereact";
import { useEffect, useState } from "react";
import { useGetAdsPerHourInLast24HoursData } from "@/hooks";

const AdsPerHourInLast24HoursChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { adsPerHourInLast24Hours, getAdsPerHourInLast24HoursIsLoading } =
        useGetAdsPerHourInLast24HoursData();

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const labels = adsPerHourInLast24Hours?.map((item: any) => item.time);
        const data = adsPerHourInLast24Hours?.map((item: any) => item.numbersOfAdsViewPerDay);
        const chartData = {
            labels: labels ?? [],
            datasets: [
                {
                    borderWidth: 1,
                    data: data ?? [],
                    label: "Number of Ads per Hour in the Last 24 Hours",
                    backgroundColor: documentStyle.getPropertyValue("--blue-500"),
                },
            ],
        };
        const options = {
            aspectRatio: 0.83,
            maintainAspectRatio: false,
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getAdsPerHourInLast24HoursIsLoading]);

    return (
        <div className="card mb-0">
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    );
};

export default AdsPerHourInLast24HoursChart;
