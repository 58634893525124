import { useQuery } from "react-query";
import { getDailyDrawTicketCount } from "@/services";

export const useGetDailyDrawTicketCountData = (drawId: string) => {
    const { data: dailyDrawTicketCount, isLoading: getDailyDrawTicketCountIsLoading } =
        useQuery(["dailyDrawTicketCount", drawId], async () => {
            if (drawId) {
                return await getDailyDrawTicketCount(drawId);
            }
        });
    return { dailyDrawTicketCount, getDailyDrawTicketCountIsLoading };
};
