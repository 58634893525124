import { useQuery } from "react-query";
import { getCampaignsUsageCounts } from "@/services";

export const useGetCampaignsUsageCountsData = () => {
    const {
        data: campaignsUsageCounts,
        isLoading: getCampaignsUsageCountsIsLoading,
    } = useQuery(
        "campaignsUsageCounts",
        getCampaignsUsageCounts,
    );
    return {
        campaignsUsageCounts,
        getCampaignsUsageCountsIsLoading,
    };
};
