import {
    ErrorField,
    InputField,
    DropdownField,
    CalendarField,
    InputNumberField,
    MultiSelectField,
    DataTableDialogFooter,
} from "@/components";
import { useFormik } from "formik";
import classNames from "classnames";
import { productSchema } from "@/validations";
import { useGetFootnotesData } from "@/hooks";
import { useTranslation } from "react-i18next";
import { ICategory, IProduct } from "@/models";
import { PRODUCT_TYPES } from "@/constants/enums";
import React, { useEffect, useState } from "react";
import { initialFootnote, statusTypes } from "@/constants";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { Badge, Column, DataTable, Dialog, InputSwitch, TabMenu } from "primereact";

interface ProductDialogProps {
    visible: boolean;
    product: Omit<IProduct, "imageUrl">;
    categories: ICategory[] | undefined;
    isLoading: boolean;
    isAddItem: boolean;
    isDrawProduct?: boolean;
    onHide: () => void;
    addProduct: (product: Omit<IProduct, "imageUrl">) => void;
}


const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "Second Language", icon: "pi pi-fw pi-language" },
    { label: "Label", icon: "pi pi-fw pi-tag" },
    { label: "T&C", icon: "pi pi-fw pi-check-circle" },
];

const statusOptions = statusTypes?.map((status) => ({
    label: status,
    value: status,
}));

const colorOptions = [
    { label: "", value: null },
    { label: "PURPLE", value: "#331074" },
    { label: "RED", value: "#E42626" },
];

const ProductDialog: React.FC<ProductDialogProps> = ({
    visible,
    product,
    isLoading,
    categories,
    isAddItem,
    isDrawProduct = false,
    onHide,
    addProduct,
}) => {
    const types = isDrawProduct ? [PRODUCT_TYPES.DRAW] : [PRODUCT_TYPES.DIGITAL, PRODUCT_TYPES.PHYSICAL];

    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);

    const [selectedFootnote, setSelectedFootnote] = useState(initialFootnote);
    const [updatedFootNote, setUpdatedFootNote] = useState('');
    const [updatedFootNote2lng, setUpdatedFootNote2lng] = useState('');


    const { refetchGetFootnotes, footnotes } = useGetFootnotesData(false);

    const footNoteOptions = footnotes?.map((footnote) => ({
        label: footnote.name,
        value: footnote,
    }));

    const categoriesOptions = categories?.map((category) => ({
        label: category.title,
        value: category,
    }));

    const typeOptions = types?.map((type) => ({
        label: type,
        value: type,
    }));

    function removeTrailingPipes(inputString: string) {
        // Sağ taraftaki boşlukları temizle
        inputString = inputString.trimEnd();

        // Sağdan başlayarak "|" karakterlerini kaldır
        while (inputString.endsWith('|')) {
            inputString = inputString.slice(0, -1);
        }

        inputString = inputString.replace(/\|+/g, '|');

        return inputString;
    }

    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validationSchema: productSchema,
        onSubmit: (data) => {
            if (!(data?.labelColor?.includes('#'))) {
                data.labelColor = data?.labelColor !== null ? '#' + data?.labelColor : null
            }

            data.footNote = removeTrailingPipes(updatedFootNote ?? '') === "" ? "" : `|${removeTrailingPipes(updatedFootNote ?? '')}`;
            data.footNote2lng = removeTrailingPipes(updatedFootNote2lng ?? '') === "" ? "" : `|${removeTrailingPipes(updatedFootNote2lng ?? '')}`;

            data.drawJoinEndDate = formatDate(data.drawJoinEndDate);
            data.drawDate = formatDate(data.drawDate);
            data.linkText = data.linkText === '' ? null : data.linkText;
            data.linkTextScnd = data.linkTextScnd === '' ? null : data.linkTextScnd;

            addProduct(data);
        },
    });

    useEffect(() => {
        if (visible) {
            refetchGetFootnotes();
            // setSelectedFootnote(footNoteOptions?.find((e) => e.label === formik.values.footNoteName)?.value ?? initialFootnote)
            setUpdatedFootNote(`${(formik.values.footNote ?? '').replace(/^(\|)*/, '')}|`);
            setUpdatedFootNote2lng(`${(formik.values.footNote2lng ?? '').replace(/^(\|)*/, '')}|`);
        }
    }, [visible]);

    useEffect(() => {
        if (selectedFootnote) {
            formik.setValues({
                ...formik.values,
                footNote: selectedFootnote.description ?? "",
                footNote2lng: selectedFootnote.description2lng ?? "",
                footNoteName: selectedFootnote.name ?? "",
            });
            setUpdatedFootNote(`${(selectedFootnote.description ?? "").replace(/^(\|)*/, '')}|`);
            setUpdatedFootNote2lng(`${(selectedFootnote.description2lng ?? "").replace(/^(\|)*/, '')}|`);
        }
    }, [selectedFootnote]);

    const hideDialog = () => {
        formik.resetForm();
        onHide();
    };

    const onDescCellEditComplete = (e: any) => {
        const { rowData, newValue, field, originalEvent: event } = e;
        const updatedData = [...updatedFootNote?.split('|') ?? []];
        const index = updatedData.findIndex((data) => rowData[field] === data)
        updatedData[index] = newValue;
        const newUpdatedData = updatedData.join('|');
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        setUpdatedFootNote(`${removeTrailingPipes(newUpdatedData)}|`);
    };

    const onDesc2lngCellEditComplete = (e: any) => {
        const { rowData, newValue, field, originalEvent: event } = e;
        const updatedData = [...updatedFootNote2lng?.split('|') ?? []];
        const index = updatedData.findIndex((data) => rowData[field] === data)
        updatedData[index] = newValue;
        const newUpdatedData = updatedData.join('|');
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        setUpdatedFootNote2lng(`${removeTrailingPipes(newUpdatedData)}|`);
    };

    const textEditor = (options: any) => {
        return <InputField type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} labelName={""} labelText={""} multiline />;
    };

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay değeri 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "800px" }}
            header={t("Product Details")}
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <InputField
                            id="name"
                            value={formik.values.name ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name"
                            labelText={capitalizeFirstLetter("name")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name"),
                            })}
                        />
                        <DropdownField
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            options={typeOptions ?? []}
                            labelName="type"
                            labelText={capitalizeFirstLetter("type")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "type"),
                            })}
                        />
                        <InputField
                            id="brand"
                            value={formik.values.brand ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="brand"
                            labelText={capitalizeFirstLetter("brand")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="brand" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "brand"),
                            })}
                        />
                        <InputField
                            id="longDesc"
                            value={formik.values.longDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="longDesc"
                            labelText={capitalizeFirstLetter("Long Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="longDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "longDesc"),
                            })}
                            multiline
                        />
                        <InputField
                            id="shortDesc"
                            value={formik.values.shortDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="shortDesc"
                            labelText={capitalizeFirstLetter("Short Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="shortDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "shortDesc"),
                            })}
                        />
                        <InputNumberField
                            id="sellingPoint"
                            inputId="sellingPoint"
                            showButtons
                            mode="currency"
                            currency="CKP"
                            value={formik.values.sellingPoint ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="sellingPoint"
                            labelText={capitalizeFirstLetter("Price")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="sellingPoint" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "sellingPoint"),
                            })}
                        />
                        <InputNumberField
                            id="yearlyPurchaseLimit"
                            inputId="yearlyPurchaseLimit"
                            showButtons
                            value={formik.values.yearlyPurchaseLimit ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="yearlyPurchaseLimit"
                            labelText={formik.values.type === PRODUCT_TYPES.DRAW ? "Daily Purchase Limit" : "Yearly Purchase Limit"}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="yearlyPurchaseLimit" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "yearlyPurchaseLimit"),
                            })}
                        />

                        <CalendarField
                            id="drawJoinEndDate"
                            labelName="drawJoinEndDate"
                            labelText="Draw Join End Date"
                            visible={formik.values.type === PRODUCT_TYPES.DRAW}
                            value={formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : undefined}
                            showTime={true}
                            showIcon
                            dateFormat="dd-mm-yy"
                            required
                            minDate={new Date()}
                            onChange={(e) => {
                                formik.handleChange(e);

                                const drawDate = formik.values.drawDate ? new Date(formik.values.drawDate) : null;

                                if (drawDate && e.value !== undefined && drawDate > e.value) {
                                    formik.setFieldError("drawJoinEndDate", "Draw Join End Date should be before Draw Date");
                                } else {
                                    formik.setFieldError("drawJoinEndDate", "");
                                }
                            }}
                        />

                        <CalendarField
                            id="drawDate"
                            labelName="drawDate"
                            labelText="Draw Date"
                            visible={formik.values.type === PRODUCT_TYPES.DRAW}
                            value={formik.values.drawDate ? new Date(formik.values.drawDate) : undefined}
                            showTime={true}
                            showIcon
                            disabled={!formik.values.drawJoinEndDate}
                            dateFormat="dd-mm-yy"
                            required
                            minDate={formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : undefined}
                            onChange={(e) => {
                                formik.handleChange(e);

                                const drawJoinEndDate = formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : null;

                                if (drawJoinEndDate && e.value !== undefined && drawJoinEndDate < e.value) {
                                    formik.setFieldError("drawDate", "Draw Date should be after Draw Join End Date");
                                } else {
                                    formik.setFieldError("drawDate", "");
                                }
                            }}
                        />

                        <InputField
                            id="linkText"
                            value={formik.values.linkText ?? ''}
                            type="text"
                            visible={formik.values.type === PRODUCT_TYPES.DRAW}
                            required
                            onChange={formik.handleChange}
                            labelName="linkText"
                            labelText="Link Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"linkText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "linkText"),
                            })}
                        />

                        <MultiSelectField
                            id="categories"
                            name="categories"
                            value={formik.values.categories}
                            multiple={true}
                            onChange={formik.handleChange}
                            options={categoriesOptions ?? []}
                            filter={true}
                            labelName="categories"
                            labelText={capitalizeFirstLetter("categories")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="categories" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "categories"),
                            })}
                        />
                        <InputField
                            id="productLink"
                            value={formik.values.productLink ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="productLink"
                            labelText={capitalizeFirstLetter("Product Link")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="productLink" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "productLink"),
                            })}
                        />
                        {!isAddItem && (
                            <DropdownField
                                id="status"
                                name="status"
                                value={formik.values.status ?? ""}
                                required
                                autoFocus={true}
                                onChange={formik.handleChange}
                                options={statusOptions}
                                filter={true}
                                labelName="status"
                                labelText={capitalizeFirstLetter("status")}
                                getFormErrorMessage={() => <ErrorField formik={formik} name="status" />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "status"),
                                })}
                            />
                        )}
                        <InputNumberField
                            id="stockQuantity"
                            inputId="stockQuantity"
                            showButtons
                            value={formik.values.stockQuantity ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="stockQuantity"
                            labelText={capitalizeFirstLetter("Stock Amount")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="stockQuantity" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "stockQuantity"),
                            })}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span>Show Stock Amount</span>
                            <InputSwitch
                                id="showStockAmount"
                                checked={formik.values.showStockAmount ?? false}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "showStockAmount"),
                                })}
                            />
                        </div>
                    </>
                ) : activeIndex === 1 ? (
                    <>
                        <InputField
                            id="name2lgn"
                            value={formik.values.name2lgn ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name2lgn"
                            labelText={capitalizeFirstLetter("name")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="name2lgn" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name2lgn"),
                            })}
                        />
                        <InputField
                            id="brand2lng"
                            value={formik.values.brand2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="brand2lng"
                            labelText={capitalizeFirstLetter("brand")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="brand2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "brand2lng"),
                            })}
                        />
                        <InputField
                            id="longDesc2lng"
                            value={formik.values.longDesc2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="longDesc2lng"
                            labelText={capitalizeFirstLetter("Long Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="longDesc2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "longDesc2lng"),
                            })}
                            multiline
                        />
                        <InputField
                            id="shortDesc2lng"
                            value={formik.values.shortDesc2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="shortDesc2lng"
                            labelText={capitalizeFirstLetter("Short Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="shortDesc2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "shortDesc2lng"),
                            })}
                        />


                        <InputField
                            id="linkTextScnd"
                            value={formik.values.linkTextScnd ?? ''}
                            type="text"
                            visible={formik.values.type === PRODUCT_TYPES.DRAW}
                            required
                            onChange={formik.handleChange}
                            labelName="linkTextScnd"
                            labelText="Second Language Link Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"linkTextScnd"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "linkTextScnd"),
                            })}
                        />
                    </>
                ) : activeIndex === 2 ? (
                    <>
                        <InputField
                            id="labelText"
                            value={formik.values.labelText ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="labelText"
                            labelText={capitalizeFirstLetter("text")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="labelText" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "labelText"),
                            })}
                        />
                        <DropdownField
                            id="labelColor"
                            value={formik.values.labelColor}
                            type="text"
                            required
                            options={colorOptions}
                            onChange={formik.handleChange}
                            labelName="labelColor"
                            labelText={capitalizeFirstLetter("color")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"labelColor"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "labelColor"),
                            })}
                            itemTemplate={(option) => {
                                return option?.value !== null ?
                                    <Badge value={option?.label} style={{ backgroundColor: option?.value }} />
                                    : null;
                            }}
                            valueTemplate={(option) => {
                                return option?.value !== null ?
                                    <Badge value={option?.label} style={{ backgroundColor: option?.value }} />
                                    : null;
                            }}
                        />
                    </>
                ) : (
                    <>
                        <DropdownField
                            id="footNoteName"
                            value={footNoteOptions?.find((option) => option.label === formik.values.footNoteName)?.value ?? ""}
                            type="text"
                            required
                            options={footNoteOptions ?? []}
                            onChange={(e) => setSelectedFootnote(e.value)}
                            labelName="footNoteName"
                            labelText="Name"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"footNoteName"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "footNoteName"),
                            })}
                        />

                        <DataTable
                            value={
                                (updatedFootNote?.split('|') ?? []).map((footnote, index) => ({
                                    id: index,
                                    footNote: footnote,
                                    footNote2lng: (updatedFootNote2lng?.split('|') ?? [])[index],
                                }))

                            }
                            editMode="cell"
                            tableStyle={{ minWidth: '50rem' }}
                            responsiveLayout="scroll"
                            showGridlines
                        >
                            <Column
                                field="footNote"
                                header="Description"
                                style={{ width: '50%' }}
                                editor={(options) => textEditor(options)}
                                onCellEditComplete={onDescCellEditComplete}
                            />
                            <Column
                                field="footNote2lng"
                                header="Description Second"
                                style={{ width: '50%' }}
                                editor={(options) => textEditor(options)}
                                onCellEditComplete={onDesc2lngCellEditComplete}
                            />
                        </DataTable>
                    </>
                )
                }
            </form>
        </Dialog>
    );
};

export default ProductDialog;
