import { Toast } from "primereact";
import { updateCampaignStatus } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateCampaignStatusData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: updateCampaignStatusIsLoading } = useMutation(
        async ({ campaignId, status }: {
            campaignId: bigint,
            status: string,
        }) => await updateCampaignStatus(campaignId, status),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Status updated");
            },
            onError: (error: any) => {
                let errorMessage = "Status not updated";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        updateCampaignStatusIsLoading,
    };
};
