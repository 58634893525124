import {
    Toast,
    Button,
    Column,
    Tooltip,
    Dropdown,
    InputText,
    Paginator,
    PaginatorPageState,
    ColumnFilterElementTemplateOptions,
} from "primereact";
import { useEffect, useRef, useState } from "react";
import {
    DataTableCrud,
    CustomerDialog,
    DataTableSearchHeader,
    CustomerAddressDialog,
    DataTableDeleteDialog,
    DataTableActionTemplate,
    DataTableStatusTemplate,
    CustomerNotificationsDialog,
} from "@/components";
import { ICustomer } from "@/models";
import {
    ROLE,
    STATUS,
    initialCustomer,
    customerFilters,
    customerStatusTypes,
} from "@/constants";
import {
    useRole,
    useGetCustomersData,
    useDeleteCustomerData,
    useUpdateCustomerData,
} from "@/hooks";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

const CustomerDataTable = () => {
    const { role } = useRole();
    const queryClient = useQueryClient();

    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
        null,
    );
    const [customer, setCustomer] = useState<ICustomer>(initialCustomer);
    const [filters, setFilters] = useState(customerFilters);
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
    const [customerDialog, setCustomerDialog] = useState(false);
    const [addressDialog, setAddressDialog] = useState(false);
    const [notificationsDialog, setNotificationsDialog] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [searchedEmailValue, setSearchedEmailValue] = useState('');
    const [searchedEmail, setSearchedEmail] = useState('');
    const [orderBy, setOrderBy] = useState<string>('NAME'); // Varsayılan sıralama alanı
    const [isDesc, setIsDesc] = useState<boolean>(false); // Varsayılan sıralama yönü

    const toast = useRef<Toast>(null);

    const {
        mutate: updateCustomerMutate,
        updateCustomerIsLoading,
        updateCustomerIsSuccess,
    } = useUpdateCustomerData(toast);
    const { mutate: deleteCustomerMutate } = useDeleteCustomerData(toast);

    const { customers, getCustomersIsLoading } = useGetCustomersData(page, rowsPerPage, isDeleted, isInactive, searchedEmail, orderBy.toUpperCase(), isDesc);
    const totalRecords = customers?.[0]?.totalElements ?? 0;

    const { t } = useTranslation();

    useEffect(() => {
        queryClient.setQueryData(["customers", "orderBy"], orderBy.toUpperCase());
        queryClient.setQueryData(["customers", "isDesc"], isDesc);
    }, [orderBy, isDesc]);

    useEffect(() => {
        if (updateCustomerIsSuccess) {
            hideCustomerDialog();
        }
    }, [updateCustomerIsSuccess]);

    const openAddressDialog = (rowData: ICustomer) => {
        setCustomer({ ...rowData });
        setAddressDialog(true);
    };

    const hideAddressDialog = () => {
        setAddressDialog(false);
    };

    const openEditCustomerDialog = (rowData: ICustomer) => {
        setCustomer({ ...rowData });
        setCustomerDialog(true);
    };

    const hideCustomerDialog = () => {
        setCustomerDialog(false);
    };

    const openNotificationsDialog = (rowData: ICustomer) => {
        setCustomer({ ...rowData });
        setNotificationsDialog(true);
    };

    const hideNotificationsDialog = () => {
        setNotificationsDialog(false);
    };

    const openDeleteCustomerDialog = (customer?: ICustomer) => {
        customer && setCustomer(customer);
        setDeleteCustomerDialog(true);
    };

    const hideDeleteCustomerDialog = () => {
        setDeleteCustomerDialog(false);
    };

    const saveCustomer = (customer: ICustomer) => {
        updateCustomerMutate(customer);
    };

    const deleteCustomer = () => {
        customer.id && deleteCustomerMutate(customer.id);
        hideDeleteCustomerDialog();
    };

    const onPageChange = (event: PaginatorPageState) => {
        setPage(event.page);
        setRowsPerPage(event.rows);
    };

    const handleEnterKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            setSearchedEmail(searchedEmailValue);
        }
    };

    const onSortChange = (event: any) => {
        setOrderBy(event.sortField);
        setIsDesc(event.sortOrder === -1);
    };

    return (
        <>
            <Toast ref={toast} />

            <DataTableCrud
                value={customers}
                selection={selectedCustomer}
                paginator={false}
                onSelectionChange={(e: any) => setSelectedCustomer(e.value)}
                loading={getCustomersIsLoading}
                title="users"
                header={
                    <DataTableSearchHeader
                        title={t("Manage Users")}
                    />
                }
                rows={rowsPerPage}
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["name", "surname", "email", "moneyAmount", "totalStep", "type"]}
                emptyMessage={t("No users found")}
                sortField={orderBy}
                sortOrder={isDesc ? -1 : 1}
                onSort={onSortChange}
            >
                <Column
                    field="name"
                    header={t("Name")}
                    sortable
                ></Column>
                <Column
                    field="surname"
                    header={t("Surname")}
                    sortable
                ></Column>
                <Column
                    field="email"
                    header={t("Email")}
                    sortable
                    filter
                    showFilterMenu={false}
                    filterElement={(options: ColumnFilterElementTemplateOptions) => {
                        return (
                            <div className="p-inputgroup">
                                <InputText
                                    value={options.value}
                                    onChange={(e) => setSearchedEmailValue(e.target.value)}
                                    onKeyUp={handleEnterKeyPress}
                                    placeholder="Search by email"
                                    style={{
                                        height: "2.9rem",
                                        borderTopRightRadius: "0",
                                        borderBottomRightRadius: "0",
                                        borderBottomLeftRadius: "1rem",
                                        borderTopLeftRadius: "1rem",
                                        boxShadow: "none",
                                    }}
                                />

                                <Button
                                    icon="pi pi-search"
                                    className="p-button p-inputgroup-addon"
                                    onClick={() => setSearchedEmail(searchedEmailValue)}
                                    style={{
                                        height: "2.9rem",
                                        borderTopRightRadius: "1rem",
                                        borderBottomRightRadius: "1rem",
                                        borderBottomLeftRadius: "0",
                                        borderTopLeftRadius: "0",
                                        boxShadow: "none",
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
                <Column
                    field="moneyAmount"
                    header={t("CKP")}
                    sortable
                ></Column>
                <Column
                    field="totalStep"
                    header={t("Total Step")}
                    sortable
                ></Column>

                <Column
                    field="status"
                    header={t("Status")}
                    showFilterMenu={false}
                    body={(rowData: ICustomer) => (
                        <DataTableStatusTemplate status={rowData.status} />
                    )}
                    filter
                    onFilterClear={() => {
                        setPage(0);
                        setIsDeleted(false);
                        setIsInactive(false);
                    }}
                    filterPlaceholder="Search by status"
                    filterElement={(options: ColumnFilterElementTemplateOptions) => {
                        return (
                            <Dropdown
                                value={options.value ?? STATUS.ACTIVE}
                                options={customerStatusTypes}
                                onChange={(e) => {
                                    const selectedValue = e.value;

                                    let updatedIsDeleted = false;
                                    let updatedIsInactive = false;

                                    if (selectedValue === "DELETED") {
                                        updatedIsDeleted = true;
                                        updatedIsInactive = false;
                                    } else if (selectedValue === STATUS.INACTIVE) {
                                        updatedIsDeleted = false;
                                        updatedIsInactive = true;
                                    }

                                    setPage(0);
                                    setIsDeleted(updatedIsDeleted);
                                    setIsInactive(updatedIsInactive);
                                    options.filterApplyCallback(e.value);
                                }}
                                itemTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                valueTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                placeholder="Select a Status"
                                className="p-column-filter"

                            />
                        );
                    }}
                ></Column>
                <Column
                    body={(rowData: ICustomer) => (
                        <>
                            {role === ROLE.ADMIN && (<DataTableActionTemplate
                                openEditDialog={() => openEditCustomerDialog(rowData)}
                            />)}
                            <Tooltip target="#p-info-button" content="User Info" position="bottom" />
                            <Button
                                id="p-info-button"
                                icon="pi pi-info-circle"
                                className="p-button-rounded p-button-secondary m-1"
                                onClick={() => openAddressDialog(rowData)}
                            />
                            <Tooltip target="#p-notification-button" content="Notifications" position="bottom" />
                            <Button
                                id="p-notification-button"
                                icon="pi pi-bell"
                                className="p-button-rounded p-button m-1"
                                onClick={() => openNotificationsDialog(rowData)}
                            />
                        </>
                    )}
                    exportable={false}
                    style={{ minWidth: "14rem" }}
                ></Column>
            </DataTableCrud>
            <Paginator first={rowsPerPage * page} rows={rowsPerPage} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange} />

            <DataTableDeleteDialog
                visible={deleteCustomerDialog}
                data={selectedCustomer ?? customer}
                onHide={hideDeleteCustomerDialog}
                onDelete={deleteCustomer}
            />

            <CustomerDialog
                visible={customerDialog}
                customer={customer}
                isLoading={updateCustomerIsLoading}
                onHide={hideCustomerDialog}
                saveCustomer={saveCustomer}
            />
            <CustomerAddressDialog
                visible={addressDialog}
                customerId={customer.id ?? null}
                onHide={hideAddressDialog}
            />
            <CustomerNotificationsDialog
                visible={notificationsDialog}
                customer={customer}
                onHide={hideNotificationsDialog}
            />
        </>
    );
};

export default CustomerDataTable;
