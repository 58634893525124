import {
    DashboardCard,
    CustomerCountByStepChart,
    RetentionInLast7DaysChart,
    AdsPerHourInLast24HoursChart,
    ApuAndAdWatchedUserCountInLast7DaysDataTable,
} from "@/components";
import {
    useGetAdViewingRateData, useGetUserAdInfoRateData,
} from "@/hooks";

import { Col, Grid } from "@/layouts";


const Reporting = () => {

    const { adViewingRate, getAdViewingRateIsLoading } = useGetAdViewingRateData();
    const { userAdInfoRate, getUserAdInfoRateIsLoading } = useGetUserAdInfoRateData();

    return (
        <>
            <Grid className="m-0">
                <Col className="col-12 flex align-items-center m-0 py-10">
                    <div className="card mb-0 w-full">
                        <h1 className="text-2xl font-bold m-0">
                            Reporting
                        </h1>
                    </div>
                </Col>

                <DashboardCard
                    title="Ad Viewing Rate"
                    count={`${adViewingRate ?? 0}%`}
                    countNegative={(adViewingRate ?? 0) < 0}
                    icon="pi-eye"
                    iconTextColor="text-blue-500"
                    iconBgColor="bg-blue-100"
                    footerTitle={`Daily`}
                    className="col-12 lg:col-4"
                    isLoading={getAdViewingRateIsLoading}
                />

                <DashboardCard
                    title="Ads Per User"
                    count={`${userAdInfoRate?.find(item => item[0] === "adsPerUserResult")?.[1] ?? 0}%`}
                    countNegative={(userAdInfoRate?.find(item => item[0] === "adsPerUserResult")?.[1] ?? 0) < 0}
                    icon="pi-dollar"
                    iconTextColor="text-orange-500"
                    iconBgColor="bg-orange-100"
                    footerTitle={`Daily`}
                    className="col-12 lg:col-4"
                    isLoading={getUserAdInfoRateIsLoading}
                />

                <DashboardCard
                    title="Unique User"
                    count={`${userAdInfoRate?.find(item => item[0] === "uniqueUserResult")?.[1] ?? 0}%`}
                    countNegative={(userAdInfoRate?.find(item => item[0] === "uniqueUserResult")?.[1] ?? 0) < 0}
                    icon="pi-user"
                    iconTextColor="text-indigo-500"
                    iconBgColor="bg-blue-100"
                    footerTitle={`Daily`}
                    className="col-12 lg:col-4"
                    isLoading={getUserAdInfoRateIsLoading}
                />

                <Col className="col-12 xl:col-12 px-0">
                    <Col className="col-12 m-0">
                        <AdsPerHourInLast24HoursChart />
                    </Col>
                </Col>

                <Col className="col-12 xl:col-6 px-0">
                    <Col className="col-12 m-0">
                        <RetentionInLast7DaysChart />
                    </Col>
                </Col>

                <Col className="col-12 xl:col-6 px-0">
                    <Col className="col-12 m-0">
                        <CustomerCountByStepChart />
                    </Col>
                </Col>

                <Col className="col-12 xl:col-6 px-0">
                    <ApuAndAdWatchedUserCountInLast7DaysDataTable />
                </Col>
            </Grid>
        </>
    );
};

export default Reporting;
