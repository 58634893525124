import { Toast } from "primereact";
import { deleteCampaign } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteCampaignData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: deleteCampaignIsLoading } = useMutation(
        async (campaignId: bigint) => await deleteCampaign(campaignId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Campaign deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteCampaignIsLoading,
    };
};
