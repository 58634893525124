import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";
import { addProductInfographImage, addProductInfographScndImage } from "@/services";

export const useAddProductInfographImageData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addProductInfographImagesIsLoading,
        isSuccess: addProductInfographImagesIsSuccess,
    } = useMutation(
        async ({ infographImage, imageType }: {
            infographImage: FormData,
            imageType?: number | null
        }) => {
            if (imageType === 2) {
                await addProductInfographScndImage(infographImage);
            } else if (imageType === 1) {
                await addProductInfographImage(infographImage);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("drawProducts");
                toastSuccess(toast, "Infograph image added");
            },
            onError: (error: any) => {
                let errorMessage = "Infograph image not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addProductInfographImagesIsLoading, addProductInfographImagesIsSuccess };
};
