import { useQuery } from "react-query";
import { getDrawProducts } from "@/services";

export const useGetDrawProductsData = (enabled?: boolean) => {
    const {
        data: drawProducts,
        isLoading: getDrawProductsIsLoading,
        refetch: refetchGetProducts,
    } = useQuery("drawProducts", getDrawProducts, {
        enabled,
    });
    return { drawProducts, getDrawProductsIsLoading, refetchGetProducts };
};
