import { useQuery } from "react-query";
import { getDrawList } from "@/services";

export const useGetDrawListData = () => {
    const { data: drawList, isLoading: getDrawListIsLoading } = useQuery(
        "drawList",
        getDrawList,
    );
    return { drawList, getDrawListIsLoading };
};
