import { getDrawUserData } from "@/services";
import { useQuery } from "react-query";

export const useGetDrawUserData = (customerFBId: string | null) => {
    const {
        data: user,
        isLoading: getDrawUserDataIsLoading,
        refetch: refetchGetDrawUserData,
    } = useQuery(
        ["customer", customerFBId],
        async () => {
            if (customerFBId) {
                return await getDrawUserData(customerFBId ?? "");
            }
            return null
        },
    );
    return { user, getDrawUserDataIsLoading, refetchGetDrawUserData };
};
