export const TRANSLATIONS_UR = {
    Home: "ہوم",
    Dashboard: "ڈیش بورڈ",
    "User Management": "کسٹمرز کا انتظام",
    "Products Management": "پروڈکٹس کا انتظام",
    Products: "پروڈکٹس",
    Suppliers: "سپلائرز",
    Categories: "زمرہ جات",
    System: "سسٹم",
    Users: "صارفین",
    "Manage Products": "پروڈکٹس کا انتظام",
    "Manage Users": "صارفین کا انتظام",
    "Manage Suppliers": "سپلائرز کا انتظام",
    "Manage Categories": "زمرہ جات کا انتظام",
    "Product Details": "پروڈکٹ کی تفصیلات",
    "User Details": "صارف کی تفصیلات",
    "Supplier Details": "سپلائر کی تفصیلات",
    "Category Details": "زمرہ جات کی تفصیلات",
    "Are you sure you want to delete": "کیا آپ یقینی طور پر حذف کرنا چاہتے ہیں",
    "Showing {first} to {last} of {totalRecords} products":
        "{totalRecords} پروڈکٹس کے {first} سے {last} تک دکھا رہا ہے",
    "Showing {first} to {last} of {totalRecords} users":
        "{totalRecords} صارفین کے {first} سے {last} تک دکھا رہا ہے",
    "Showing {first} to {last} of {totalRecords} suppliers":
        "{totalRecords} سپلائرز کے {first} سے {last} تک دکھا رہا ہے",
    "Showing {first} to {last} of {totalRecords} categories":
        "{totalRecords} زمرہ جات کے {first} سے {last} تک دکھا رہا ہے",
    Name: "نام",
    Surname: "نام خاندانی",
    Email: "ای میل",
    Password: "Şifre",
    "Gsm No": "Gsm نمبر",
    Gsmno: "Gsm نمبر",
    Address: "پتہ",
    City: "شہر",
    Birthdate: "تاریخ پیدائش",
    MAN: "مرد",
    WOMAN: "عورت",
    Status: "حالت",
    Type: "قسم",
    New: "نئی",
    Delete: "حذف کریں",
    "Search...": "تلاش کریں ...",
    Yes: "جی ہاں",
    No: "نہیں",
    Cancel: "منسوخ کریں",
    Save: "محفوظ کریں",
    Language: "زبان",

    ENGLISH: "انگریزی",
    TURKISH: "ترکی",
    URDU: "اردو",
};
