import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logoLight from "@assets/images/logo-white.svg";
import logoDark from "@assets/images/logo-dark.svg";
import { useMenuToggle, useMobileMenuToggle, useTheme } from "@/hooks";
import { appName, CONFIG_VARS, profileItems } from "@constants/index";
import { Menu } from "primereact";
import { TopbarMenuItems, TopbarMenu, LanguageDialog } from "@/components";
import { isDesktop } from "@/utils";
import moment from "moment";

const AppTopbar = () => {
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const profileMenuRef = useRef<Menu>(null);
    const { setMenuIsActive } = useMenuToggle();
    const { setMobileMenuIsActive } = useMobileMenuToggle();
    const { layoutColorMode, setLayoutColorMode } = useTheme();

    const onMobileTopbarMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setMobileTopbarMenuActive((prevState) => !prevState);
    };

    const onSideBarMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setMenuIsActive((prevState) => !prevState);
        !isDesktop() && setMobileMenuIsActive((prevState) => !prevState);
    };

    const closeDialog = () => {
        setOpenDialog(false);
    };

    const changeTheme = () => {
        setLayoutColorMode((prevState) =>
            prevState === CONFIG_VARS.LIGHT ? CONFIG_VARS.DARK : CONFIG_VARS.LIGHT,
        );
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img
                    src={layoutColorMode === CONFIG_VARS.LIGHT ? logoDark : logoLight}
                    alt="logo"
                />
                <span>{appName}</span>
            </Link>

            <button
                type="button"
                className="p-link  layout-menu-button layout-topbar-button"
                onClick={(e) => onSideBarMenuClick(e)}
            >
                <i className="pi pi-bars" />
            </button>

            <button
                type="button"
                className="p-link layout-topbar-menu-button layout-topbar-button"
                onClick={(e) => onMobileTopbarMenuClick(e)}
            >
                <i className="pi pi-ellipsis-v" />
            </button>

            <div style={{ padding: '1rem' }}>
                {moment(currentTime.toLocaleTimeString("en-US", {
                    timeZone: 'Asia/Karachi',
                    hour12: false,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })).format("DD-MM-YYYY / HH:mm:ss")}
            </div>

            <TopbarMenu active={mobileTopbarMenuActive}>
                <TopbarMenuItems
                    title="Events"
                    iconClassName="pi pi-sun"
                    onClick={() => changeTheme()}
                />
                <TopbarMenuItems
                    title="Language"
                    iconClassName="pi pi-language"
                    onClick={() => setOpenDialog(true)}
                />
                <TopbarMenuItems title="Settings" iconClassName="pi pi-cog" />
                <TopbarMenuItems
                    title="Profile"
                    iconClassName="pi pi-user"
                    onClick={(e) => profileMenuRef.current?.toggle(e)}
                />
            </TopbarMenu>

            <Menu ref={profileMenuRef} model={profileItems} popup />
            <LanguageDialog visible={openDialog} onHide={closeDialog} />
        </div>
    );
};

export default AppTopbar;
