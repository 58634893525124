import { Column, DataTable } from "primereact";
import { useGetPromotionCodeData } from "@/hooks";
import { t } from "i18next";

const PromotionCodeAnalyticCard = () => {
    const { promotionCodeData, getPromotionCodeDataIsLoading } =
        useGetPromotionCodeData();

    return (
        <div className="col-12 m-0">
            <div className="card mb-0">
                <h5>Promotion Codes</h5>
                <DataTable
                    value={promotionCodeData}
                    loading={getPromotionCodeDataIsLoading}
                    responsiveLayout="scroll"
                    paginator
                    rows={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t(
                        `Showing {first} to {last} of {totalRecords} Orders`,
                    )}
                >
                    <Column field="0" header="Promotion Code" sortable style={{ width: "35%" }} />
                    <Column
                        field="1"
                        header="Count"
                        sortable
                        style={{ width: "35%" }}
                    />
                </DataTable>
            </div>
        </div>
    );
};

export default PromotionCodeAnalyticCard;
