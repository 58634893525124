import React from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Dialog } from "primereact";
import {
    ErrorField,
    CalendarField,
    InputNumberField,
    DataTableDialogFooter,
} from "@/components";
import { IEcpm } from "@/models";
import { ecpmSchema } from "@/validations";
import { isFormFieldValid } from "@/utils";

interface EcpmDialogProps {
    visible: boolean;
    ecpm: IEcpm;
    isLoading: boolean;
    onHide: () => void;
    addEcpm: (ecpm: IEcpm) => void;
}

const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay değeri 0-11 arası olduğu için +1 ekliyoruz
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};

const EcpmDialog: React.FC<EcpmDialogProps> = ({
    visible,
    ecpm,
    isLoading,
    onHide,
    addEcpm,
}) => {

    const hideDialog = () => {
        onHide();
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: ecpm,
        enableReinitialize: true,
        validationSchema: ecpmSchema,
        onSubmit: (data) => {
            data.date = formatDate(data.date);
            addEcpm(data);
        },
    });

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header="Ecpm Details"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <form onSubmit={formik.handleSubmit}>
                <CalendarField
                    id="date"
                    dateFormat="yy-mm-dd"
                    value={formik.values.date ? new Date(formik.values.date) : undefined}
                    showIcon
                    required
                    maxDate={new Date()}
                    onChange={formik.handleChange}
                    labelName={"date"}
                    labelText={"Date"}
                    getFormErrorMessage={() => <ErrorField formik={formik} name={"date"} />}
                    className={classNames({
                        "p-invalid": isFormFieldValid(formik, "date"),
                    })}
                />
                <InputNumberField
                    id="ecpm"
                    inputId="ecpm"
                    minFractionDigits={2}
                    value={formik.values.ecpm}
                    required
                    onValueChange={formik.handleChange}
                    labelName="ecpm"
                    labelText="eCPM"
                    getFormErrorMessage={() => <ErrorField formik={formik} name={"ecpm"} />}
                    className={classNames({
                        "p-invalid": isFormFieldValid(formik, "ecpm"),
                    })}
                />
            </form>
        </Dialog>
    );
};

export default EcpmDialog;
