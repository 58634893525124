import React from "react";
import { Dialog } from "primereact";
import { ICustomer } from "@/models";
import { CustomerNotificationLogDataTable } from "@/components";

interface CustomerNotificationsDialogProps {
    visible: boolean;
    customer: ICustomer | undefined;
    onHide: () => void;
}

const CustomerNotificationsDialog: React.FC<CustomerNotificationsDialogProps> = ({
    visible,
    onHide,
    customer,
}) => {

    const hideDialog = () => {
        onHide();
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "70%" }}
            header="User Notifications Details"
            modal
            className="p-fluid"
            onHide={hideDialog}
        >
            <div className="datatable-crud">
                <div className="card">
                    <CustomerNotificationLogDataTable customerId={customer?.id ?? null} />
                </div>
            </div>
        </Dialog>
    );
};

export default CustomerNotificationsDialog;
