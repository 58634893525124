import { ROLE } from "../enums";

export const getMenuItems = (role: ROLE | undefined) => {
   return [
      {
         label: "Home",
         items: [
            { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
            {
               label: "User Management",
               icon: "pi pi-fw pi-user",
               items: role === ROLE.ADMIN ? [
                  {
                     label: "Users",
                     icon: "pi pi-fw pi-users",
                     to: "/user",
                  },
                  {
                     label: "eCPM",
                     icon: "pi pi-fw pi-chart-bar",
                     to: "/ecpm",
                  },
                  {
                     label: "Pop-Up Message",
                     icon: "pi pi-fw pi-comment",
                     to: "/popupHistory",
                  },
               ] : [
                  {
                     label: "Users",
                     icon: "pi pi-fw pi-users",
                     to: "/user",
                  },
                  {
                     label: "Pop-Up Message",
                     icon: "pi pi-fw pi-comment",
                     to: "/popupHistory",
                  },
               ],
            },
            {
               label: "Product Management",
               icon: "pi pi-fw pi-shopping-cart",
               items: [
                  {
                     label: "Products",
                     icon: "pi pi-fw pi-shopping-bag",
                     to: "/product",
                  },
                  {
                     label: "Draw Products",
                     icon: "pi pi-fw pi-ticket",
                     to: "/productDraw",
                  },
                  { label: "Suppliers", icon: "pi pi-fw pi-building", to: "/supplier" },
                  { label: "Categories", icon: "pi pi-fw pi-book", to: "/category" },
               ],
            },

            role !== ROLE.OPERATOR && {
               label: "Campaign Management",
               icon: "pi pi-fw pi-megaphone",
               to: "/campaign",
            },
            {
               label: "Order Management",
               icon: "pi pi-fw pi-truck",
               items: [
                  {
                     label: "Active Orders",
                     icon: "pi pi-fw pi-spinner",
                     to: "/activeorder",
                  },
                  {
                     label: "In Active Orders",
                     icon: "pi pi-fw pi-moon",
                     to: "/inactiveorder",
                  },
               ],
            },
            role !== ROLE.OPERATOR && {
               label: "Main Board Management",
               icon: "pi pi-fw pi-folder",
               to: "/mbi",
            },
            {
               label: "Notification Management",
               icon: "pi pi-fw pi-bell",
               items: [
                  {
                     label: "Notification Messages",
                     icon: "pi pi-fw pi-envelope",
                     to: "/notificationMessages",
                  },
                  {
                     label: "Notification History",
                     icon: "pi pi-fw pi-clock",
                     to: "/notificationHistory",
                  },
               ],
            },
            role !== ROLE.OPERATOR && {
               label: "Reporting",
               icon: "pi pi-fw pi-chart-line",
               to: "/reporting"
            },
            role === ROLE.ADMIN && {
               label: "System",
               icon: "pi pi-fw pi-cog",
               items: [
                  {
                     label: "Staff",
                     icon: "pi pi-fw pi-users",
                     to: "/stuff",
                  },
               ],
            },
         ].filter(Boolean),
      },
   ];
};
