import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { addCampaignPopupImages } from "@/services";
import { useMutation, useQueryClient } from "react-query";

export const useAddCampaignPopupImagesData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCampaignPopupImagesIsLoading,
        isSuccess: addCampaignPopupImagesIsSuccess,
    } = useMutation(
        async ({ campaignId, popupImages }: {
            campaignId: String | null,
            popupImages: FormData,
        }) => {
            await addCampaignPopupImages(campaignId, popupImages);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("campaigns");
                toastSuccess(toast, "Campaign popup images added");
            },
            onError: (error: any) => {
                let errorMessage = "Campaign popup images not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addCampaignPopupImagesIsLoading, addCampaignPopupImagesIsSuccess };
};
