import moment from "moment";
import { Chart } from "primereact";
import { useEffect, useState } from "react";
import { useGetLastWeekGameInfoData } from "@/hooks";

const PrizePercentagesFromOpenedBoxesChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { lastWeekGameInfo, getLastWeekGameInfoIsLoading } =
        useGetLastWeekGameInfoData();

    useEffect(() => {
        const backgroundColors = [
            '#1f78b4', // royal blue
            '#33a02c', // forest green
            '#e31a1c', // red
            '#ff7f00', // orange
            '#6a3d9a', // slate blue
            '#b15928', // brown
        ];
        const labels = lastWeekGameInfo?.map((item: any) => moment(item.date).format("DD-MM-YYYY"));
        const ckp2 = lastWeekGameInfo?.map((item: any) => item.prize_2_percentage);
        const ckp1 = lastWeekGameInfo?.map((item: any) => item.prize_1_percentage);
        const ckp05 = lastWeekGameInfo?.map((item: any) => item.prize_05_percentage);
        const ckp025 = lastWeekGameInfo?.map((item: any) => item.prize_025_percentage);
        const tryAgain = lastWeekGameInfo?.map((item: any) => item.prize_try_again_percentage);
        const freeChange = lastWeekGameInfo?.map((item: any) => item.prize_free_chance_percentage);

        const chartData = {
            labels: labels ?? [],
            datasets:
                [
                    {
                        data: ckp2 ?? [],
                        label: "2 CKP",
                        borderColor: backgroundColors[0],
                        tension: 0.4,
                    },
                    {
                        data: ckp1 ?? [],
                        label: "1 CKP",
                        borderColor: backgroundColors[1],
                        tension: 0.4,
                    },
                    {
                        data: ckp05 ?? [],
                        label: "0.5 CKP",
                        borderColor: backgroundColors[2],
                        tension: 0.4,
                    },
                    {
                        data: ckp025 ?? [],
                        label: "0.25 CKP",
                        borderColor: backgroundColors[3],
                        tension: 0.4,
                    },
                    {
                        data: freeChange ?? [],
                        label: "Free Chance",
                        borderColor: backgroundColors[4],
                        tension: 0.4,
                    },
                    {
                        data: tryAgain ?? [],
                        label: "Try Again",
                        borderColor: backgroundColors[5],
                        tension: 0.4,
                    },
                ],
        };
        const options = {
            aspectRatio: 0.83,
            maintainAspectRatio: false,
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getLastWeekGameInfoIsLoading]);

    return (
        <div className="card mb-0">
            <h4 style={{ textAlign: "center" }}>
                Prize Percentages From Opened Boxes
            </h4>
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
};

export default PrizePercentagesFromOpenedBoxesChart;
