import React from "react";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "primereact/button";

interface ExcelExporterProps {
    data: any[];
    headers: string[];
    filename: string;
    isLoading: boolean;
    disabled: boolean;
}

const ExcelExporter: React.FC<ExcelExporterProps> = ({
    data,
    headers,
    filename,
    isLoading,
    disabled,
}) => {
    const handleExportExcel = () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Data");

        // Excel tablosu başlıkları
        const headerRow = worksheet.addRow(headers);

        // Verileri Excel tablosuna ekleme
        data.forEach((rowData: any) => {
            const values = Object.values(rowData);
            worksheet.addRow(values);
        });

        // Excel dosyasını oluşturma
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `${filename}.xlsx`);
        });
    };

    return (
        <Button
            label="Export to Excel"
            className="p-button-success"
            onClick={handleExportExcel}
            loading={isLoading}
            disabled={disabled}
        />
    );
};

export default ExcelExporter;
