import { axiosInstance } from "@/api/axios.instance";
import { INotification, INotificationMessage } from "@/models";

export const getNotificationMessages = async (): Promise<any> =>
    await axiosInstance.get(`/customer/notification/messages/getAll`).then((res) => res.data);

export const getSingleNotificationMessages = async (): Promise<any> =>
    await axiosInstance.get(`/customer/notification/messages/getAllNonCronJob`).then((res) => res.data);

export const addNotificationMessage = async (notificationMessage: INotificationMessage) =>
    await axiosInstance.post(`/customer/notification/messages/add`, notificationMessage);

export const updateNotificationMessage = async (notificationMessage: INotificationMessage) =>
    await axiosInstance.put(`/customer/notification/messages/update`, notificationMessage);

export const deleteNotificationMessage = async (notificationMessageId: number) =>
    await axiosInstance.delete(`/customer/notification/messages/delete/${notificationMessageId}`);

export const getCustomerNotifications = async (customerId: number | null): Promise<INotification> => {
    const response = await axiosInstance.get("/customer/notificationLog/getSingle", {
        params: { customerId: customerId }
    });
    const notificationLogs: INotification = response.data;
    return notificationLogs;
}

export const getNotificationHistory = async (page: number | null, rowPerPage: number | null): Promise<INotification[]> => {
    const response = await axiosInstance.get("/customer/notificationLog/getNonSingle", {
        params: { page: page, limit: rowPerPage }
    });
    const responseData = response.data;

    responseData.content = responseData.content.map((item: any) => ({
        ...item,
        totalElements: responseData.totalElements,
    }));
    const notificationLogs: INotification[] = responseData.content;
    return notificationLogs;
}

export const sendNotificationToUser = async (customerId: number | null, notificationMessageId: number | null) =>
    await axiosInstance.post(`/customer/notification/sendToUser`, null, {
        params: { customerId: customerId, notificationMsgId: notificationMessageId }
    });
