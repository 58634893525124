import { Button } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableDialogFooterProps {
    loading?: boolean;
    hideDialog: () => void;
    handleSubmit: () => void;
    submitButtonText?: string;
}

const DataTableDialogFooter: React.FC<DataTableDialogFooterProps> = ({
    loading,
    hideDialog,
    handleSubmit,
    submitButtonText,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Button
                label={t("Cancel")}
                icon="pi pi-times"
                className="p-button-text"
                disabled={loading}
                onClick={hideDialog}
            />
            <Button
                label={submitButtonText ?? t("Save")}
                type="submit"
                icon="pi pi-check"
                className="p-button-text"
                loading={loading}
                onClick={handleSubmit}
            />
        </>
    );
};

export default DataTableDialogFooter;
