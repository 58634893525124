import { Toast } from "primereact";
import { IProduct } from "@/models";
import { addProduct } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddProductData = (toast: React.RefObject<Toast>, isItProduct: boolean = true) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addProductIsLoading,
        isSuccess: addProductIsSuccess,
    } = useMutation(
        async (product: Omit<IProduct, "imageUrl">) => await addProduct(product),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isItProduct ? "products" : "drawProducts");
                toastSuccess(toast, "Product added");
            },
            onError: (error: any) => {
                let errorMessage = "Product not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addProductIsLoading, addProductIsSuccess };
};
