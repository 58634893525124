import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Calendar, Chart } from "primereact";
import { useGetCustomerCountByStepData } from "@/hooks";

const CustomerCountByStepChart = () => {

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);

    const queryClient = useQueryClient();
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [selectedDate, setSelectedDate] = useState(maxDate);

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay değeri 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const { customerCountByStep, getCustomerCountByStepIsLoading } = useGetCustomerCountByStepData(formatDate(selectedDate));


    useEffect(() => {
        queryClient.setQueryData(["customerCountByStep", "date"], formatDate(selectedDate));

        const documentStyle = getComputedStyle(document.documentElement);
        const labels = customerCountByStep?.map((item: any) => item[0]);
        const data = customerCountByStep?.map((item: any) => item[1]);
        const chartData = {
            labels: labels ?? [],
            datasets: [
                {
                    borderWidth: 1,
                    data: data ?? [],
                    label: "Total User Count by Step",
                    backgroundColor: documentStyle.getPropertyValue("--indigo-500"),
                },
            ],
        };
        const options = {
            aspectRatio: 0.83,
            maintainAspectRatio: false,
        };

        setChartData(chartData);
        setChartOptions(options);
    }, [getCustomerCountByStepIsLoading, selectedDate]);

    return (
        <div className="card mb-0">
            <Chart type="bar" data={chartData} options={chartOptions} />
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <Calendar
                    dateFormat="dd-mm-yy"
                    value={selectedDate}
                    showIcon
                    required
                    maxDate={maxDate}
                    onChange={(e) => {
                        if (e.value instanceof Date) {
                            setSelectedDate(e.value);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default CustomerCountByStepChart;
