import { useQuery } from "react-query";
import { getDrawTicketListData } from "@/services";

export const useGetDrawTicketListData = (campaignId: bigint | null) => {
    const {
        data: drawTicketList,
        isLoading: getDrawTicketListIsLoading,
    } = useQuery(
        ["drawTicketList", campaignId],
        async () => await getDrawTicketListData(campaignId),
    );
    return { drawTicketList, getDrawTicketListIsLoading };
};
