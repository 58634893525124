import { getEcpms } from "@/services";
import { useQuery } from "react-query";

export const useGetEcpmsData = () => {
    const {
        data: ecpms,
        isLoading: getEcpmsIsLoading,
    } = useQuery("ecpms", getEcpms);
    return { ecpms, getEcpmsIsLoading };
};
