import { IUser } from "@/models";
import { Toast } from "primereact";
import { addUser } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddUserData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addUserIsLoading,
        isSuccess: addUserIsSuccess,
    } = useMutation(async (user: IUser) => await addUser(user), {
        onSuccess: () => {
            queryClient.invalidateQueries("users");
            toastSuccess(toast, "User added");
        },
        onError: (error: any) => {
            let errorMessage = "User not added";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return { mutate, addUserIsLoading, addUserIsSuccess };
};
