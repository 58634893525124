import { Toast } from "primereact";
import { updateProductStatus } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateProductStatusData = (toast: React.RefObject<Toast>, isItProduct: boolean = true) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: updateProductStatusIsLoading } = useMutation(
        async ({ productId, status }: {
            productId: number,
            status: string,
        }) => await updateProductStatus(productId, status),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isItProduct ? "products" : "drawProducts");
                toastSuccess(toast, "Status updated");
            },
            onError: (error: any) => {
                let errorMessage = "Status not updated";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        updateProductStatusIsLoading,
    };
};
