import { useQuery } from "react-query";
import { getCustomerInvitedFriends } from "@/services";

export const useGetCustomerInvitedFriendsData = (customerId: number | null) => {
    const { data: invitedFriends, isLoading: getInvitedFriendsIsLoading } = useQuery(
        ["customerInvitedFriends", customerId],
        async () => {
            return await getCustomerInvitedFriends(customerId);

        },
    );
    return { invitedFriends, getInvitedFriendsIsLoading };
};
