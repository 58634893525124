import { IMbi } from "@/models";
import { axiosInstance } from "@/api/axios.instance";

export const getMbis = async (): Promise<IMbi[]> =>
    await axiosInstance.get(`/mbis`).then((res) => res.data);

export const addMbi = async (mainBoard: Omit<IMbi, "imageUrl">) =>
    await axiosInstance.post(`/mbis`, mainBoard);

export const updateMbi = async (mainBoard: Omit<IMbi, "imageUrl">) =>
    await axiosInstance.put(`/mbis`, mainBoard);

export const updateMbiStatus = async (mbiId: bigint, status: string) =>
    await axiosInstance.put(`/mbis/status`, null, {
        params: {
            id: mbiId,
            status: status,
        }
    });

export const deleteMbi = async (mbisId: bigint) =>
    await axiosInstance.delete(`/mbis/${mbisId}`);

export const orderMbis = async (mbis: Array<Omit<IMbi, "imageUrl">>) =>
    await axiosInstance.post(`/mbis/order`, mbis);

export const addMbImage = async (mbiImage: FormData) =>
    await axiosInstance.post(`/mbis/uploadImage`, mbiImage, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
