import { useQuery } from "react-query";
import { getCustomerCampaignLogs, getCustomerConvertStepLogs } from "@/services";

export const useGetCampaignLogData = (userUId: number | bigint | null) => {
 const { data: convertStepLog, isLoading: getConvertStepLogDataLoading } = useQuery(
  ["convertStepLog", userUId],
  async () => {
   const convertStepLogs = await getCustomerConvertStepLogs(userUId);
   return convertStepLogs || []; // Make sure to handle potential null or undefined cases
  },
 );

 return { convertStepLog, getConvertStepLogDataLoading };
};

export default useGetCampaignLogData;
