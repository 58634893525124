import { IFieldProps } from "@/models";
import { Calendar, CalendarProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface CalendarFieldProps extends CalendarProps, IFieldProps { }

const CalendarField: React.FC<CalendarFieldProps> = ({
    labelName,
    labelText,
    visible = true,
    getFormErrorMessage,
    ...rest
}) => {
    const { t } = useTranslation();
    if (visible) {
        return (
            <div className="field">
                <label htmlFor={labelName}>{t(labelText)}</label>
                <Calendar {...rest} />
                {getFormErrorMessage && getFormErrorMessage()}
            </div>
        );
    } else {
        return null;
    }
};

export default CalendarField;
