import { useState } from "react";
import { useGetDrawListData } from "@/hooks";
import { useTranslation } from "react-i18next";
import { DrawTicketCountDialog } from "../Dialog";
import { Button, Column, DataTable, Tooltip } from "primereact";

const DrawAnalyticCard = () => {
    const { drawList, getDrawListIsLoading } = useGetDrawListData();

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    const handleInfoButtonClick = (rowData: any) => {
        setSelectedRowData(rowData);
        setShowInfoDialog(true);
    };

    const hideInfoDialog = () => {
        setShowInfoDialog(false);
    };

    return (
        <div className="col-12 m-0">
            <div className="card mb-0">
                <h5>Draws</h5>
                <DataTable
                    value={drawList}
                    loading={getDrawListIsLoading}
                    responsiveLayout="scroll"
                    paginator
                    rows={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                >
                    <Column
                        field="drawName"
                        header="Draw Name"
                        sortable
                        style={{ width: "50%" }}
                    />
                    <Column
                        field="ticketCount"
                        header="Ticket Count"
                        sortable
                        style={{ width: "35%" }}
                    />
                    <Column
                        body={(rowData: any) => (
                            <>
                                <Tooltip target="#p-info-button" content="Info" position="bottom" />
                                <Button
                                    id={"p-info-button"}
                                    icon="pi pi-info-circle"
                                    className="p-button-rounded p-button-secondary m-2"
                                    onClick={() => handleInfoButtonClick(rowData)}
                                    style={{ height: 35, width: 35 }}
                                />
                            </>
                        )}
                    />
                </DataTable>

                <DrawTicketCountDialog
                    visible={showInfoDialog}
                    onHide={hideInfoDialog}
                    rowData={selectedRowData}
                />
            </div>
        </div>
    );
};

export default DrawAnalyticCard;
