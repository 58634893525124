import React from "react";
import { Button, Dialog } from "primereact";

interface DataTableUpdateDialogProps {
    visible: boolean;
    data: any;
    onHide: () => void;
    onUpdate: () => void;
    text?: string;
}

const DataTableUpdateDialog: React.FC<DataTableUpdateDialogProps> = ({
    visible,
    data,
    onHide,
    onUpdate,
    text,
}) => {
    const updateDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={onHide}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => onUpdate()}
            />
        </>
    );

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={updateDialogFooter}
            onHide={onHide}
        >
            <div className="confirmation-content">
                <i
                    className="pi pi-exclamation-triangle mr-3"
                    style={{ fontSize: "2rem" }}
                />
                {data && (
                    <span>{!text ? "Are you sure you want to update?" : text}</span>
                )}
            </div>
        </Dialog>
    );
};

export default DataTableUpdateDialog;
