import { IProduct } from "@/models";
import { Column, DataTable, Dialog, Image } from "primereact";
import { DataTableDialogFooter } from "../../common";
import { STATUS } from "@/constants/enums";
import React, { SyntheticEvent, useState } from "react";
import NoImage from "@assets/images/no-image.png";
import { useGetProductsData } from "@/hooks";


interface PopupProductSelectionDialogProps {
    visible: boolean;
    onHide: () => void;
    onProductSelect: (product: IProduct) => void;
}


const PopupProductSelectionDialog: React.FC<PopupProductSelectionDialogProps> = ({
    visible,
    onHide,
    onProductSelect,
}) => {

    const [selectedProduct, setSelectedProduct] = useState<IProduct>();

    const { products, getProductsIsLoading } = useGetProductsData();

    const hideProductSelectionDialog = () => {
        onHide();
    };

    const handleSelectedProduct = (product: IProduct) => {
        onProductSelect(product);
        hideProductSelectionDialog();
    };

    const imageBodyTemplate = (rowData: IProduct) => {
        return (
            <Image
                src={`${rowData.imageUrl}`}
                alt="product"
                height="80"
                width="80"
                preview
                className="product-image"
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                    (e.target as HTMLImageElement).src = NoImage;
                }}
            />
        );
    };

    return (
        <Dialog
            visible={visible}
            footer={
                <DataTableDialogFooter
                    loading={getProductsIsLoading}
                    hideDialog={hideProductSelectionDialog}
                    handleSubmit={() => {
                        if (selectedProduct !== undefined) {
                            handleSelectedProduct(selectedProduct);
                        }
                    }}
                    submitButtonText="Select"
                />
            }
            header="Select Product"
            modal
            className="p-fluid"
            onHide={hideProductSelectionDialog}
        >
            <DataTable
                value={products?.filter((product) => product.status === STATUS.ACTIVE)}
                title="active products"
                paginator={false}
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
                loading={getProductsIsLoading}
            >
                <Column
                    selectionMode="single"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                ></Column>
                <Column field="name" header={"Name"} style={{ minWidth: "6rem" }}></Column>
                <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                <Column field="stockQuantity" header={"Stock Quantity"} style={{ minWidth: "6rem" }}></Column>
            </DataTable>
        </Dialog>
    );
};

export default PopupProductSelectionDialog;
