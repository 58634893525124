import { ICampaign } from "@/models";
import { Column, DataTable, Dialog, Image } from "primereact";
import { DataTableDialogFooter } from "../../common";
import { STATUS } from "@/constants/enums";
import React, { SyntheticEvent, useState } from "react";
import NoImage from "@assets/images/no-image.png";
import { useGetCampaignsData } from "@/hooks";


interface PopupCampaignSelectionDialogProps {
    visible: boolean;
    onHide: () => void;
    onCampaignSelect: (campaign: ICampaign) => void;
}


const PopupCampaignSelectionDialog: React.FC<PopupCampaignSelectionDialogProps> = ({
    visible,
    onHide,
    onCampaignSelect,
}) => {

    const [selectedCampaign, setSelectedCampaign] = useState<ICampaign>();

    const { campaigns, getCampaignsIsLoading } = useGetCampaignsData();

    const hideCampaignSelectionDialog = () => {
        onHide();
    };

    const handleSelectedCampaign = (campaign: ICampaign) => {
        onCampaignSelect(campaign);
        hideCampaignSelectionDialog();
    };

    const imageBodyTemplate = (rowData: ICampaign) => {
        return (
            <Image
                src={`${rowData.imageUrl}`}
                alt="campaign"
                height="80"
                width="80"
                preview
                className="campaign-image"
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                    (e.target as HTMLImageElement).src = NoImage;
                }}
            />
        );
    };

    return (
        <Dialog
            visible={visible}
            footer={
                <DataTableDialogFooter
                    loading={getCampaignsIsLoading}
                    hideDialog={hideCampaignSelectionDialog}
                    handleSubmit={() => {
                        if (selectedCampaign !== undefined) {
                            handleSelectedCampaign(selectedCampaign);
                        }
                    }}
                    submitButtonText="Select"
                />
            }
            header="Select Campaign"
            modal
            className="p-fluid"
            onHide={hideCampaignSelectionDialog}
        >
            <DataTable
                value={campaigns?.filter((campaign) => campaign.status === STATUS.ACTIVE)}
                title="active campaigns"
                paginator={false}
                selection={selectedCampaign}
                onSelectionChange={(e) => setSelectedCampaign(e.value)}
                loading={getCampaignsIsLoading}
            >
                <Column
                    selectionMode="single"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                ></Column>
                <Column field="name" header={"Name"} style={{ minWidth: "6rem" }}></Column>
                <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                <Column field="type" header={"Type"} style={{ minWidth: "6rem" }}></Column>
            </DataTable>
        </Dialog>
    );
};

export default PopupCampaignSelectionDialog;
