import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { useMutation } from "react-query";
import { uploadGeneralImage } from "@/services";

export const useUploadGeneralImageData = (toast: React.RefObject<Toast>, onSuccessCallback: (url: string) => void) => {
    const {
        mutate,
        isLoading: uploadGeneralImageIsLoading,
        isSuccess: uploadGeneralImageIsSuccess,
    } = useMutation(
        async ({ image }: { image: FormData }) => {
            const response = await uploadGeneralImage(image);
            return response;
        },
        {
            onSuccess: (data) => {
                toastSuccess(toast, "Image uploaded");
                onSuccessCallback(data);
            },
            onError: (error: any) => {
                let errorMessage = "Image not uploaded";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, uploadGeneralImageIsLoading, uploadGeneralImageIsSuccess };
};
