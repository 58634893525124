import { ICampaign, IMbi, IProduct } from "@/models";
import React, { SyntheticEvent } from "react";
import NoImage from "@assets/images/no-image.png";
import { Button, Dialog, ProgressSpinner, Image } from "primereact";

interface Props {
    rowData: IMbi | ICampaign | IProduct | null;
    visible: boolean;
    isLoading?: boolean;
    onHide: () => void;
    imageType?: number | null;
    saveImage: (rowData: IMbi | ICampaign | IProduct | null, files: FileList) => void;
}

const SingleImageUploadDialog: React.FC<Props> = ({
    rowData,
    visible,
    onHide,
    imageType,
    saveImage,
    isLoading,
}) => {

    const closeDialog = () => {
        onHide();
    };


    const openImageUploadDialog = (rowData: IMbi | ICampaign | IProduct | null) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";

        fileInput.onchange = (event) => {
            const inputElement = event.target as HTMLInputElement;
            const files = inputElement.files;
            if (files && files.length > 0) {
                saveImage(rowData, files);
            }
        };

        fileInput.click();
    };


    return (
        <Dialog
            visible={visible}
            style={{ width: "30%", minHeight: "100px" }}
            header="Image Upload"
            modal
            className="p-fluid"
            onHide={closeDialog}
        >
            {!isLoading ? (
                <>
                    <span style={{ color: "red" }}>
                        {imageType === 2 || imageType === 1 ? 'Image width must be 512px' : 'Image size must be 600px width and 400px height'}
                    </span>
                    <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%", paddingTop: "20px" }}>
                        <Image
                            src={
                                imageType === 2 ?
                                    (rowData?.targetUrlScnd !== undefined && rowData?.targetUrlScnd !== null) ? rowData.targetUrlScnd.split('imageurl=')[1] : ""
                                    : imageType === 1 ?
                                        (rowData?.targetUrl !== undefined && rowData?.targetUrl !== null) ? rowData.targetUrl.split('imageurl=')[1] : "" :
                                        (rowData?.imageUrl !== undefined && rowData?.imageUrl !== null) ? rowData.imageUrl : ""
                            }
                            alt="image"
                            height="100%"
                            width="100%"
                            style={{
                                flex: "1",
                                display: "flex",
                                justifyContent: "flex-start",
                                marginRight: "110px",
                            }}
                            preview
                            className="mbi-image"
                            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                                (e.target as HTMLImageElement).src = NoImage;
                            }}
                        />

                        <Button
                            id="p-upload-image-button2"
                            className="p-button-rounded m-2"
                            icon="pi pi-upload"
                            onClick={() => openImageUploadDialog(rowData)}
                            style={{
                                textAlign: "center",
                                textShadow: "1px 1px 2px black",
                                fontWeight: "bold",
                                marginTop: "10px",
                                marginLeft: "auto",
                            }}
                        >
                        </Button>
                    </div>

                </>
            ) : (
                <div
                    className="flex align-items-center"
                    style={{ height: 200, width: "100%" }}
                >
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                </div>
            )}
        </Dialog>
    );
};

export default SingleImageUploadDialog;
