import { IProduct } from "@/models";
import { STATUS } from "@/constants";
import { toastError } from "@/utils";
import NoImage from "@assets/images/no-image.png";
import { DataTableDialogFooter } from "@/components";
import ProductList from "./preview/components/product_list";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Column, DataTable, Dialog, Image, Toast } from "primereact";

interface ProductSortDialogProps {
    visible: boolean;
    products: IProduct[];
    isLoading: boolean;
    onHide: () => void;
    orderProducts: (products: IProduct[]) => void;
}

const ProductSortDialog: React.FC<ProductSortDialogProps> = ({
    visible,
    products,
    isLoading,
    onHide,
    orderProducts,
}) => {
    const toast = useRef<Toast>(null);

    const [infoDialog, setInfoDialog] = useState(false);
    const [zoomOutFactor, setZoomOutFactor] = useState<number>(0.85);
    const [sortingProducts, setSortingProducts] = useState<IProduct[]>([]);
    const [missingProductsIds, setMissingProductsIds] = useState<[]>([]);
    const [addInactiveProductsDialog, setAddInactiveProductsDialog] = useState(false);
    const [isGroupedProductsEqual, setIsGroupedProductsEqual] = useState<boolean>(false);
    const [selectedInactiveProducts, setSelectedInactiveProducts] = useState<IProduct[]>([]);

    let missingProducts: [] = [];

    useEffect(() => {
        setSortingProducts([...products]);
    }, [visible]);

    const hideDialog = () => {
        onHide();
    };

    const handleSubmit = () => {
        if (isGroupedProductsEqual) {
            orderProducts(sortingProducts);
            hideDialog();
        } else {
            toastError(toast, `There are missing items in the sorted products. Correct the order and try again!`);
        }
    };

    const openInfoDialog = () => {
        setInfoDialog(true);
    };

    const hideInfoDialog = () => {
        setInfoDialog(false);
    };

    const openAddInactiveProductsDialog = () => {
        setAddInactiveProductsDialog(true);
    };

    const hideAddInactiveProductsDialog = () => {
        setAddInactiveProductsDialog(false);
        setSelectedInactiveProducts([]);
    };

    const handleGroupedProducts = (productIDs: any) => {

        missingProducts = productIDs;

        if (productIDs.length > 0) {
            setIsGroupedProductsEqual(false);
            console.log("Missing Products:", productIDs);
        } else {
            setIsGroupedProductsEqual(true);
            console.log("All products are in order.");
        }
    }

    useEffect(() => {
        setMissingProductsIds(missingProducts);
    }, [sortingProducts]);

    const rowClassName = (rowData: IProduct) => {
        return missingProductsIds.some((missingProductId) => missingProductId === rowData.id)
            ? 'missing-product-row'
            : '';
    };

    const handleSortingProducts = (e: any) => {
        const newSortingProducts = e.map((product: IProduct, index: number) => {
            return {
                ...product,
                sequence: index + 1,
            };
        });
        const inactiveProducts = sortingProducts.filter((product) => product.status === STATUS.INACTIVE);
        setSortingProducts([...inactiveProducts, ...newSortingProducts]);
    };

    const handleDeactivateProduct = (product: IProduct) => {
        const updatedProducts = [...sortingProducts];
        const productIndex = updatedProducts.findIndex((e) => e.id === product.id);

        if (productIndex !== -1) {
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                status: STATUS.INACTIVE,
            };

            setSortingProducts(updatedProducts);
        }
    };

    const handleActivateProducts = () => {
        const updatedSortingProducts = [...sortingProducts];

        selectedInactiveProducts.forEach((product) => {
            const productIndex = updatedSortingProducts.findIndex((e) => e.id === product.id);

            if (productIndex !== -1) {
                updatedSortingProducts[productIndex] = {
                    ...product,
                    status: STATUS.ACTIVE,
                };
            }
        });
        setSortingProducts(updatedSortingProducts);
        hideAddInactiveProductsDialog();
    };

    const handleZoomOut = () => {
        setZoomOutFactor((prevFactor) => prevFactor > 0.05 ? prevFactor - 0.05 : prevFactor);
    };

    const handleZoomIn = () => {
        setZoomOutFactor((prevFactor) => prevFactor < 1.1 ? prevFactor + 0.05 : prevFactor);
    };

    const handleZoomReset = () => {
        setZoomOutFactor(() => 0.85);
    };

    const imageBodyTemplate = (rowData: IProduct) => {
        return (
            <Image
                src={`${rowData.imageUrl}`}
                alt="product"
                width="80"
                preview
                className="product-image"
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                    (e.target as HTMLImageElement).src = NoImage;
                }}
            />
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                style={{ minWidth: "450px", width: "80%" }}
                header={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Product Sort</span>
                        <div>
                            <Button icon="pi pi-info-circle" className="p-button-text" onClick={openInfoDialog} style={{ color: 'blue' }} />
                            <Button icon="pi pi-times" className="p-button-text" onClick={hideDialog} style={{ color: 'grey' }} />
                        </div>
                    </div>
                }
                closable={false}
                modal
                className="p-fluid"
                footer={
                    <DataTableDialogFooter
                        loading={isLoading}
                        hideDialog={hideDialog}
                        handleSubmit={handleSubmit}
                    />
                }
                onHide={hideDialog}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginInline: '100px', marginBottom: '5px' }}>
                            <Button
                                id={"p-zoom-in-button"}
                                icon="pi pi-plus"
                                className="p-button-success mr-2"
                                onClick={openAddInactiveProductsDialog}
                                label="Add Inactive Products"
                            />
                        </div>
                        <div style={{ overflowY: 'auto', maxHeight: '480px' }}>
                            <DataTable
                                value={sortingProducts.filter((product) => product.status === STATUS.ACTIVE)}
                                title="products"
                                paginator={false}
                                size={'small'}
                                loading={isLoading}
                                reorderableRows
                                onRowReorder={(e) => handleSortingProducts(e.value)}
                                rowClassName={rowClassName}
                            >
                                <Column rowReorder style={{ width: "3rem" }} />
                                <Column
                                    field="sequence"
                                    header={"Sequence"}
                                    style={{ minWidth: "3rem" }}
                                ></Column>
                                <Column field="name" header={"Name"} style={{ minWidth: "6rem" }}></Column>
                                <Column
                                    field="status"
                                    style={{ minWidth: "4rem" }}
                                    body={(rowData: IProduct) => (
                                        <Button
                                            icon="pi pi-times"
                                            className="p-button-rounded p-button-danger"
                                            style={{ height: 26, width: 26 }}
                                            onClick={() => handleDeactivateProduct(rowData)}
                                        />
                                    )}
                                />
                            </DataTable>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginInline: '50px', marginBottom: '5px' }}>
                            <Button
                                id={"p-zoom-in-button"}
                                icon="pi pi-plus"
                                className="p-button-warning mr-2"
                                onClick={handleZoomIn}
                                label="Zoom In"
                            />
                            <Button
                                id={"p-zoom-reset-button"}
                                icon="pi pi-refresh"
                                className=" mr-2"
                                onClick={handleZoomReset}
                                label="Reset"
                            />
                            <Button
                                id={"p-zoom-out-button"}
                                icon="pi pi-minus"
                                className="p-button-warning mr-2"
                                onClick={handleZoomOut}
                                label="Zoom Out"
                            />
                        </div>
                        <div style={{ overflowY: 'auto', maxHeight: '480px', paddingRight: '20px' }}>
                            <ProductList
                                products={sortingProducts.filter((product) => product.status === STATUS.ACTIVE)}
                                zoomOutFactor={zoomOutFactor}
                                onGroupedProductsChange={handleGroupedProducts}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={infoDialog}
                style={{ width: "50%" }}
                footer={
                    <div>
                        <Button label="OK" icon="pi pi-check" onClick={hideInfoDialog} />
                    </div>
                }
                header="Info"
                modal
                className="p-fluid"
                onHide={hideInfoDialog}
            >
                <>
                    <div>
                        * Changes won`t be applied live until you press save.
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        * You can rearrange the order using the icon with three lines on the left.
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        * You can add and sort inactive products, they become active when you press save.
                    </div>
                </>
            </Dialog>

            <Dialog
                visible={addInactiveProductsDialog}
                footer={
                    <DataTableDialogFooter
                        loading={isLoading}
                        hideDialog={hideAddInactiveProductsDialog}
                        handleSubmit={handleActivateProducts}
                        submitButtonText="Add"
                    />
                }
                header="Add Inactive Products"
                modal
                className="p-fluid"
                onHide={hideAddInactiveProductsDialog}
            >
                <DataTable
                    value={sortingProducts.filter((product) => product.status === STATUS.INACTIVE)}
                    title="inactive products"
                    paginator={false}
                    selection={selectedInactiveProducts} onSelectionChange={e => setSelectedInactiveProducts(e.value)}
                >
                    <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                        exportable={false}
                    ></Column>
                    <Column field="name" header={"Name"} style={{ minWidth: "6rem" }}></Column>
                    <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                    <Column field="stockQuantity" header={"Stock Quantity"} style={{ minWidth: "6rem" }}></Column>
                    <Column
                        field="status"
                        header="Status"
                        style={{ minWidth: "12rem" }}
                        body={(rowData: IProduct) => (
                            <span
                                className={`badge status-${rowData.status ? rowData.status.toLowerCase() : ""}`}
                            >
                                {rowData.status}
                            </span>
                        )}
                    />
                </DataTable>
            </Dialog>
        </>
    );
};

export default ProductSortDialog;
