import { Toast } from "primereact";
import { addMbImage } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddMbiImagesData = (toast: React.RefObject<Toast>, setImageDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: addProductImagesIsLoading
  } = useMutation(
    async (productImages: FormData) => await addMbImage(productImages),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mbis");
        toastSuccess(toast, "Mbi image added");
        setImageDialog(false);
      },
      onError: (error: any) => {
        let errorMessage = "Mbi image not added";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        toastError(toast, `${errorMessage}`);
      },
    },
  );
  return { mutate, addProductImagesIsLoading };
};
