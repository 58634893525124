import * as Yup from "yup";

export const campaignSchema = Yup.object().shape({
    directPopUpColorCode1: Yup.string().nullable()
        .matches(/^$|^[0-9A-Fa-f]{6}$/, 'Please enter a valid HEX color code (e.g., FFFFFF) or leave it blank.'),
    directPopUpColorCode2: Yup.string().nullable()
        .matches(/^$|^[0-9A-Fa-f]{6}$/, 'Please enter a valid HEX color code (e.g., FFFFFF) or leave it blank.'),
    directPopUpButtonColorCode: Yup.string().nullable()
        .matches(/^$|^[0-9A-Fa-f]{6}$/, 'Please enter a valid HEX color code (e.g., FFFFFF) or leave it blank.'),
    sellingPoint: Yup.number().nullable()
        .min(0, "Selling point cannot be negative!")
        .max(100000, "You can enter up to 100000!")
});