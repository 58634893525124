import { INotificationMessage } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Badge, Dialog, TabMenu } from "primereact";
import React, { useState } from "react";
import {
    InputField,
    ErrorField,
    DataTableDialogFooter,
    DropdownField,
} from "@/components";
import { isFormFieldValid } from "@/utils";
import { notificationSchema } from "@/validations";

interface NotificationMessageDialogProps {
    visible: boolean;
    notificationMessage: INotificationMessage;
    isLoading: boolean;
    isAddNotification: boolean;
    onHide: () => void;
    saveNotificationMessage: (notificationMessage: INotificationMessage) => void;
}

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "Second Language", icon: "pi pi-fw pi-language" },
];

const msgCodeOptions = [
    { label: "NCS", value: "NCS" },
    { label: "CMP_1000", value: "CMP_1000" },
    { label: "CMP_3000", value: "CMP_3000" },
    { label: "CMP_5000", value: "CMP_5000" },
    { label: "CMP_10000", value: "CMP_10000" },
    { label: "CMP_20000", value: "CMP_20000" },
    { label: "APP_OPEN_REMINDER", value: "APP_OPEN_REMINDER" },
    { label: "SINGLE_NOTIFICATION", value: "SINGLE_NOTIFICATION" },
    { label: "ORDER_SENT_NOTIFICATION", value: "ORDER_SENT_NOTIFICATION" },
];

const NotificationMessageDialog: React.FC<NotificationMessageDialogProps> = ({
    visible,
    notificationMessage,
    isLoading,
    isAddNotification,
    onHide,
    saveNotificationMessage,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const formik = useFormik({
        initialValues: notificationMessage,
        enableReinitialize: true,
        validationSchema: notificationSchema,
        onSubmit: (data) => {
            saveNotificationMessage(data);
        },
    });

    const hideDialog = () => {
        onHide();
        formik.resetForm();
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header="Notification Message Details"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <DropdownField
                            id="msgCode"
                            value={formik.values.msgCode}
                            type="text"
                            required
                            options={msgCodeOptions}
                            onChange={formik.handleChange}
                            disabled={!isAddNotification}
                            labelName="msgCode"
                            labelText="Message Code"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"msgCode"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "msgCode"),
                            })}
                            itemTemplate={(option) => {
                                return (
                                    <Badge value={option?.value} className={getBadgeColor(option?.value)} />
                                );
                            }}
                            valueTemplate={(option) => {
                                return (
                                    <Badge value={option?.value} className={getBadgeColor(option?.value)} />
                                );
                            }}
                        />
                        <InputField
                            id="title"
                            value={formik.values.title ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="title"
                            labelText="Title"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="title" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "title"),
                            })}
                        />
                        <InputField
                            id="text"
                            value={formik.values.text ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="text"
                            labelText="Text"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="text" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "text"),
                            })}
                        />
                    </>
                ) : (
                    <>

                        <InputField
                            id="titleScnd"
                            value={formik.values.titleScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="titleScnd"
                            labelText="Title"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="titleScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "titleScnd"),
                            })}
                        />
                        <InputField
                            id="textScnd"
                            value={formik.values.textScnd ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="textScnd"
                            labelText="Text"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="textScnd" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "textScnd"),
                            })}
                        />
                    </>
                )}
            </form>
        </Dialog>
    );
};

export default NotificationMessageDialog;

function getBadgeColor(msgCode: string) {
    switch (msgCode) {
        case "NCS":
            return "p-badge-success";
        case "CMP_1000":
            return "p-badge-info";
        case "CMP_3000":
            return "p-badge-warning";
        case "CMP_5000":
            return "p-badge-danger";
        case "CMP_10000":
            return "p-badge-primary";
        case "CMP_20000":
            return "p-badge-secondary";
        case "APP_OPEN_REMINDER":
            return "p-badge-tertiary";
        case "SINGLE_NOTIFICATION":
            return "p-badge-quaternary";
        case "ORDER_SENT_NOTIFICATION":
            return "p-badge-info";
        default:
            return "p-badge-rose";
    }
}
