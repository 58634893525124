import * as Yup from "yup";

export const orderSchema = Yup.object().shape({
    trackingNumber: Yup.string()
        .nullable()
        .min(2, "Please enter at least 2 characters!")
        .max(50, "You can enter up to 50 characters!")
        .required("Tracking number is required"),
    carrierCompany: Yup.string()
        .nullable()
        .min(2, "Please enter at least 2 characters!")
        .max(50, "You can enter up to 50 characters!")
        .required("Carrier company is required"),
});

export const digitalOrderSchema = Yup.object().shape({
    expiryDate: Yup.string()
        .nullable()
        .min(2, "Please enter at least 2 characters!")
        .max(50, "You can enter up to 50 characters!")
        .required("Expiry Date is required"),
    digitalCode: Yup.string().nullable().required("Digital Code is required"),
});
