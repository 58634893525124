import moment from "moment";
import React, { useState } from "react";
import { Button, Tooltip } from "primereact";
import { ICustomer, IOrder } from "@/models";
import { PRODUCT_TYPES } from "@/constants/enums";
import { CustomerAddressDialog } from "@/components";
import { CONFIG_VARS, initialCustomer } from "@/constants";
import { useGetCustomerBasicData, useGetOrderDetailsData, useTheme } from "@/hooks";

interface OrderDialogProps {
  order: IOrder | undefined;
}

interface ITrackingInfo {
  id: number | null;
  customerId: number | null;
  date: string | null;
  status: string | null;
  trackingNumber: string;
  carrierCompany: string;
}

const OrderDetailDataTable: React.FC<OrderDialogProps> = ({ order }) => {
  const { layoutColorMode } = useTheme();

  const [userInfoDialog, setUserInfoDialog] = useState(false);

  const { customerBasic, getCustomerBasicIsLoading } = useGetCustomerBasicData(order?.customerId ?? 0);
  const { orderDetails, getOrderDetailsIsLoading } = useGetOrderDetailsData(order?.id ?? null);

  const orderData: IOrder | undefined = orderDetails?.order;
  const trackingInfoList = orderDetails?.trackingInfoList?.filter(
    (info: ITrackingInfo) => info.trackingNumber && info.carrierCompany
  );


  const openUserInfoDialog = (rowData: ICustomer) => {
    setUserInfoDialog(true);
  };

  const hideUserInfoDialog = () => {
    setUserInfoDialog(false);
  };

  return (
    <>
      {orderData && (
        <table
          style={{
            border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
              ? "rgba(0, 0, 0, .05)"
              : "rgba(255, 255, 255, 0.6)"
              }`,
            borderCollapse: "collapse",
            textAlign: "left",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Order Id:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {orderData.id.toString()}
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                User :
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                  width: "80%",
                }}
              >
                <div style={{ position: "relative" }}>
                  <tr style={{ lineHeight: "20px" }}>
                    {orderData.customerName.toString()} {customerBasic?.surname} | {customerBasic?.email}
                  </tr>
                  <tr style={{ lineHeight: "20px" }}>
                    CKP: {customerBasic?.moneyAmount} | Average Step: {customerBasic?.averageStep}
                  </tr>
                  <tr style={{ lineHeight: "20px" }}>
                    Creation Date:{" "}
                    {customerBasic?.createDate
                      ? moment(customerBasic?.createDate).format("DD-MM-YYYY").toString()
                      : ""}
                  </tr>
                  <Tooltip target="#p-info-button" content="User Info" position="bottom" />
                  <Button
                    id="p-info-button"
                    icon="pi pi-info-circle"
                    className="p-button p-button-primary m-0"
                    style={{ position: "absolute", top: "23%", right: 0, height: 30, width: 50 }}
                    onClick={() => openUserInfoDialog(customerBasic ?? initialCustomer)}
                  />
                </div>

              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Address Info:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {orderData.addressInfo.toString()}
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Product Name:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {orderData.productName.toString()}
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Date:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {moment.utc(orderData.date).format("DD-MM-YYYY HH:mm")}
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Price:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {orderData.price.toString()}
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Status:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                <span
                  className={`badge status-${orderData.status ? orderData.status?.toLowerCase() : ""
                    }`}
                >
                  {orderData.status?.toString()}
                </span>
              </td>
            </tr>


            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Type:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {orderData.type}
              </td>
            </tr>

            {orderData.type?.toLowerCase() !== PRODUCT_TYPES.DIGITAL.toLowerCase() && (
              <tr>
                <th
                  style={{
                    border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                      ? "rgba(0, 0, 0, .05)"
                      : "rgba(255, 255, 255, 0.6)"
                      }`,

                    padding: "8px",
                  }}
                >
                  Tracking Info:
                </th>
                <td
                  style={{
                    border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                      ? "rgba(0, 0, 0, .05)"
                      : "rgba(255, 255, 255, 0.6)"
                      }`,

                    padding: "8px",
                  }}
                >
                  {trackingInfoList && trackingInfoList.length > 0 ? (
                    <>
                      {trackingInfoList.map((info: ITrackingInfo, index: number) => (
                        <div key={index}>
                          {info.trackingNumber} / {info.carrierCompany}
                        </div>
                      ))}
                    </>
                  ) : (
                    <span>No tracking info available</span>
                  )}
                </td>
              </tr>)}

            {orderData.type?.toLowerCase() === PRODUCT_TYPES.DIGITAL.toLowerCase() && (
              <>
                <tr>
                  <th
                    style={{
                      border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                        ? "rgba(0, 0, 0, .05)"
                        : "rgba(255, 255, 255, 0.6)"
                        }`,

                      padding: "8px",
                    }}
                  >
                    Expiry Date:
                  </th>
                  <td
                    style={{
                      border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                        ? "rgba(0, 0, 0, .05)"
                        : "rgba(255, 255, 255, 0.6)"
                        }`,

                      padding: "8px",
                    }}
                  >
                    {orderData?.expiryDate?.split('T')[0]}
                  </td>
                </tr>


                <tr>
                  <th
                    style={{
                      border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                        ? "rgba(0, 0, 0, .05)"
                        : "rgba(255, 255, 255, 0.6)"
                        }`,

                      padding: "8px",
                    }}
                  >
                    Digital Code:
                  </th>
                  <td
                    style={{
                      border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                        ? "rgba(0, 0, 0, .05)"
                        : "rgba(255, 255, 255, 0.6)"
                        }`,

                      padding: "8px",
                    }}
                  >
                    {orderData?.digitalCode}
                  </td>
                </tr>
              </>)}
          </tbody>
        </table>
      )}

      <CustomerAddressDialog
        visible={userInfoDialog}
        customerId={customerBasic?.id ?? null}
        onHide={hideUserInfoDialog}
      />

    </>
  );
};

export default OrderDetailDataTable;
