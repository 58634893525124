import { useState, useRef, useEffect } from "react";
import {
    DataTableToolbar,
    DataTableSearchHeader,
    PopupDialog,
} from "@/components";
import { IPopup } from "@/models";
import { Button, Column, DataTable, Paginator, PaginatorPageState, Toast, Tooltip } from "primereact";
import { ROLE, initialPopup } from "@/constants";
import {
    useRole,
    useSendPopupData,
    useGetPopupHistoryData,
} from "@/hooks";
import { useQueryClient } from "react-query";

const PopupHistoryDataTable = () => {
    const { role } = useRole();
    const queryClient = useQueryClient();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [popup, setPopup] = useState<IPopup>(initialPopup);
    const [isSendPopup, setIsSendPopup] = useState(false);
    const [selectedPopup, setSelectedPopup] = useState<any>(null);
    const [popupDialog, setPopupDialog] = useState(false);

    const toast = useRef<Toast>(null);

    const {
        mutate: sendPopupMutate,
        sendPopupIsLoading,
        sendPopupIsSuccess,
    } = useSendPopupData(toast);

    const { popupHistory, getPopupHistoryIsLoading } = useGetPopupHistoryData(page, rowsPerPage);
    const totalRecords = popupHistory?.[0]?.totalElements ?? 0;

    useEffect(() => {
        queryClient.setQueryData(["popupHistory", "page"], page);
        queryClient.setQueryData(["popupHistory", "rowPerPage"], rowsPerPage);
    }, [page, rowsPerPage, queryClient]);

    useEffect(() => {
        if (sendPopupIsSuccess) {
            hidePopupDialog();
        }
    }, [sendPopupIsSuccess]);

    const hidePopupDialog = () => {
        setPopupDialog(false);
    };

    const sendPopup = (newPopup: IPopup) => {
        sendPopupMutate(newPopup)
    };

    const openSendPopupDialog = () => {
        setPopup(initialPopup);
        setIsSendPopup(true);
        setPopupDialog(true);
    };

    const openInfoPopupDialog = (rowData: IPopup) => {
        setPopup({ ...rowData });
        setIsSendPopup(false);
        setPopupDialog(true);
    };

    const onPageChange = (event: PaginatorPageState) => {
        setPage(event.page);
        setRowsPerPage(event.rows);
    };

    return (
        <>
            <Toast ref={toast} />
            {(role === ROLE.ADMIN || role === ROLE.MANAGER) && (<DataTableToolbar
                openAddDialog={openSendPopupDialog}
            />)}

            <DataTable
                dataKey="id"
                value={popupHistory}
                loading={getPopupHistoryIsLoading}
                title="popup history"
                selection={selectedPopup}
                onSelectionChange={(e: any) => setSelectedPopup(e.value)}
                header={
                    <DataTableSearchHeader
                        title="Pop-up History"
                        filters={null}
                        onChange={(e: any) => { }}
                    />
                }
                rows={rowsPerPage}
                responsiveLayout="scroll"
                emptyMessage="Pop-up messages not found"
            >
                <Column
                    field="title"
                    header={"Title"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="text"
                    header={"Text"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="sendedPopUpCount"
                    header={"Sended Count"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="readedPopUpCount"
                    header={"Readed Count"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="popupType"
                    header={"Pop-up Type"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="status"
                    header={"Status"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    body={(rowData: IPopup) => (
                        <>
                            <Tooltip target="#p-info-button" content="User Info" position="bottom" />
                            <Button
                                id="p-info-button"
                                icon="pi pi-info-circle"
                                className="p-button-rounded p-button-secondary m-1"
                                onClick={() => openInfoPopupDialog(rowData)}
                            />
                        </>
                    )}
                    exportable={false}
                    style={{ minWidth: "4rem" }}
                ></Column>
            </DataTable>
            <Paginator first={rowsPerPage * page} rows={rowsPerPage} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange} />

            <PopupDialog
                popup={popup}
                isSendPopup={isSendPopup}
                sendPopup={sendPopup}
                visible={popupDialog}
                onHide={hidePopupDialog}
                isLoading={sendPopupIsLoading}
            />
        </>
    );
};

export default PopupHistoryDataTable;
