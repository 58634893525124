import { NotificationHistoryDataTable } from "@/components";

const NotificationHistory = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <NotificationHistoryDataTable />
            </div>
        </div>
    );
};

export default NotificationHistory;
