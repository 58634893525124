import { IStock } from "@/models";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";
import { addStock } from "@/services/stock-services/stockServices";

export const useAddStockData = (toast: React.RefObject<Toast>, isItProduct: boolean = true) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addStockIsLoading,
        isSuccess: addStockIsSuccess,
    } = useMutation(async (stock: IStock) => await addStock(stock), {
        onSuccess: () => {
            queryClient.invalidateQueries(isItProduct ? "products" : "drawProducts");
            toastSuccess(toast, "Stock added");
        },
        onError: (error: any) => {
            let errorMessage = "Stock not added";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        addStockIsLoading,
        addStockIsSuccess,
    };
};
