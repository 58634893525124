import { useGetCustomerCountByCkpData } from "@/hooks";
import { Chart } from "primereact";
import React, { useEffect, useState } from "react";

const CustomerCountByCkpChart = () => {
 const [chartData, setChartData] = useState({});
 const [chartOptions, setChartOptions] = useState({});
 const { customerCountByCkp, getCustomerCountByCkpIsLoading } =
  useGetCustomerCountByCkpData();

 useEffect(() => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const data = customerCountByCkp?.map((item: any) => item[1]);
  const labels = customerCountByCkp?.map((item: any) => item[0]);
  const chartData = {
   labels: labels ?? [],
   datasets: [
    {
     label: "Total User Count by CKP",
     data: data ?? [],
     backgroundColor: documentStyle.getPropertyValue("--green-500"),
     borderWidth: 1,
    },
   ],
  };
  const options = {
   maintainAspectRatio: false,
   aspectRatio: 0.9,
  };

  setChartData(chartData);
  setChartOptions(options);
 }, [getCustomerCountByCkpIsLoading]);

 return (
  <div className="card mb-0">
   <Chart type="bar" data={chartData} options={chartOptions} />
  </div>
 );
};

export default CustomerCountByCkpChart;
