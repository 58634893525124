import { useQuery } from "react-query";
import { getCustomerCkpHistory } from "@/services";

export const useGetCustomerCkpHistoryData = (customerId: number | null) => {
    const {
        data: customerCkpHistory,
        isLoading: getCustomerCkpHistoryIsLoading,
    } = useQuery(
        ["customerCkpHistory", customerId],
        async () => {
            return await getCustomerCkpHistory(customerId);
        },
    );
    return {
        customerCkpHistory,
        getCustomerCkpHistoryIsLoading,
    };
};
